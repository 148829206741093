import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
} from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { SubmitButton } from "../../../components";

const StartSectionButton = ({ section, children, startSection }) => {
  const methods = useForm();
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <SubmitButton
        color="link"
        className="focus:shadow-none text-lg"
        onClick={handleSubmit(() => startSection(section))}
      >
        {children}
      </SubmitButton>
    </FormProvider>
  );
};

export const TourListModal = ({
  isOpen,
  toggle,
  onFinish,
  onContinue,
  percentComplete,
  sections,
  isComplete,
  onMoreHelp,
  startSection,
  isLastComplete,
}) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={toggle}>
          <Trans>myfood24 tour checklist</Trans>
        </ModalHeader>
        <ModalBody>
          <Progress
            color="hc-secondary"
            value={percentComplete}
            style={{ height: "1.5rem" }}
          >
            {percentComplete}%
          </Progress>
          <p className="prose mb-3">
            Click 'Continue tour' or skip a step using the sections below.
          </p>
          <ul>
            {sections.map(({ key, label, complete }) => (
              <li key={key} className="flex items-center">
                <FontAwesomeIcon
                  size="2x"
                  className="text-hc-primary"
                  icon={complete ? faCheckSquare : faSquare}
                />
                <StartSectionButton startSection={startSection} section={key}>
                  {t(label)}
                </StartSectionButton>
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter className="block">
          <div>
            {isComplete || isLastComplete ? (
              <p className="text-center mb-4">
                <Trans>Are you ready to get started with myfood24?</Trans>
              </p>
            ) : null}
            <div className="flex">
              {isComplete || isLastComplete ? (
                <>
                  {!isComplete && isLastComplete ? (
                    <SubmitButton
                      className="mr-auto"
                      outline
                      onClick={handleSubmit(onContinue)}
                    >
                      <Trans>Continue tour</Trans>
                    </SubmitButton>
                  ) : (
                    <SubmitButton
                      outline
                      onClick={handleSubmit(onMoreHelp)}
                      className="mr-auto"
                    >
                      <Trans>I'd like more help</Trans>
                    </SubmitButton>
                  )}
                  <SubmitButton onClick={handleSubmit(onFinish)}>
                    <Trans>Yes, let's go</Trans>
                  </SubmitButton>
                </>
              ) : (
                <>
                  <SubmitButton
                    outline
                    onClick={handleSubmit(onFinish)}
                    className="mr-auto"
                  >
                    <Trans>Finish tour</Trans>
                  </SubmitButton>
                  <SubmitButton onClick={handleSubmit(onContinue)}>
                    <Trans>Continue tour</Trans>
                  </SubmitButton>
                </>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
