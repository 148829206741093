import React, { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import i18n from "./config/i18n";
import ErrorBoundary from "./components/ErrorBoundary";
import { I18nextProvider, useTranslation } from "react-i18next";
import { NotificationContainer } from "react-notifications";
import { RecoilRoot } from "recoil";
import { UserAtomEffect } from "./atomEffects";
import { GoogleAnalytics } from "./components/GoogleAnalytics";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      useErrorBoundary: true,
      structuralSharing: false,
    },
  },
});

const DirSwitch = () => {
  const { i18n } = useTranslation();
  const dir = i18n.dir();

  useEffect(() => {
    document.body.dir = dir;
    document.body.className = dir;
  }, [dir]);

  return null;
};

export const App = () => {
  return (
    <Suspense fallback={null}>
      <RecoilRoot>
        <I18nextProvider i18n={i18n}>
          <NotificationContainer />
          <BrowserRouter>
            <GoogleAnalytics />
            <DirSwitch />
            <UserAtomEffect />
            <ErrorBoundary>
              <Suspense fallback={null}>
                <QueryClientProvider client={queryClient}>
                  <Routes />
                </QueryClientProvider>
              </Suspense>
            </ErrorBoundary>
          </BrowserRouter>
        </I18nextProvider>
      </RecoilRoot>
    </Suspense>
  );
};

export default App;
