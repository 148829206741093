import React from "react";
import classNames from "classnames";
import { Trans } from "react-i18next";

export const ViewingRecords = ({
  limit,
  offset,
  count,
  className,
  noCount,
  ...props
}) => (
  <div className={classNames("opacity-50 text-sm", className)} {...props}>
    {count > 0 ? offset + 1 : 0}-
    {Math.min(offset + limit, noCount ? offset + limit : count)}
    {!noCount && (
      <span>
        {" /"} {count} <Trans>results</Trans>
      </span>
    )}
  </div>
);

export default ViewingRecords;
