import React, { useState } from "react";
import {
  FormGroup,
  Select,
  FileField,
  PopoverInputGroup,
  FormCheck, SubmitButton,
} from "components/common";
import {Field, FormikProvider, useFormik, useFormikContext} from "formik";
import { validateEmail, validateUrl } from "services/validators";
import {Row, Col, ModalBody, ModalFooter} from "reactstrap";
import { IntegerRadioField } from "./YesNoField";
import { Button, Alert } from "reactstrap";
import { useTranslation, Trans } from "react-i18next";
import { FastInput } from "../../../components/common";
import {useClient, useModal} from "../../../hooks";
import {useReportingStatic} from "../queries/useReportingStatic";
import {NotificationManager} from "react-notifications";
import {useConfirmMultiModal} from "../../../features/multi_day_projects";

export const LANGUAGES = [
  { label: "English", value: "en" },
  { label: "English (US)", value: "en_us" },
  { label: "Dansk", value: "da" },
  { label: "Deutsche", value: "de" },
  { label: "Norsk", value: "no" },
  { label: "Français", value: "fr" },
  { label: "Italian", value: "it" },
  { label: "عربى", value: "ar" },
  { label: "Español", value: "es" },
];

const DefaultLanguageField = ({ onChange }) => {
  const form = useFormik({
    onSubmit,
    initialValues: {},
  });
  const { resetForm } = form;
  const { Modal, isOpen, setIsOpen } = useModal();
  const client = useClient();
  const { data: reportingStatic } = useReportingStatic(client);
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  function onSubmit() {
    const { male_reference_intake, female_reference_intake } = reportingStatic;
    setFieldValue('project_reference_intake.male', male_reference_intake);
    setFieldValue('project_reference_intake.female', female_reference_intake);
    setIsOpen(false);
    NotificationManager.success(t('Reference intakes reset'))
  }

  return (
    <>
      {isOpen ? (
        <FormikProvider value={form}>
          <Modal>
            <ModalBody>
              <Trans>
                The project default language has changed, do you want to reset the reference intakes to values for this country?
              </Trans>
            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={() => setIsOpen(false)}>
                <Trans>No</Trans>
              </Button>
              <SubmitButton>
                <Trans>Yes</Trans>
              </SubmitButton>
            </ModalFooter>
          </Modal>
        </FormikProvider>
      ) : null}
      <FormGroup label="Default language" name="default_locale" required>
        <Select
          name="default_locale"
          validate={(v) => !v}
          options={LANGUAGES}
          onChange={(v) => {
            resetForm();
            onChange?.(v?.value ?? null);
            setIsOpen(true)
          }}
          simpleValue
        />
      </FormGroup>
    </>
  )
}

export const ProjectFields = ({ allowOffline, onDefaultLanguageChanged }) => {
  const [showParams, setShowParams] = useState(false);
  const { setFieldValue, values, errors } = useFormikContext();
  const { t } = useTranslation();

  function handleConfirmMulti() {
    setFieldValue('is_multi_day', true);
    setIsOpen(false);
  }

  const { confirmMultiModal, setIsOpen } = useConfirmMultiModal({ onConfirm: handleConfirmMulti });

  const { is_student_project, is_multi_day } = values;

  return (
    <>
      {confirmMultiModal}
      {!is_student_project && (
        <FormGroup label="Allow multiple diary submission?">
          <FormCheck
            name="is_multi_day"
            label={<>&nbsp;</>}
            className="form-group"
            input={
              <Field
                name="is_multi_day"
                type="checkbox"
                id="is_multi_day"
                disabled={!!is_multi_day}
                className="form-check-input"
                onChange={() => {
                  setIsOpen(true);
                }}
              />
            }
          />
        </FormGroup>
      )}
      {!is_multi_day && (
        <FormGroup label="Recall day type" name="recall_day_type" required>
          <FormCheck
            label="Recall today"
            translateLabel
            input={
              <IntegerRadioField
                name="recall_day_type"
                value={0}
                id="recall_day_type_today"
                className="form-check-input"
              />
            }
          />
          <FormCheck
            label="Recall previous day"
            translateLabel
            input={
              <IntegerRadioField
                name="recall_day_type"
                id="recall_day_type_yesterday"
                value={1}
                className="form-check-input"
              />
            }
          />
        </FormGroup>
      )}
      <Row form>
        <Col>
          <FormGroup label="Contact phone" name="contact_telephone">
            <Field
              name="contact_telephone"
              className="form-control"
              as={FastInput}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup label="Contact email" name="contact_email" required>
            <Field
              name="contact_email"
              className="form-control"
              validate={validateEmail()}
              as={FastInput}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup label="External redirect link" name="external_link">
        <PopoverInputGroup
          popover={t(
            "This link will redirect the participant to a different page when they submit their diary"
          )}
        >
          <Field
            name="external_link"
            validate={validateUrl({ required: false, stripeWhitespace: true })}
            className="form-control"
            as={FastInput}
          />
        </PopoverInputGroup>
      </FormGroup>
      <FormGroup
        label="External request link"
        name="external_backend_request_link"
      >
        <PopoverInputGroup
          popover={t(
            "This link will send a request to the link with the defined participant information"
          )}
        >
          <Field
            name="external_backend_request_link"
            validate={validateUrl({ required: false, stripeWhitespace: true })}
            className="form-control"
            as={FastInput}
          />
        </PopoverInputGroup>
        <Button
          color="link"
          size="sm"
          className="pl-0 my-1"
          onClick={() => setShowParams((v) => !v)}
        >
          Show allowable parameters
        </Button>
        {showParams ? (
          <div className="bg-gray-300 p-2">
            {"{{ respondentId }}"}
            <br />
            {"{{ email }}"}
            <br />
            {"{{ name }}"}
            <br />
            <br />
            <strong>Example:</strong>
            <br />
            {"https://myfood24.org?email={{ email }}"}
          </div>
        ) : null}
      </FormGroup>
      <DefaultLanguageField onChange={onDefaultLanguageChanged} />
      <FormGroup label="Project logo">
        <FileField name="logo" />
        {values.logo__name ? (
          <img
            key={values.logo__name}
            src={`/api/projects/${values.id || values.cloned_id}/logo?height=40&type=height`}
            className="h-10 w-auto mt-2 img-thumbnail"
            alt="Project logo"
          />
        ) : null}
        {errors.logo ? (
          <Alert color="danger" className="mt-1">
            <Trans>
              Logo must be an be a JPEG or PNG file (*.jpeg, *.jpg or *.png
              extension)
            </Trans>
          </Alert>
        ) : null}
      </FormGroup>
      {allowOffline ? (
        <FormCheck
          label={t("Allow offline")}
          name="offline"
          className="form-group"
          input={
            <Field
              name="offline"
              type="checkbox"
              id="offline"
              className="form-check-input"
            />
          }
        />
      ) : null}
    </>
  );
};

export default ProjectFields;
