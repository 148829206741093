import React from "react";
import { useTable, useModal, useClient } from "hooks";
import { Field, useFormik, FormikProvider } from "formik";
import {
  Page,
  DebouncedInput,
  FiltersForm,
  SubmitButton,
  Button,
} from "components/common";
import { Table } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "services/formatters";
import { faChartBar, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUserContext from "../../hooks/useUserContext";
import download from "downloadjs";
import { Trans } from "react-i18next";

const ExtractButton = ({ onSubmit }) => {
  const form = useFormik({
    onSubmit,
    initialValues: {},
  });

  return (
    <FormikProvider value={form}>
      <SubmitButton size="sm" color="light" className="whitespace-no-wrap ml-2">
        <FontAwesomeIcon icon={faEdit} /> <Trans>Extract</Trans>
      </SubmitButton>
    </FormikProvider>
  );
};

const CloneButton = ({ onSubmit: onSubmitProp }) => {
  const {
    isOpen,
    setIsOpen,
    Modal,
    ModalBody,
    ModalFooter,
    CloseButton,
  } = useModal();
  const form = useFormik({
    onSubmit,
    initialValues: {},
  });

  async function onSubmit() {
    await onSubmitProp();
    setIsOpen(false);
  }

  return (
    <>
      {isOpen ? (
        <FormikProvider value={form}>
          <Modal>
            <ModalBody>
              <Trans>
                Are you sure you want to clone this project? You will have the
                chance to confirm the new project's details before it is saved.
              </Trans>
            </ModalBody>
            <ModalFooter>
              <CloseButton />
              <SubmitButton>OK</SubmitButton>
            </ModalFooter>
          </Modal>
        </FormikProvider>
      ) : null}
      <Button
        size="sm"
        className="whitespace-no-wrap"
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon icon={faEdit} /> <Trans>Clone</Trans>
      </Button>
    </>
  );
};

export const List = () => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
    setFilters,
  } = useTable({
    url: "projects",
  });
  const client = useClient();
  const navigate = useNavigate();
  const { isAdmin, isTeacher } = useUserContext();

  return (
    <Page title="Projects" fluid translateTitle>
      <TableSearch />
      <div className="flex justify-between mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table size="sm" hover striped>
          <thead>
            <tr>
              <th select />
              <th sortBy="title">
                <Trans>Title</Trans>
              </th>
              <th sortBy="start_date">
                <Trans>Start date</Trans>
              </th>
              <th sortBy="end_date">
                <Trans>End date</Trans>
              </th>
              <th className="text-right">
                <Trans>Total participants</Trans>
              </th>
              <th className="text-right">
                <Trans>Complete diaries</Trans>
              </th>
              <th>
                <Trans>Owner</Trans>
              </th>
              <th />
            </tr>
            <FiltersForm
              persistKey="projects"
              onChange={setFilters}
              initialValues={{
                title: "",
                owned_by_user__name: "",
              }}
            >
              <tr>
                <td />
                <td>
                  <Field
                    name="title"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td />
                <td />
                <td />
                <td />
                <td>
                  <Field
                    name="owned_by_user__name"
                    className="form-control  form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td />
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${i.id}`}>{i.title}</Link>
                </td>
                <td>{formatDate(i.start_date)}</td>
                <td>{formatDate(i.end_date)}</td>
                <td className="text-right">
                  {i.num_participants + i.num_unaccepted}
                </td>
                <td className="text-right">{i.num_completed}</td>
                <td>
                  <Link to={`../users/${i.owned_by_user}`}>
                    {i.owned_by_user__name}
                  </Link>
                </td>
                <td className="whitespace-no-wrap">
                  <CloneButton
                    onSubmit={async () => {
                      const clone = await client
                        .post(`projects/${i.id}/clone`)
                        .get("data");
                      navigate("new", {
                        state: {
                          clone,
                        },
                      });
                    }}
                  />
                  {i.num_completed ? (
                    <>
                      <ExtractButton
                        onSubmit={async () => {
                          const { filename, content } = await client
                            .post(`projects/${i.id}/extract`)
                            .get("data");
                          download(content, filename);
                        }}
                      />
                      {isAdmin || isTeacher ? (
                        <Link
                          to={`./${i.id}/reports`}
                          className="btn btn-light btn-sm ml-2"
                        >
                          <FontAwesomeIcon icon={faChartBar} />{" "}
                          <Trans>View report</Trans>
                        </Link>
                      ) : null}
                    </>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};

export default List;
