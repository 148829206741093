import { useClient } from "../../../hooks";
import { useMutation } from "react-query";

export const useUpdateTour = ({ config } = {}) => {
  const client = useClient();

  return useMutation({
    mutationFn: (payload) => client.patch(`tour/0`, payload),
    ...config,
  });
};
