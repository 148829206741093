import React, { useState, useMemo, useEffect } from "react";
import { ReportContainer } from "./components";
import { uniqueId, reduce } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "selectors";
import numeral from "numeral";

const IntakeBar = ({
  intakeKey,
  gradient,
  percent: percentProp,
  aboveMax,
  value,
  unit,
}) => {
  const [percent, setPercent] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setPercent(percentProp);
    }, 0);
  }, [percentProp]);

  return (
    <div className="intake-chart__row intake-chart__row--intake">
      <div className="intake-chart__label-container intake-chart__label-container--intake">
        <span className="intake-chart__label intake-chart__label--intake">
          {t(intakeKey)}
        </span>
      </div>
      <div className="intake-chart__bar-container">
        <div
          className={`intake-chart__bar intake-chart__bar--intake intake-chart__bar--${gradient}`}
          style={{ width: percent + "%" }}
        />
        <div
          className={`intake-chart__bar-value intake-chart__bar-value--${gradient} intake-chart__bar-value--intake`}
        >
          {aboveMax ? (
            <span className="pl-1">
              {value} {unit} !
            </span>
          ) : (
            <>
              <span
                className={`intake-chart__bar-bullet intake-chart__bar-bullet--${gradient}`}
              />{" "}
              {value} {unit}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const ReferenceBar = ({ type, label, percent: percentProp, value, unit }) => {
  const [percent, setPercent] = useState();

  useEffect(() => {
    setTimeout(() => {
      setPercent(percentProp);
    }, 0);
  }, [percentProp]);

  return (
    <div className="intake-chart__row intake-chart__row--ref">
      <div className="intake-chart__label-container intake-chart__label-container--ref">
        <span className="intake-chart__label intake-chart__label--ref">
          <span
            className={`intake-chart__bullet intake-chart__bullet--${type}`}
          />
          {label}
        </span>
      </div>
      <div className="intake-chart__bar-container">
        <div
          style={percent !== undefined ? { width: percent + "%" } : null}
          className={`intake-chart__bar intake-chart__bar--ref intake-chart__bar intake-chart__bar--${type}`}
        />
        <div className="intake-chart__bar-value intake-chart__bar-value--ref">
          <span
            className={`intake-chart__bullet intake-chart__bullet--${type}`}
          />
          {value} {unit}
        </div>
      </div>
    </div>
  );
};

const calculateGradient = (value, references) => {
  const low = references.reduce(
    (min, ref) => (ref < min ? ref : min),
    references[0]
  );
  const high = references.reduce(
    (max, ref) => (ref > max ? ref : max),
    references[0]
  );

  if (value < low / 3) {
    return "superlow";
  }

  if (value > low / 3 && value < low) {
    return "low";
  }

  if (value > high) {
    return "high";
  }

  return "average";
};

export const CalorieConsumption = ({
  title = <Trans>Your Nutrient Summary</Trans>,
  data,
}) => {
  const {
    maleReferenceIntake,
    femaleReferenceIntake,
    fieldUnits,
    nutrientNames,
  } = useRecoilValue(reportingStaticSelector);
  const { t } = useTranslation();

  const datasets = useMemo(() => {
    if (!data) return null;

    return reduce(
      data,
      (acc, value, key) => {
        const male = maleReferenceIntake[key];
        const female = femaleReferenceIntake[key];
        const mean = (male + female) / 2;
        const barMax = mean * 1.5;
        const unit = fieldUnits[key] ?? "g";
        const references = [male, female];

        let percent = (value / barMax) * 100;
        if (percent > 100) {
          percent = 100;
        }

        acc.push({
          id: uniqueId(),
          intake: {
            value: numeral(value).format("0[.]00"),
            intakeKey: nutrientNames[key],
            percent,
            gradient: calculateGradient(value, references),
            aboveMax: value > barMax,
          },
          references: (male && female) ? [
            {
              type: "female",
              label: t("Female Reference"),
              value: female,
              percent: (female / barMax) * 100,
            },
            {
              type: "male",
              label: t("Male Reference"),
              value: male,
              percent: (male / barMax) * 100,
            },
          ] : [],
          mean,
          unit,
        });
        return acc;
      },
      []
    );
  }, [
    data,
    maleReferenceIntake,
    femaleReferenceIntake,
    nutrientNames,
    fieldUnits,
    t,
  ]);

  if (!datasets) return <ReportContainer title={title} loading />;

  return (
    <ReportContainer title={title}>
      {datasets.map(({ intake, id, references, unit }) => (
        <div key={id} className="intake-chart__dataset">
          <IntakeBar {...intake} unit={unit} />
          {references.map((j) => (
            <ReferenceBar key={j.type} {...j} unit={unit} />
          ))}
        </div>
      ))}
    </ReportContainer>
  );
};

export default CalorieConsumption;
