const shared = {
  disableBeacon: true,
  locale: {
    last: "Next",
  },
};

const getPatientSteps = () => {
  const common = {
    ...shared,
    title: "Invite Patients",
  };

  return [
    {
      ...common,
      target: "#self-register-button",
      content: (
        <p>
          You can invite patients to use myfood24 by using a unique link, click{" "}
          <strong>Self-Register Link</strong> to copy this to your clipboard to
          send to your patients via email or text message.
        </p>
      ),
    },
    {
      ...common,
      target: "#add-new-patient",
      content: (
        <p>
          Or invite patients using their details by clicking{" "}
          <strong>Add New Patient</strong>.
        </p>
      ),
    },
    {
      ...common,
      target: "#patient-table",
      content: (
        <>
          <p>Once you've invited your patients, they will appear here.</p>
          <p>
            Click on the <strong>patient name</strong> to view their food diary
            and more.
          </p>
        </>
      ),
    },
  ];
};

const getFoodDiarySteps = () => {
  const common = {
    ...shared,
    title: "Reviewing Food Diaries",
  };

  return [
    {
      ...common,
      target: "#food-diary-tab",
      content: (
        <>
          After selecting a patient from the patient list, click{" "}
          <strong>Food Diary</strong> to view the food diaries your patient has
          completed.
        </>
      ),
    },
    {
      ...common,
      target: "#food-diary-date-range",
      content: (
        <>
          Select your date range using the pre-defined options or a custom
          range.
        </>
      ),
    },
    {
      ...common,
      target: "#food-diary-calendars",
      content: (
        <>
          Dates highlighted in blue indicate when diaries have been submitted
          over the last 6 months to help you select an appropriate date range.
        </>
      ),
    },
    {
      ...common,
      target: "#food-diaries-table",
      offset: -200,
      content: (
        <>Here’s an overview of the food diaries for the date range selected.</>
      ),
    },
    {
      ...common,
      target: "#food-diary-view-details-0",
      content: (
        <>
          Click <strong>View details</strong> to see more in-depth food diary
          information including portion sizes and more nutrients.
        </>
      ),
    },
  ];
};

const getMealPlanSteps = () => {
  const common = {
    ...shared,
    title: "Create and Assign Meal Plans",
  };

  return [
    {
      ...common,
      target: "#create-assign-plans",
      content: (
        <>
          In the <strong>Meal Plans</strong> tab you can create new meal plans
          for your patients using the <strong>Create Plan</strong> button.
        </>
      ),
    },
    {
      ...common,
      target: "#meal-plan-patient-assign",
      content: (
        <>
          You can allocate patients new meal plans or use our sample templates
          using the <strong>Assign Plan</strong> button. You can assign plans to
          one or more patients at a time.
        </>
      ),
    },
    {
      ...common,
      target: "#meal-plan-table",
      content: (
        <>
          Assigned meal plans will appear here. Click on the plan to view more
          details or edit the plan.
        </>
      ),
    },
  ];
};

const getTargetSteps = () => {
  const common = {
    ...shared,
    title: "Customise Nutrient Targets",
  };

  return [
    {
      ...common,
      target: "#diet-and-targets-tab",
      content: (
        <>
          By default, nutrient targets are set to Reference Intakes based on sex
          and age.
        </>
      ),
    },
    {
      ...common,
      target: "#targets-table",
      content: (
        <>
          You can customise each nutrient to meet individual patient needs by
          typing here.
        </>
      ),
    },
    {
      ...common,
      target: "#add-target-button",
      content: (
        <>
          Use the <strong>Add Nutrients</strong> button to add additional
          nutrient targets for your patient.
        </>
      ),
    },
  ];
};

const getReportSteps = () => {
  const common = {
    ...shared,
    title: "Analyse Nutrient Reports",
  };

  return [
    {
      ...common,
      target: "#reports-tab",
      content: (
        <>
          The <strong>Reports</strong> tab gives an overview of the nutritional
          analysis. Select the date range to view reports and filter the graphs
          based on your nutrients of interest.
        </>
      ),
    },
    {
      ...common,
      target: "#nutrient-select",
      content: (
        <>
          Use <strong>Select nutrients</strong> to view one or more nutrients
          and see how intake compares with the target, over the selected date
          range.
        </>
      ),
    },
    {
      ...common,
      target: "#meal-nutrient-select",
      content: (
        <>
          Select nutrients of interest to see how they are distributed across
          meal events.
        </>
      ),
    },
    {
      ...common,
      target: "#nutrient-intake",
      content: (
        <>
          View the average nutrient intake for all nutrients with targets for
          the selected date range.
        </>
      ),
    },
    {
      ...common,
      target: "#top-contributors",
      content: (
        <>
          View the top contributing food and drinks item for the selected
          nutrient.
        </>
      ),
    },
    {
      ...common,
      target: "#daily-nutrient-summary",
      content: (
        <>
          Here’s an overview of all nutrients with a target assigned for the
          time period selected.
        </>
      ),
    },
  ];
};

export const useTourSectionSteps = () => {
  return {
    patients: getPatientSteps(),
    food_diaries: getFoodDiarySteps(),
    meal_plans: getMealPlanSteps(),
    targets: getTargetSteps(),
    reports: getReportSteps(),
  };
};
