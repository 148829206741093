import React, { useMemo, useEffect, useState } from "react";
import { keys, map } from "lodash";
import { ReportContainer, ReportTabs } from "./components";
import { Bar } from "react-chartjs-2";
import useReferenceAnnotations from "./useReferenceAnnotations";
import { Trans } from "react-i18next";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "../../selectors";

export const ParticipantAnalysis = ({
  data: dataProp,
  title = <Trans>Participant Analysis - Individual Diaries</Trans>,
}) => {
  const [active, setActive] = useState();
  const [options, setOptions] = useState([]);
  const { annotations, ReferenceInformation } = useReferenceAnnotations(active);
  const { fieldUnits } = useRecoilValue(reportingStaticSelector);

  useEffect(() => {
    setActive(null);
    setOptions([]);
    const options = keys(dataProp);
    const active = options[0];
    setOptions(options);
    setActive(active);
  }, [dataProp]);

  const data = useMemo(() => {
    if (!active || !dataProp) return null;

    const labels = map(dataProp[active], "respondent_id");
    const data = map(dataProp[active], (i) => i.value.toFixed(2));

    return {
      datasets: [
        {
          data,
          backgroundColor: "#3366cc",
        },
      ],
      labels,
    };
  }, [active, dataProp]);

  if (!data) return <ReportContainer title={title} loading />;

  return (
    <ReportContainer title={title}>
      <ReportTabs options={options} active={active} setActive={setActive} />
      <div className="overflow-x-auto max-w-full flex">
        <div className="mx-auto">
          <Bar
            width={dataProp[active].length * 30 + 200}
            height={350}
            data={data}
            options={{
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: (value) => {
                        return `${value} ${fieldUnits[active] ?? "g"}`;
                      },
                      suggestedMin: 0,
                    },
                  },
                ],
              },
              responsive: false,
              legend: {
                display: false,
              },
              annotation: {
                events: ["click"],
                annotations,
              },
              plugins: {
                labels: false,
              },
            }}
          />
        </div>
      </div>
      <ReferenceInformation />
    </ReportContainer>
  );
};

export default ParticipantAnalysis;
