import { Button as BaseButton } from "reactstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export const Button = ({
  tag: Tag = BaseButton,
  children,
  pending,
  disabled,
  ...props
}) => {
  return (
    <Tag disabled={!!(pending || disabled)} {...props}>
      {pending ? (
        <FontAwesomeIcon icon={faSpinner} className="mr-2" spin />
      ) : null}
      {children}
    </Tag>
  );
};
