import React from "react";
import { Button as BaseButton } from "reactstrap";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from 'classnames';

export const Button = ({
  pending,
  color = "primary",
  disabled,
  children,
  ...props
}) => {
  return (
    <BaseButton disabled={disabled || pending} color={color} {...props}>
      <FontAwesomeIcon className={cx("mr-2", !pending ? 'hidden' : '')} icon={faSpinner} spin />
      {children}
    </BaseButton>
  );
};

export default Button;
