import React, { useState } from "react";
import { Button } from "components/common";
import { useAsyncEffect, useParticipantClient } from "hooks";
import { useDiaryContext } from "./DiaryContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlusCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Trans, useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { CardHeader } from "./components/CardHeader";

export const MyRecipes = ({ onUnsavedEdit }) => {
  const { unsavedRecipe, setUnsavedRecipe } = useDiaryContext();
  const client = useParticipantClient();
  const [recipes, setRecipes] = useState();
  const { t } = useTranslation();

  useAsyncEffect(async () => {
    const recipes = await client.get("recipes").get("data");
    setRecipes(recipes);
  }, []);

  if (!recipes) return null;

  const recipeCount = recipes.length;
  const unsavedRecipes = unsavedRecipe ? 1 : 0;

  return (
    <div>
      <div className="text-center">
        <Trans>
          You have <strong>{{ recipeCount }} saved recipe/s</strong> and{" "}
          <strong>{{ unsavedRecipes }} unsaved recipe/s</strong>
        </Trans>
        <br />
        <Trans>
          Unsaved recipes will be automatically deleted if you create a new one.
        </Trans>
      </div>

      <Card className="border-brand-light mt-4">
        <CardHeader size="xs">
          <Trans>Unsaved</Trans>
        </CardHeader>
        {unsavedRecipe ? (
          <CardBody className="flex items-center py-2">
            <span className="font-medium text-brand-dark mr-auto">
              {unsavedRecipe.name || t("Untitled Recipe")}
            </span>
            <Link to={`../new`} className="btn btn-link">
              <FontAwesomeIcon icon={faEdit} size="lg" />
            </Link>
            <Button
              color="link"
              className="mr-2 text-red-500"
              onClick={() => {
                setUnsavedRecipe(null);
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </Button>
          </CardBody>
        ) : (
          <CardBody className="py-2">
            <Trans>No unsaved recipes</Trans>
          </CardBody>
        )}
        <CardHeader size="xs">
          <Trans>Saved</Trans>
        </CardHeader>
        {recipes.map((i, idx) => (
          <CardBody className="flex py-2" key={i.id}>
            <div className="mr-auto">
              <div className="font-semibold">{i.name}</div>
              <div className="text-sm opacity-75">
                {i.total_servings} <Trans>portion/s</Trans>
              </div>
            </div>
            <div>
              <Link to={`../../../recipes/${i.id}`} className="btn btn-link">
                <FontAwesomeIcon icon={faEdit} size="lg" />
              </Link>
              <Link
                to={`../${i.id}/delete`}
                className="btn btn-link text-red-500 ml-2"
              >
                <FontAwesomeIcon icon={faTimes} size="lg" />
              </Link>
            </div>
            <Link
              to={`../../../recipe_entry/new?recipe=${i.id}`}
              className="ml-8 btn btn-link text-brand-dark"
            >
              <FontAwesomeIcon icon={faPlusCircle} size="lg" />
            </Link>
          </CardBody>
        ))}
        {!recipes.length && (
          <CardBody className="py-2">
            <Trans>No saved recipes</Trans>
          </CardBody>
        )}
      </Card>
    </div>
  );
};

export default MyRecipes;
