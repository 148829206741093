import React, { useState } from "react";
import { Card } from "reactstrap";
import { useParticipantClient, useAsyncEffect } from "hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { FoodCardBody } from "./components/FoodCardBody";

export const Recent = () => {
  const client = useParticipantClient();
  const location = useLocation();
  const [items, setItems] = useState();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    const items = await client.get("food_items/recently_used").get("data");
    setItems(items);
  }, []);

  if (!items) return null;

  return (
    <div>
      <Card className="border-brand-light">
        {items.map((i, idx) => (
          <FoodCardBody
            description={i.description}
            brand={i.food_brand__name}
            onClick={() => {
              navigate(`../../food_entry/new?food_item=${i.id}`, {
                state: {
                  backLocation: location,
                },
              });
            }}
            key={i.id}
            idx={idx}
          />
        ))}
      </Card>
    </div>
  );
};

export default Recent;
