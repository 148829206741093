import React, { useRef, useEffect } from "react";
import { useFormik, FormikProvider, Field, FieldArray } from "formik";
import { FormGroup, Button, SubmitButton } from "components/common";
import { useDiaryContext } from "./DiaryContext";
import { useParticipantClient, useEventCallback, useModalContext } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import numeral from "numeral";
import { isEmpty } from "lodash";
import { validateDecimal, validateInt } from "services/validators";
import { ModalBody } from "reactstrap";
import { BackLinkContainer } from "./BackLink";
import { Trans, useTranslation } from "react-i18next";
import { ModalHeader } from "./components/ModalHeader";
import { FoodCardBody } from "./components/FoodCardBody";
import { Card, Col } from "reactstrap/es";

export const EditRecipe = () => {
  const {
    unsavedRecipe,
    currentRecipe,
    setUnsavedRecipe,
    setCurrentRecipe,
    setSearch,
  } = useDiaryContext();
  const formik = useFormik({
    onSubmit,
  });
  const { toggle } = useModalContext();
  const { resetForm, values, setFieldValue } = formik;
  const valuesRef = useRef();
  valuesRef.current = values;
  const navigate = useNavigate();
  const client = useParticipantClient();
  const { id } = useParams();
  const isNew = id === undefined;
  const { t } = useTranslation();

  async function onSubmit(x) {
    x.id
      ? await client.put(`recipes/${x.id}`, x).get("data")
      : await client.post("recipes", x).get("data");
    NotificationManager.success(t("Your recipe has been saved successfully."));
    resetForm({
      values: {},
    });

    setUnsavedRecipe(null);

    if (isNew) {
      navigate("../my_recipes");
    } else {
      navigate("../../search/recipes/my_recipes");
    }
  }

  const validateTotalServings = validateInt({ min: 0.01 });

  const recalculateTotalSize = useEventCallback(() => {
    if (!ingredients || validateTotalServings(total_servings)) return;

    const totalSize = ingredients
      .reduce(
        (
          acc,
          { actual_size_convertible_unit__to_base_conversion_rate, exact_size }
        ) => {
          const n =
            Number(exact_size || 0) *
            actual_size_convertible_unit__to_base_conversion_rate;
          acc.add(n);
          return acc;
        },
        numeral(0)
      )
      .format("0.00");
    const portionSize = numeral(totalSize)
      .divide(total_servings)
      .format("0.00");
    setFieldValue("total_size", totalSize);
    setFieldValue("portion_size", portionSize);
  });

  async function onMount() {
    setCurrentRecipe(null);

    let values = null;

    if (!isNew) {
      values = currentRecipe ?? (await client.get(`recipes/${id}`).get("data"));
    } else {
      values = unsavedRecipe;
      if (!values) {
        values = {
          name: "",
          total_servings: "1",
          total_size: "0.00",
          ingredients: [],
        };
        setUnsavedRecipe(values);
      }
    }

    resetForm({ values });
  }

  useEffect(() => {
    onMount();

    return () => {
      if (isNew) {
        setUnsavedRecipe(isEmpty(valuesRef.current) ? null : valuesRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { ingredients, total_servings } = values || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(recalculateTotalSize, [ingredients, total_servings]);

  if (isEmpty(values)) return null;

  const base = (children) =>
    isNew ? (
      <div>{children}</div>
    ) : (
      <>
        <ModalHeader toggle={toggle}>
          <BackLinkContainer to="../../search/recipes/my_recipes">
            <div>
              <Trans>Edit Recipe</Trans>
            </div>
          </BackLinkContainer>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </>
    );

  return base(
    <>
      {!values.id ? (
        <div className="flex justify-center">
          <ul className="list-inside mb-4">
            <li>
              <Trans>1. Naming your recipe</Trans>
            </li>
            <li>
              <Trans>2. Enter the number of people it serves</Trans>
            </li>
            <li>
              <Trans>3. Add the ingredients by searching for items</Trans>
            </li>
            <li>
              <Trans>4. Save recipe when complete</Trans>
            </li>
          </ul>
        </div>
      ) : null}
      <FormikProvider value={formik}>
        <FormGroup label="Recipe Name" name="name">
          <Field className="form-control" name="name" validate={(v) => !v} />
        </FormGroup>
        <div className="text-center border-t border-b border-grey-200 -mx-4 px-4 py-4 mb-4">
          <div className="mb-4">
            <Trans>Your recipe contains the following ingredients:</Trans>
          </div>
          {values.ingredients.length ? (
            <Card className="text-left border-brand-light">
              <FieldArray name="ingredients">
                {({ remove }) =>
                  values.ingredients.map((i, idx) => (
                    <FoodCardBody
                      idx={idx}
                      amount={numeral(i.exact_size).format('0[.][00]') + t(i.actual_size_convertible_unit__unit)}
                      key={i.key || i.id}
                      brand={i.food_item__food_brand__name}
                      description={i.food_item__description}
                      append={
                        <Col xs="auto">
                          <Button
                            className="text-red-500"
                            color="link"
                            onClick={() => {
                              remove(idx);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        </Col>
                      }
                    />
                  ))
                }
              </FieldArray>
            </Card>
          ) : null}
          <Button
            color="secondary"
            outline
            className="my-4"
            onClick={() => {
              setCurrentRecipe(valuesRef.current);
              setSearch("");

              if (isNew) {
                navigate("../../../search/all");
              } else {
                navigate("../../search/all");
              }
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="mr-2 opacity-50" />{" "}
            <Trans>Search</Trans>
          </Button>
        </div>
        <FormGroup
          label="How many people is this recipe for?"
          name="total_servings"
        >
          <Field
            className="form-control"
            name="total_servings"
            validate={validateTotalServings}
          />
        </FormGroup>
        <FormGroup
          label={
            <>
              <Trans>1 portion is</Trans> <Trans>g</Trans>
            </>
          }
          translateLabel={false}
          name="portion_size"
        >
          <Field
            className="form-control"
            name="portion_size"
            readOnly
            validate={validateDecimal()}
          />
        </FormGroup>
        <div className="text-right">
          <SubmitButton color="primary">
            {values.id ? t("Update Recipe") : t("Save Recipe")}
          </SubmitButton>
        </div>
      </FormikProvider>
    </>
  );
};

export default EditRecipe;
