import moment from "moment";

export const tour_sections = [
  "patients",
  "food_diaries",
  "meal_plans",
  "targets",
  "reports",
];

export const tour_section_label_mapping = {
  patients: "Invite Patients",
  food_diaries: "Reviewing Food Diaries",
  meal_plans: "Create and Assign Meal Plans",
  targets: "Customise Nutrient Targets",
  reports: "Analyse Nutrient Reports",
};

const requireDummyData = (filename) => require(`./data/dummy/${filename}`);

export const tour_url_dummy_data_mapping = {
  patients: requireDummyData("patients.json"),
  "patients/tour": requireDummyData("patient.json"),
  "reports/run_nutrient_intake/tour": requireDummyData("nutrient_intake.json"),
  "reports/nutrient_intake_by_meal/tour": requireDummyData(
    "nutrient_intake_by_meal"
  ),
  "reports/run_aggregated_nutrient_intake/tour": (config) => {
    if (config?.key_intake_by_nutrient) {
      return requireDummyData("aggregated_nutrient_intake_keyed.json");
    } else {
      return requireDummyData("aggregated_nutrient_intake.json");
    }
  },
  "reports/top_contributors/tour": requireDummyData("top_contributors.json"),
  "diary/tour": requireDummyData("diary.json"),
  "diary_history/tour": requireDummyData("diary_history.json"),
  "meal_plans/assignable_meal_plans/tour": requireDummyData(
    "assignable_meal_plans.json"
  ),
  "patients/tour/meal_plans": requireDummyData("meal_plans.json"),
  "diary_history/tour/last": [],
};

export const tour_date_range = [
  moment("2022-05-06").toDate(),
  moment("2022-05-12").toDate(),
];

export const tour_enabled = false;