import { useUserContext, useClient } from "hooks";

export const useParticipantClient = () => {
  const { participant } = useUserContext();

  return useClient({
    baseURL: `/api/participants/${participant}`,
  });
};

export default useParticipantClient;
