import React from "react";
import { useTranslation } from "react-i18next";
import Page from "../components/common/Page";
import useUserContext from "../hooks/useUserContext";

const DocumentLink = ({ filename, href }) => {
  return (
    <div className="mb-1">
      <a rel="noopener noreferrer" href={href}>{filename}</a>
    </div>
  );
};

const parseLink = content => {
  const [filename, href] = content.split(' - ');
  return <DocumentLink key={href} filename={filename} href={href} />
}

const links = `Researcher French - https://content.app-us1.com/zDAaV/2023/12/08/f397c717-1ee4-4027-a683-6b2af27d9613.pdf
Researcher German - https://content.app-us1.com/zDAaV/2023/12/08/1e7e8424-8960-4153-8a34-7f4b4f2f6a23.pdf
Researcher Australian - https://content.app-us1.com/zDAaV/2023/12/08/3021dec6-f934-4d51-9378-3991e1c9a095.pdf
Researcher Arabic - https://content.app-us1.com/zDAaV/2023/12/08/becc7449-4156-4e83-9021-8f15de0c6ffe.pdf
Participant Spanish - https://content.app-us1.com/zDAaV/2023/12/08/fa3f1060-aae7-4d7a-b59e-f86992e2ae0f.pdf
Participant UK - https://content.app-us1.com/zDAaV/2023/12/08/706ef97b-bf8b-4fcd-ad67-c91ff9ede12f.pdf
Offline UK - https://content.app-us1.com/zDAaV/2023/12/08/7a0b4f1f-c25f-41fd-a746-d8bca6f46d83.pdf
Offline Spanish - https://content.app-us1.com/zDAaV/2023/12/08/f7c44a5d-f862-413a-83e9-2e9d96d0d7a5.pdf
Teacher - https://content.app-us1.com/zDAaV/2023/12/08/1b1a82f3-572a-4f9b-8c71-0969c1150057.pdf
Participant Norwegian - https://content.app-us1.com/zDAaV/2023/12/08/c8b73eab-fb2c-4111-af69-480582b0b5c7.pdf
Participant French - https://content.app-us1.com/zDAaV/2023/12/08/3c331f6f-bf92-4fd9-bca7-38b861f2b83c.pdf
Participant Denmark - https://content.app-us1.com/zDAaV/2023/12/08/1bba7ab4-596a-47f2-9ccc-39f196d27140.pdf
Participant Italian - https://content.app-us1.com/zDAaV/2023/12/08/fdd30392-7960-4c51-8b2d-ce51fe8ecc16.pdf 
Participant Arabic - https://content.app-us1.com/zDAaV/2023/12/08/d5fcc82c-6a18-49f8-861a-b78fc6d4aecb.pdf
Researcher UK - https://content.app-us1.com/zDAaV/2023/12/08/ad41c59b-b5bb-4f19-9d6f-14fd7b75f493.pdf
Researcher Spanish - https://content.app-us1.com/zDAaV/2023/12/08/b02a4a7a-a057-4d62-9eea-c00ab1a7b2b4.pdf
Researcher US - https://content.app-us1.com/zDAaV/2023/12/08/725b1100-6333-423f-8302-56711c93b0d4.pdf`.split('\n').map(parseLink);

const teachingLinks = `Teacher - https://content.app-us1.com/zDAaV/2023/12/08/1b1a82f3-572a-4f9b-8c71-0969c1150057.pdf
Participant - https://content.app-us1.com/zDAaV/2023/12/08/706ef97b-bf8b-4fcd-ad67-c91ff9ede12f.pdf`.split('\n').map(parseLink);


export const Support = () => {
  const { t } = useTranslation();
  const { isResearcher, isTeacher } = useUserContext();

  return (
    <Page title={t("User Manuals")}>
      {isResearcher ? links : null}
      {isTeacher ? teachingLinks : null}
    </Page>
  );
};
