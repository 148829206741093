import YesNoField from "./YesNoField";
import {
  Button,
  FastInput,
  FormGroup as BaseFormGroup,
} from "../../../components/common";
import React from "react";
import { Field, FieldArray, useFormikContext } from "formik";
import { Table } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { uniqueId } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";

const FormGroup = (props) => (
  <BaseFormGroup translateLabel={false} {...props} />
);

export const EligibilityQuestions = () => {
  const { values } = useFormikContext();
  const { show_eligibility_questions, eligibility_questions } = values;
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      <FormGroup label={t("Show eligibility questions?")} required>
        <YesNoField name="show_eligibility_questions" />
      </FormGroup>
      <FieldArray name="eligibility_questions">
        {({ remove, push }) => (
          <div className={show_eligibility_questions !== 1 ? "hidden" : ""}>
            <Table
              size="sm"
              borderless
              className={cx(
                "max-w-screen-sm",
                !eligibility_questions.length && "hidden"
              )}
            >
              <thead>
                <tr>
                  <th>
                    <Trans>Question</Trans>
                  </th>
                  <th className="whitespace-no-wrap">
                    <Trans>Required answer</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {eligibility_questions.map((i, idx) => (
                  <tr key={i.key || i.id}>
                    <td style={{ width: "99%" }}>
                      <FormGroup
                        name={`eligibility_questions.${idx}.question`}
                        className="mb-0"
                      >
                        <Field
                          name={`eligibility_questions.${idx}.question`}
                          className="form-control form-control-sm"
                          placeholder={t("e.g. Are you aged 18 or over?")}
                          as={FastInput}
                          validate={(v) => !v}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <YesNoField
                        inline
                        name={`eligibility_questions.${idx}.required_answer`}
                        yesValue="yes"
                        noValue="no"
                      />
                    </td>
                    <td>
                      <Button
                        size="sm"
                        color="light"
                        block
                        onClick={() => {
                          remove(idx);
                        }}
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Button
              size="sm"
              className="mt-2"
              onClick={() => {
                push({
                  question: "",
                  required_answer: 1,
                  key: uniqueId("eligibility-questions"),
                });
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faPlus} />
              <Trans>Add question</Trans>
            </Button>
          </div>
        )}
      </FieldArray>
    </div>
  );
};
