import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useClient } from "hooks";
import { Trans, useTranslation } from "react-i18next";
import { Table, Button } from "reactstrap";
import { FormGroup, SubmitButton, Page } from "components/common";
import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { uniqueId } from "lodash";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationManager } from "react-notifications";

export const MedicalConditions = () => {
  const client = useClient();
  const url = "medical_conditions";
  const { t } = useTranslation();

  const { data, refetch } = useQuery(url, () => client.get(url).get("data"), {
    suspense: true,
  });

  const initialValues = useMemo(() => ({ data }), [data]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });

  async function onSubmit({ data }) {
    await client.post(`${url}/bulk`, data);
    await refetch({ throwOnError: true });
    NotificationManager.success(t("Saved successfully"));
  }

  const { values } = formik;

  return (
    <Page title={t("Medical conditions")} fluid>
      <FormikProvider value={formik}>
        <FieldArray name="data">
          {({ push, remove }) => (
            <Table size="sm">
              <thead>
                <tr>
                  <th>
                    <Trans>Key</Trans>
                  </th>
                  <th>
                    <Trans>Name</Trans>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {values.data.map((i, idx) => (
                  <tr key={i.id || i._id}>
                    <td>
                      <FormGroup className="mb-0" name={`data.${idx}.key`}>
                        <Field
                          name={`data.${idx}.key`}
                          validate={(v) => !v}
                          className="form-control"
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup className="mb-0" name={`data.${idx}.name`}>
                        <Field
                          name={`data.${idx}.name`}
                          validate={(v) => !v}
                          className="form-control"
                        />
                      </FormGroup>
                    </td>
                    <td className="w-1">
                      <Button
                        color="link"
                        onClick={() => remove(idx)}
                        className="text-red-500"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3} className="text-right">
                    <Button
                      size="sm"
                      onClick={() => {
                        push({
                          name: "",
                          key: "",
                          _id: uniqueId(url),
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />{" "}
                      <Trans>Add another</Trans>
                    </Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
          )}
        </FieldArray>
        <div className="flex justify-end">
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};
