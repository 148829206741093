import React from "react";
import { Page, Button } from "../../components/common";
import { useForm, FormProvider } from "react-hook-form";
import {
  DateRangePicker,
  FormGroup,
  Select,
} from "../../healthcare/components";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { useClient } from "../../hooks";
import { Card, Col, Row } from "reactstrap";
import { formatISODate } from "../../services/formatters";
import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import download from "downloadjs";
import { Link } from "react-router-dom";

const initialDateRange = [null, null];

const Item = ({ title, children }) => {
  return (
    <div className="text-center">
      <div className="font-semibold">{title}</div>
      <div className="font-semibold text-secondary text-lg">{children}</div>
    </div>
  );
};

const Section = ({ title, children }) => {
  return (
    <Card body className="border-secondary">
      <p className="text-primary font-semibold mb-4">{title}</p>
      {children}
    </Card>
  );
};

export const HealthcareDashboard = () => {
  const methods = useForm({
    defaultValues: {
      organisation: null,
      date_range: initialDateRange,
      type: "average",
    },
    mode: "onChange",
  });
  const { watch, formState, register } = methods;
  const { isValid } = formState;
  const values = watch();
  const { type } = values;
  const payload = useMemo(() => {
    const { organisation, date_range, type } = values;

    return {
      organisation,
      type,
      from_date: date_range[0] ? formatISODate(date_range[0]) : null,
      to_date: date_range[1] ? formatISODate(date_range[1]) : null,
    };
  }, [values]);
  const { t } = useTranslation();
  const client = useClient();

  const { data: organisations } = useQuery(
    ["healthcare_organisations/basic"],
    ({ queryKey }) => client.get(...queryKey).get("data"),
    {
      select: (v) =>
        v.map((i) => ({
          ...i,
          name: i.exclude_from_dashboard ? `${i.name} (x)` : i.name,
        })),
    }
  );

  const { mutateAsync: exportDashboard, isLoading } = useMutation(
    async (payload) => {
      const { type, organisation, from_date, to_date } = payload;
      const data = await client
        .post("healthcare_dashboard/export", payload, {
          responseType: "arraybuffer",
        })
        .get("data");

      const filename = [
        `healthcare_dashboard_${moment().format("YYYYMMDD")}`,
        type,
        organisation ? `organisation_${organisation}` : "",
        from_date ? `from_${from_date}` : "",
        to_date ? `to_${from_date}` : "",
      ].filter(Boolean);

      download(data, filename.join("_") + ".xlsx");
    }
  );

  const { data, isFetching } = useQuery(
    ["healthcare_dashboard", payload],
    ({ queryKey }) => client.post(...queryKey).get("data"),
    {
      enabled: isValid,
    }
  );

  const isAverage = type === "average";
  const typeTitle = isAverage ? t("Average") : t("Total");

  return (
    <Page title={t("Healthcare Dashboard")} translateTitle={false}>
      <FormProvider {...methods}>
        <Row form>
          <Col lg="auto">
            <FormGroup className="lg:w-64" label={t("Organisation")}>
              <Select
                placeholder={t("Select organisation")}
                simpleValue
                name="organisation"
                options={organisations}
                labelKey="name"
                valueKey="id"
                isClearable
              />
            </FormGroup>
          </Col>
          <Col lg="auto">
            <FormGroup label={t("Patient created date range")}>
              <div className="flex">
                <DateRangePicker name="date_range" />
              </div>
            </FormGroup>
          </Col>
          <Col lg="auto">
            <FormGroup label={t("Type")}>
              <select ref={register} name="type" className="form-control">
                <option value="average">{t("Averages")}</option>
                <option value="total">{t("Totals")}</option>
              </select>
            </FormGroup>
          </Col>
          <Col lg="auto" className="self-center pt-1">
            <Button
              disabled={!isValid}
              pending={isLoading}
              onClick={() => exportDashboard(payload)}
            >
              {t("Export")}
            </Button>
            {isFetching && (
              <FontAwesomeIcon
                icon={faSpinner}
                className="text-gray-500 self-center ml-2"
                spin
              />
            )}
          </Col>
        </Row>
      </FormProvider>
      {data ? (
        <>
          <hr />
          <div className="lg:grid grid-cols-2 gap-4 space-y-4 lg:space-y-0">
            <Section title={t("Organisations using myfood24 healthcare")}>
              <div className="grid grid-cols-3 gap-4">
                <Item title={t("Organisations")}>
                  <Link
                    className="text-secondary underline"
                    to="../healthcare_organisations"
                  >
                    {data.organisations_using.organisations}
                  </Link>
                </Item>
                <Item title={t("{{ typeTitle }} HCPs", { typeTitle })}>
                  <Link className="text-secondary underline" to="../hcp_users">
                    {data.organisations_using.hcps}
                  </Link>
                </Item>
                <Item title={t("HCP activation rate")}>
                  {data.organisations_using.hcp_activation_rate}%
                </Item>
              </div>
            </Section>
            <Section title={t("Patients using myfood24 healthcare")}>
              <div className="grid grid-cols-3 gap-4">
                <Item title={t("Invited")}>{data.patients_using.invited}</Item>
                <Item title={t("Activated")}>
                  {data.patients_using.activated}
                </Item>
                <Item title={t("Activation rate")}>
                  {data.patients_using.activation_rate}%
                </Item>
              </div>
            </Section>
            <Section title={t("Patients completing at least 1 myfood24 diary")}>
              <div className="grid grid-cols-3 gap-4">
                {[
                  ["total", "Total"],
                  ["week_1", "Week 1"],
                  ["month_1", "Month 1"],
                  ["month_6", "Month 6"],
                  ["year_1", "Year 1"],
                ].map(([key, title]) => (
                  <React.Fragment key={key}>
                    <Item title={t(title)}>
                      {data.patients_at_least_1[key].num}
                    </Item>
                    <Item title={t("% of invited")}>
                      {data.patients_at_least_1[key].percentage_of_invited}%
                    </Item>
                    <Item title={t("% of activated")}>
                      {data.patients_at_least_1[key].percentage_of_activated}%
                    </Item>
                  </React.Fragment>
                ))}
              </div>
            </Section>
            <div className="space-y-4">
              <Section
                title={
                  isAverage
                    ? t("Average number of diaries completed per patient")
                    : t("Total number of diaries completed")
                }
              >
                <div className="grid grid-cols-5 gap-4">
                  <Item title={t("Total")}>
                    {data.patients_completed.total}
                  </Item>
                  <Item title={t("Week 1")}>
                    {data.patients_completed.week_1}
                  </Item>
                  <Item title={t("Month 1")}>
                    {data.patients_completed.month_1}
                  </Item>
                  <Item title={t("Month 6")}>
                    {data.patients_completed.month_6}
                  </Item>
                  <Item title={t("Year 1")}>
                    {data.patients_completed.year_1}
                  </Item>
                </div>
              </Section>
              <Section title={t("Average myfood24 diaries")}>
                <div className="grid grid-cols-2 gap-4">
                  <Item title={t("Time to complete")}>
                    {data.time_to_complete.total}
                  </Item>
                  <Item title={t("Number of items")}>
                    {data.time_to_complete.items}
                  </Item>
                </div>
              </Section>
            </div>
          </div>
        </>
      ) : null}
    </Page>
  );
};
