import React from "react";
import { useFormik, FormikProvider } from "formik";
import { Link } from "react-router-dom";
import TermsLinks from "components/TermsLinks";
import { SubmitButton, Form } from "components/common";
import LoginBase from "./components/LoginBase";
import Title from "./components/Title";
import { createClient } from "hooks/useClient";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import PasswordFields from "components/PasswordFields";
import { Trans, useTranslation } from "react-i18next";
import { BackgroundWave } from "./components/BackgroundWave";
import { Card } from "reactstrap";
import { SubTitle } from "./components/SubTitle";

export const ResetPassword = () => {
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      success: false,
      error: null,
    },
    onSubmit,
  });
  const { setFieldValue } = formik;
  const { success, error } = formik.values;
  const { token } = useParams();
  const { t } = useTranslation();

  async function onSubmit({ password }) {
    const client = createClient();
    setFieldValue("success", false);
    setFieldValue("error", null);

    try {
      await client.post("password_reset", { password, token });
      setFieldValue("success", true);
    } catch (ex) {
      const code = ex?.response?.data?.code;

      const error = {
        InvalidToken: t(
          "This password reset request is invalid or has already been used"
        ),
        TokenExpired: t("This password reset request has expired"),
        PasswordFailsValidation: t("Please provide a stronger password"),
      }[code];

      if (error) {
        setFieldValue("error", error);
      } else {
        throw ex;
      }
    }
  }

  const backToLogin = (
    <div className="text-right">
      <Link to={"../../login"} className="text-sm link--white">
        <Trans>Back to login</Trans>
      </Link>
    </div>
  );

  return (
    <LoginBase
      rightSide={
        <div className="max-w-md mx-auto relative">
          <BackgroundWave />
          <Title>
            <Trans>Reset password</Trans>
          </Title>
          {!success ? (
            <>
              <SubTitle>
                <Trans>Please confirm your new password below</Trans>
              </SubTitle>
              <FormikProvider value={formik}>
                <Form className="mt-4">
                  <Card body className="mb-2">
                    <PasswordFields />
                  </Card>
                  {backToLogin}
                  <SubmitButton type="submit" block className="mt-6">
                    <Trans>Reset password</Trans>
                  </SubmitButton>
                </Form>
                {error ? (
                  <Alert color="danger" className="mt-2">
                    {error}
                  </Alert>
                ) : null}
              </FormikProvider>
            </>
          ) : (
            <Card body>
              <div className="prose prose-sm text-center mb-4">
                <p className="text-center">
                  <Trans>
                    Your password has been successfully changed. You may now{" "}
                    <Link to={"../../login"}>login</Link>.
                  </Trans>
                </p>
              </div>
            </Card>
          )}
        </div>
      }
      footer={<TermsLinks className="text-center mt-4" />}
    />
  );
};

export default ResetPassword;
