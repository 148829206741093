import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useUserContext } from "../../../hooks";
import { Trans } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { SubmitButton } from "../../../components";

export const StartTourModal = ({
  isOpen,
  onReject,
  toggle,
  onConfirm,
  size,
}) => {
  const { user } = useUserContext();
  const methods = useForm();
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <Modal isOpen={isOpen} size={size}>
        <ModalHeader toggle={toggle}>
          <Trans>Welcome to myfood24, {{ name: user?.name }}!</Trans>
        </ModalHeader>
        <ModalBody className="prose max-w-full">
          <p>
            <Trans>
              myfood24 is nutrition analysis software to help you keep track of
              your patients' diet.
            </Trans>
          </p>
          <p>
            <Trans>
              We'd love to show you around. Would you like a quick tour of
              myfood24 to help you get started?
            </Trans>
          </p>
        </ModalBody>
        <ModalFooter>
          <SubmitButton
            outline
            onClick={handleSubmit(onReject)}
            className="mr-auto"
          >
            <Trans>No, I'll explore myself</Trans>
          </SubmitButton>
          <SubmitButton onClick={handleSubmit(onConfirm)}>
            <Trans>Yes, I'd like a tour</Trans>
          </SubmitButton>
        </ModalFooter>
      </Modal>
    </FormProvider>
  );
};
