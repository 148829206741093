import { useEffect } from "react";
import { userSelector } from "./selectors";
import { useRecoilValueLoadable, useRecoilCallback } from "recoil";
import {
  tokenAtom,
  participantAtom,
  userGroupAtom,
  impersonatorAtom,
  healthcareTokenAtom,
  healthcarePatientAtom,
  healthcareUserGroupAtom,
  healthcareImpersonatorAtom,
} from "./atoms";
import { AuthenticationFailedError } from "./errors";
import { NotificationManager } from "react-notifications";

export const UserAtomEffect = () => {
  const userLoadable = useRecoilValueLoadable(userSelector);
  const { state, contents } = userLoadable;
  const hasError = state === "hasError";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    useRecoilCallback(({ reset }) => () => {
      if (!hasError) return;

      if (contents instanceof AuthenticationFailedError) {
        NotificationManager.info("You have been logged out");
        reset(tokenAtom);
        reset(participantAtom);
        reset(userGroupAtom);
        reset(impersonatorAtom);
        reset(healthcareTokenAtom);
        reset(healthcarePatientAtom);
        reset(healthcareUserGroupAtom);
        reset(healthcareImpersonatorAtom);
      }
    }),
    [hasError]
  );

  return null;
};
