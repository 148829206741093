import React from "react";
import { Page, FormGroup, YesNoField, SubmitButton } from "components/common";
import { FormikProvider, Field, useFormik } from "formik";
import { useParticipantClient, useUserContext } from "hooks";
import { NotificationManager } from "react-notifications";
import { Trans, useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-webpack-loader-syntax
import da from "!!raw-loader!./assets/da-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import en from "!!raw-loader!./assets/en-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import no from "!!raw-loader!./assets/no-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import fr from "!!raw-loader!./assets/fr-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import de from "!!raw-loader!./assets/de-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import ar from "!!raw-loader!./assets/ar-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import es from "!!raw-loader!./assets/es-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import enUS from "!!raw-loader!./assets/en_us-help.txt";
// eslint-disable-next-line import/no-webpack-loader-syntax
import it from "!!raw-loader!./assets/it-help.txt";

const mapping = {
  en: en,
  fr: fr,
  no: no,
  de: de,
  da: da,
  ar: ar,
  es: es,
  en_us: enUS,
  it: it
};

const DanishVideo = ({ url, children }) => {
  return (
    <li>
      <a href="https://www.youtube.com/watch?v=vNhqW3s1Fus">
        {children}
      </a>
    </li>
  )
}

const HelpContent = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const content = mapping[language] || mapping["en"];
  const isDanish = language === 'da';

  return (
    <article className="prose prose-sm">
      <section>
        <ul>
          {isDanish ? (
            <li>
              <p className="mb-0 font-semibold">Hurtig hjælp - Danske videoer af Bispebjerg og Frederiksberg Hospital</p>
              <ul>
                <DanishVideo url="https://www.youtube.com/watch?v=vNhqW3s1Fus">
                  Kom i gang med MyFood24
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=Xn2-_Hq6EeU">
                  Samlet gennemgang af kostregistrering i MyFood24
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=FIJ2aYZjkQU">
                  Registrering af Morgenmad
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=8GdbMYZFmAM">
                  Registrering af Frokost
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=SKSbWr6apK0">
                  Registrering af Aftensmad
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=13xcTdlr7k4">
                  Registrer Mellemmåltid & Drikkelse i MyFood24
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=4rJKPIrQ6DQ">
                  Rediger eller Slet i kostregistreringen MyFood24
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=0HoiUleknzc">
                  Tilføj opskrift & Lav liste i MyFood24
                </DanishVideo>
                <DanishVideo url="https://www.youtube.com/watch?v=3TfvD1LHhCg">
                  Afslut kostdagbog
                </DanishVideo>
              </ul>
            </li>
          ) : null}
          <li>
            <a href="#getting-started">
              <Trans>myfood.help_page.getting_started</Trans>
            </a>
            <ul>
              <li>
                <a href="#getting-started-video">
                  <span className="help-highlight">
                    <Trans>myfood.help_page.watch_video</Trans>
                  </span>
                  <Trans>myfood.help_page.getting_started</Trans> (59{" "}
                  <Trans>myfood.help_page.seconds</Trans>)
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#searching-foods">
              <Trans>myfood.help_page.searching_foods</Trans>
            </a>
            <ul>
              <li>
                <a href="#search-bar">
                  <Trans>myfood.help_page.search_bar</Trans>
                </a>
              </li>
              <li>
                <a href="#make-a-list">
                  <Trans>Make a list</Trans>
                </a>
                <ul>
                  <li>
                    <a href="#make-a-list-video">
                      <span className="help-highlight">
                        <Trans>myfood.help_page.watch_video</Trans>
                      </span>
                      <Trans>Make a list</Trans> (51{" "}
                      <Trans>myfood.help_page.seconds</Trans>)
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#using-search-filters">
                  <Trans>myfood.help_page.using_filters</Trans>
                </a>
              </li>
              <li>
                <a href="#recently-used">
                  <Trans>Recently used</Trans>
                </a>
              </li>
              <li>
                <a href="#search-tips">
                  <Trans>myfood.help_page.search_tips</Trans>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#how-much-did-you-have">
              <Trans>myfood.help_page.how_much_you_have</Trans>
            </a>
            <ul>
              <li>
                <a href="#selecting-portion-size-video">
                  <span className="help-highlight">
                    <Trans>myfood.help_page.watch_video</Trans>
                  </span>
                  <Trans>myfood.help_page.select_portion_size</Trans> (1{" "}
                  <Trans>myfood.help_page.minutes</Trans>)
                </a>
              </li>
              <li>
                <a href="{{ url('participant_help_portion_sizes') }}">
                  <Trans>myfood.help_page.typical_sizes</Trans>
                </a>
              </li>
              <li>
                <a href="#converter">
                  <Trans>myfood.help_page.converter</Trans>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#adding-and-removing-items">
              <Trans>myfood.help_page.adding_and_removing</Trans>
            </a>
            <ul>
              <li>
                <a href="#adding-to-your-food-diary">
                  <Trans>myfood.help_page.adding_to_diary</Trans>
                </a>
                <ul>
                  <li>
                    <a href="#adding-food-and-drink-video">
                      <span className="help-highlight">
                        <Trans>myfood.help_page.watch_video</Trans>
                      </span>
                      <Trans>myfood.help_page.adding_food_and_drink</Trans> (47{" "}
                      <Trans>myfood.help_page.seconds</Trans>)
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#removing-and-editing-items-in-your-food-diary">
                  <Trans>myfood.help_page.removing_and_editing</Trans>
                </a>
                <ul>
                  <li>
                    <a href="#edit-food-or-drink-video">
                      <span className="help-highlight">
                        <Trans>myfood.help_page.watch_video</Trans>
                      </span>
                      <Trans>myfood.help_page.edit_food</Trans> (23{" "}
                      <Trans>myfood.help_page.seconds</Trans>)
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href="#adding-your-own-recipes-to-recipe-builder">
              <Trans>myfood.help_page.adding_recipes</Trans>
            </a>
            <ul>
              <li>
                <a href="#recipe-builder-video">
                  <span className="help-highlight">
                    <Trans>myfood.help_page.watch_video</Trans>
                  </span>
                  <Trans>Recipe builder</Trans> (1{" "}
                  <Trans>myfood.help_page.minutes</Trans> 40{" "}
                  <Trans>myfood.help_page.seconds</Trans>)
                </a>
              </li>
              <li>
                <a href="#accessing-saved-recipes">
                  <Trans>myfood.help_page.accessing_recipes</Trans>
                </a>
              </li>
              <li>
                <a href="#editing-saved-recipes">
                  <Trans>myfood.help_page.editing_recipes</Trans>
                </a>
              </li>
              <li>
                <a href="#editing-recipe-portion-size">
                  <Trans>myfood.help_page.editing_portion_size</Trans>
                </a>
              </li>
              <li>
                <a href="#adding-your-own-recipes-tips">
                  <Trans>myfood.help_page.tips</Trans>
                </a>
                <ul>
                  <li>
                    <a href="#recipe-pancakes-video">
                      <span className="help-highlight">
                        <Trans>myfood.help_page.watch_video</Trans>
                      </span>
                      <Trans>myfood.help_page.recipe_pancakes</Trans>(1{" "}
                      <Trans>myfood.help_page.minutes</Trans> 58{" "}
                      <Trans>myfood.help_page.seconds</Trans>)
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <a href="#reviewing-your-food-diary">
              <Trans>myfood.help_page.reviewing_and_submitting</Trans>
            </a>
            <ul>
              <li>
                <a href="#submit-diary-video">
                  <span className="help-highlight">
                    <Trans>myfood.help_page.watch_video</Trans>
                  </span>
                  <Trans>myfood.help_page.submit_diary</Trans>(1{" "}
                  <Trans>myfood.help_page.minutes</Trans> 50{" "}
                  <Trans>myfood.help_page.seconds</Trans>)
                </a>
              </li>
              <li>
                <a href="#if-you-have-forgotten-something">
                  <Trans>myfood.help_page.forgotten</Trans>
                </a>
              </li>
              <li>
                <a href="#additional-questions-comments">
                  <Trans>myfood.help_page.questions</Trans>
                </a>
              </li>
              <li>
                <a href="#submitting-your-diary">
                  <Trans>myfood.help_page.submitting</Trans>
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#opting-out-of-the-project">
              <Trans>myfood.help_page.opt_out</Trans>
            </a>
          </li>
        </ul>
      </section>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </article>
  );
};

export const Help = () => {
  const formik = useFormik({
    initialValues: {
      reason: "",
      allow_data_usage: null,
    },
    validate: ({ allow_data_usage }) => {
      const errors = {};
      if (allow_data_usage === null) errors.allow_data_usage = true;
      return errors;
    },
    onSubmit,
  });
  const client = useParticipantClient();
  const { t } = useTranslation();
  const { logout } = useUserContext();

  async function onSubmit(x) {
    await client.post("opt_out", x);
    NotificationManager.success("Opted-out successfully");
    await logout();
  }

  return (
    <Page title="Contents" translateTitle>
      <HelpContent />
      <div className="prose prose-sm max-w-none">
        <h2>
          <Trans>Opting out of the project</Trans>
        </h2>
        <p>
          <Trans>
            If you no longer wish to be part of this research project then
            please complete the form below. Once this has been submitted you
            will no longer be contacted regarding this project.
          </Trans>
        </p>
        <FormikProvider value={formik}>
          <FormGroup
            label="Please explain why you would like to opt out of the project"
            name="reason"
          >
            <Field
              name="reason"
              as="textarea"
              rows={3}
              placeholder={t("Enter your text here...")}
              className="form-control"
            />
          </FormGroup>
          <FormGroup
            label="Allow the researcher to use the data already collected (if any)?"
            required
            name="allow_data_usage"
          >
            <YesNoField name="allow_data_usage" />
          </FormGroup>
          <div className="flex justify-end">
            <SubmitButton color="primary">
              <Trans>Opt Out</Trans>
            </SubmitButton>
          </div>
        </FormikProvider>
      </div>
    </Page>
  );
};

export default Help;
