import React from "react";
import DiaryDateAnalysis from "./DiaryDateAnalysis";
import DiaryStageAnalysis from "./DiaryStageAnalysis";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "selectors";
import { MealAnalysis } from "./MealAnalysis";
import { useTranslation, Trans } from "react-i18next";
import {MacronutrientReport} from "./MacronutrientReport";
import {Col, Row} from "reactstrap";

export const StudentReports = ({ nutrientIntake, projectReports, sustainabilityReport }) => {
  const {
    stage_analysis,
    dates,
    dates_analysis,
    participants,
    macronutrient_analysis
  } = projectReports;
  const { meals } = useRecoilValue(reportingStaticSelector);
  const { t } = useTranslation();

  return (
    <>
      <Row form className="items-stretch">
        <Col className="flex">
          <MacronutrientReport data={macronutrient_analysis} diaries={dates.length} />
        </Col>
        <Col className="flex">
          <MealAnalysis
            title={t("Meal Analysis")}
            mealChoices={meals}
            nutrientIntake={nutrientIntake}
          />
        </Col>
      </Row>
      {sustainabilityReport}
      {stage_analysis || dates_analysis ? (
        <h3 className="text-center text-xl my-2">
          <Trans>
            All diaries submitted for this project ({{ participants }}{" "}
            submitted)
          </Trans>
        </h3>
      ) : null}
      {stage_analysis ? <DiaryStageAnalysis data={stage_analysis} /> : null}
      {dates_analysis ? (
        <DiaryDateAnalysis data={dates_analysis} dates={dates} />
      ) : null}
    </>
  );
};

export default StudentReports;
