import { useEffect, useState, useRef } from "react";
import { keys } from "lodash";

const defaultGetOptions = (data) => keys(data);

export const useActiveNutrient = (
  data,
  { getOptions = defaultGetOptions } = {}
) => {
  const [options, setOptions] = useState(() => getOptions(data));
  const [active, setActive] = useState(() => options[0]);
  const activeData = data[active];
  const dataRef = useRef(data);

  useEffect(() => {
    if (data.current !== data) {
      setActive(null);
      setOptions([]);
      const options = getOptions(data);
      const active = options[0];
      setOptions(options);
      setActive(active);
    }
    dataRef.current = data;
  }, [data, getOptions]);

  return { active, setActive, options, setOptions, activeData };
};

export default useActiveNutrient;
