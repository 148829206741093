import React from "react";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { usePopover } from "hooks";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PopoverInputGroup = ({ children, popover }) => {
  const { id, Popover } = usePopover();
  return (
    <>
      <InputGroup>
        {children}
        <InputGroupAddon addonType="append" id={id} className="cursor-pointer">
          <InputGroupText>
            <FontAwesomeIcon icon={faQuestion} className="text-secondary" />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <Popover target={id} trigger="hover" placement="bottom-end" flip={false}>
        <div className="p-2">{popover}</div>
      </Popover>
    </>
  );
};

export default PopoverInputGroup;
