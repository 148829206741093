import React from "react";
import Button from "./Button";
import { useFormikContext } from "formik";

export const SubmitButton = ({
  pending,
  disabled,
  disabledWhenInvalid,
  type = "button",
  ...props
}) => {
  const { isSubmitting, submitForm, isValid } = useFormikContext();

  const localProps = {
    disabled: disabled || (disabledWhenInvalid ? !isValid : false),
    pending: isSubmitting || pending,
    type,
  };

  if (type !== "submit") {
    localProps.onClick = submitForm;
  }

  return <Button {...localProps} {...props} />;
};

export default SubmitButton;
