import React, { useMemo, useState } from "react";
import BaseDatePicker from "react-datepicker";
import moment from "moment";
import { useEventCallback } from "hooks";
import { useField } from "formik";

export const DateInput = ({ ...props }) => {
  const [readOnly, setReadOnly] = useState();

  return (
    <input
      {...props}
      onFocus={() => setReadOnly(true)}
      onBlur={() => setReadOnly(false)}
      readOnly={readOnly}
    />
  );
};

export const DatePicker = ({
  dateFormat = "dd/MM/yyyy",
  modelDateFormat = "YYYY-MM-DD",
  name,
  validate,
  ...props
}) => {
  const [{ value, onBlur }, , { setValue }] = useField({
    name,
    validate,
  });

  const selected = useMemo(
    () => (value ? moment(value, modelDateFormat).toDate() : null),
    [value, modelDateFormat]
  );

  const _onChange = useEventCallback((v) => {
    const m = moment(v);
    setValue(m.isValid() ? m.format(modelDateFormat) : null);
  });

  return (
    <BaseDatePicker
      onChange={_onChange}
      selected={selected}
      dateFormat={dateFormat}
      onBlur={onBlur}
      name={name}
      autoComplete="off"
      strictParsing
      placeholderText="dd/mm/yyyy"
      {...props}
    />
  );
};

export default DatePicker;
