import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import cx from 'classnames';

export const AddButton = ({
  icon = faPlusCircle,
  tag: Tag = Link,
  children,
  outline,
  ...props
}) => {
  return (
    <Tag {...props} className={cx('btn', outline ? 'btn-outline-primary' : 'btn-primary')}>
      {icon ? <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> : null}
      {children}
    </Tag>
  );
};
