import React, { useMemo, useState } from "react";
import { keys, map } from "lodash";
import { ReportContainer, ReportTabs } from "./components";
import { Line } from "react-chartjs-2";
import pallete from "google-palette";
import useActiveNutrient from "./useActiveNutrient";
import { Trans } from "react-i18next";
import { Legend } from "./Legend";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "../../selectors";

export const DiaryStageIndividual = ({
  data: dataProp,
  projectStages,
  title = <Trans>Diary name breakdown analysis - Individual</Trans>,
}) => {
  const { active, setActive, options } = useActiveNutrient(dataProp);
  const [chartRef, setChartRef] = useState();
  const { fieldUnits } = useRecoilValue(reportingStaticSelector);

  const datasets = useMemo(() => {
    if (!active) return null;

    const num = keys(dataProp[active]).length;

    const colors = [
      ...pallete("mpn65", num > 65 ? 65 : num),
      ...(num > 65 ? pallete("rainbow", num - 65) : []),
    ];

    return map(dataProp[active], (i, label) => {
      const color = "#" + colors.pop();

      return {
        data: projectStages.map((j) =>
          i[j] !== null ? i[j].toFixed(2) : null
        ),
        label,
        borderWidth: 2,
        lineTension: 0,
        spanGaps: true,
        fill: false,
        borderColor: color,
      };
    });
  }, [projectStages, active, dataProp]);

  if (!datasets) return <ReportContainer title={title} loading />;

  const renderData = (canvas) => {
    return {
      datasets,
      labels: projectStages,
    };
  };

  return (
    <ReportContainer title={title}>
      <ReportTabs options={options} active={active} setActive={setActive} />
      <Legend chartRef={chartRef} />
      <Line
        ref={setChartRef}
        data={renderData}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: (value) => {
                    return `${value} ${fieldUnits[active] ?? "g"}`;
                  },
                  suggestedMin: 0,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
        }}
      />
    </ReportContainer>
  );
};

export default DiaryStageIndividual;
