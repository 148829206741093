import { useField } from "formik";
import React, { useRef, useEffect } from "react";

const FileField = ({ name, validate, multiple, onChange, ...props }) => {
  const [{ value }, , { setValue, setTouched }] = useField({
    name,
    validate,
  });
  const inputRef = useRef();
  const filename = value?.name ?? "";

  useEffect(() => {
    if (!filename) inputRef.current.value = "";
  }, [filename, inputRef]);

  return (
    <input
      ref={inputRef}
      type="file"
      multiple={multiple}
      name={name}
      onChange={async (e) => {
        const { files } = e.target;

        const result = await Promise.mapSeries(
          files,
          (f) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", () => {
                const { name, size, type } = f;
                resolve({
                  name,
                  size,
                  type,
                  content: reader.result,
                });
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(f);
            })
        );

        const value = multiple ? result : result[0] ?? null;
        setValue(value, false);
        setTouched(true);

        if (onChange) {
          onChange(value);
        }
      }}
      {...props}
    />
  );
};

export default FileField;
