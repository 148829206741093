import { useClient } from "../../../hooks";
import { useQuery } from "react-query";

export const useRetrieveTour = ({ config } = {}) => {
  const client = useClient();

  return useQuery({
    queryKey: ["tour"],
    queryFn: ({ queryKey }) => client.get(...queryKey).get("data"),
    ...config,
  });
};
