import pallete from "google-palette";

export const getLabel = (label) => {
  const mapping = {
    monounsaturated_acid_per_100g_food: "Mono-unsaturated fat",
    polyunsaturated_acid_per_100g_food: "Polyunsaturated fat",
    trans_acid_per_100g_food: "Trans fat",
    kilojoules: "Energy",
    _: " ",
  };

  return label.replace(
    /monounsaturated_acid_per_100g_food|polyunsaturated_acid_per_100g_food|trans_acid_per_100g_food|kilojoules|_/gi,
    (matched) => {
      return mapping[matched];
    }
  );
};

export const getPallet = (num) => {
  return [
    ...pallete("mpn65", num > 65 ? 65 : num),
    ...(num > 65 ? pallete("rainbow", num - 65) : []),
  ].map((i) => `#${i}`);
};
