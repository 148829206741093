import React, {useEffect, useState} from "react";
import {
  Page,
  FormGroup,
  SubmitButton,
  Select,
  FormCheck,
} from "components/common";
import { Field, useFormik, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { validateInt, validateEmail } from "services/validators";
import PasswordFields from "../../components/PasswordFields";
import { Trans } from "react-i18next";
import {PIIUserButton} from "../../features/pii/PIIUserButton";
import {Badge} from "reactstrap";

const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const Edit = () => {
  const formik = useFormik({
    onSubmit,
    validate: () => {},
  });
  const { resetForm, values, setFieldError, setFieldValue } = formik;
  const [fetchedItem, setFetchedItem] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const client = useClient();
  const isNew = id === "new";
  const url = "users";
  const title = isNew ? "Create User" : "Edit User";

  async function onSubmit(_x) {
    const x = {
      ..._x,
      password: _x.password || undefined,
    };

    try {
      const values = await (isNew
        ? client.post("users", x)
        : client.put(`${url}/${id}`, x)
      ).get("data");
      navigate(`../${values.id}`, { replace: true });
      setFetchedItem(values);
      resetForm({ values });
      NotificationManager.success("Saved successfully");
    } catch (ex) {
      const errors = ex?.response?.data?.validation_errors;
      let handled = false;

      if (errors?.email) {
        setFieldError("email", "A user already exists with this email address");
        handled = true;
      }
      if (errors?.username) {
        setFieldError("username", "A user already exists with this username");
        handled = true;
      }
      if (errors?.password) {
        setFieldError("password", "Please provide a stronger password");
        handled = true;
      }

      if (!handled) {
        throw ex;
      }
    }
  }

  async function onPIISubmitted() {
    const values = await client.get(`${url}/${id}`).get("data");

    setFetchedItem(values);

    resetForm({
      values: {
        ...values,
        password: "",
        confirm_password: "",
      },
    });
  }

  useAsyncEffect(async () => {
    const values = isNew
      ? {
          email: "",
          first_name: "",
          last_name: "",
          age: "",
          gender: "",
          username: "",
          is_active: true,
          is_superuser: true,
          is_teacher: false,
          is_researcher: false,
          is_participant: false,
          include_ghge_in_extracts: false
        }
      : await client.get(`${url}/${id}`).get("data");

    setFetchedItem(values);

    resetForm({
      values: {
        ...values,
        password: "",
        confirm_password: "",
      },
    });
  }, []);

  const is_researcher = values?.is_researcher ?? null;

  useEffect(() => {
    if (is_researcher === false) {
      setFieldValue("allow_offline_projects", false);
    }
  }, [is_researcher, setFieldValue]);

  if (!values) return null;

  return (
    <Page title={title}>
      {values?.healthcare_user_id ? (
        <div className="flex justify-end">
          <Badge color="primary">
            Healthcare user
          </Badge>
        </div>
      ) : null}
      <FormikProvider value={formik}>
        <FormGroup label="First name" required name="first_name">
          <Field
            name="first_name"
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Last name" required name="last_name">
          <Field
            name="last_name"
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Age" name="age">
          <Field
            name="age"
            validate={validateInt({ required: false })}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Gender" name="gender">
          <Select name="gender" options={GENDERS} simpleValue />
        </FormGroup>
        <FormGroup label="Username" required name="username" showError>
          <Field
            name="username"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Email address" required name="email" showError>
          <Field
            name="email"
            validate={validateEmail({
              requiredError: true,
              invalidError: true,
            })}
            className="form-control"
          />
        </FormGroup>
        <PasswordFields required={!values.id} showError />
        <FormGroup label="Groups">
          <FormCheck
            label="Is Administrator?"
            name="is_superuser"
            input={
              <Field
                name="is_superuser"
                type="checkbox"
                className="form-check-input"
                id="is_superuser"
              />
            }
          />
          <FormCheck
            label="Is Teacher?"
            name="is_teacher"
            input={
              <Field
                name="is_teacher"
                type="checkbox"
                className="form-check-input"
                id="is_teacher"
              />
            }
          />
          <FormCheck
            label="Is Researcher?"
            name="is_researcher"
            input={
              <Field
                name="is_researcher"
                type="checkbox"
                className="form-check-input"
                id="is_researcher"
              />
            }
          />
          <FormCheck
            label="Is Participant?"
            name="is_participant"
            input={
              <Field
                name="is_participant"
                type="checkbox"
                className="form-check-input"
                id="is_participant"
              />
            }
          />
          <FormCheck
            label={<Trans>Account is enabled</Trans>}
            name="is_active"
            input={
              <Field
                name="is_active"
                type="checkbox"
                className="form-check-input"
                id="is_active"
              />
            }
          />
          {values.is_researcher ? (
            <FormCheck
              label={<Trans>Allow offline projects</Trans>}
              name="allow_offline_projects"
              input={
                <Field
                  name="allow_offline_projects"
                  type="checkbox"
                  className="form-check-input"
                  id="allow_offline_projects"
                />
              }
            />
          ) : null}
          {values.is_researcher || values.is_teacher || values.is_superuser ? (
            <FormCheck
              label={<Trans>Include GHGE in project extract?</Trans>}
              name="include_ghge_in_extracts"
              input={
                <Field
                  name="include_ghge_in_extracts"
                  type="checkbox"
                  className="form-check-input"
                  id="include_ghge_in_extracts"
                />
              }
            />
          ) : null}
        </FormGroup>
        {values.id && (
          !fetchedItem?.is_superuser && (fetchedItem?.is_teacher || fetchedItem?.is_researcher)
        ) ? (
          <PIIUserButton userId={values.id} onSubmitted={onPIISubmitted} />
        ) : null}
        <div className="flex justify-end">
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};

export default Edit;
