import { useRef, useCallback, useLayoutEffect } from "react";

export const useEventCallback = (fn) => {
  const ref = useRef(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args) => ref.current.apply(void 0, args), []);
};

export default useEventCallback;
