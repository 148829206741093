import React from "react";
import { useLocation, useResolvedPath } from "react-router";
import { NavLink as BaseNavLink } from "react-router-dom";
import classNames from "classnames";

export const NavLink = ({ to, ...props }) => {
  const location = useLocation();
  const resolvedLocation = useResolvedPath(to);
  const match = location.pathname.startsWith(resolvedLocation.pathname);

  return (
    <BaseNavLink
      to={to}
      activeClassName=""
      className={classNames(
        "btn btn-sm",
        match ? "btn-brand-primary" : "btn-outline-brand-primary"
      )}
      {...props}
    />
  );
};
