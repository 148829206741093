import {Page} from "../../components/common";
import {FormProvider, useForm} from "react-hook-form";
import {useClient} from "../../hooks";
import download from 'downloadjs';
import {Button, Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {DatePicker, FormGroup} from "../../healthcare/components";
import {formatISODate} from "../../services/formatters";
import moment from 'moment';
const url = 'search_metrics';


const defaultValues = {
  from_date: formatISODate(moment().subtract(3, 'months')),
  to_date: formatISODate(moment())
}

export const SearchMetricsView = () => {
  const form = useForm({
    defaultValues
  });
  const client = useClient();
  const { handleSubmit, formState } = form;
  const { isSubmitting } = formState;

  async function onDownload(x) {
    const resp = await client.post(url, x, {
      responseType: "arraybuffer",
    }).get('data')
    download(resp, 'search_metrics.zip');
  }

  return (
    <Page title="Search Metrics">
      <FormProvider {...form}>
        <Row form>
          <Col lg>
            <FormGroup name="from_date" label="From date">
              <DatePicker className="form-control" name="from_date" rules={{
                required: true
              }} defaultValue={null} />
            </FormGroup>
          </Col>
          <Col lg>
            <FormGroup name="to_date" label="To date">
              <DatePicker className="form-control" name="to_date" rules={{
                required: true
              }} defaultValue={null} />
            </FormGroup>
          </Col>
        </Row>
        <Button onClick={handleSubmit(onDownload)} disabled={isSubmitting}>
          {isSubmitting && (<FontAwesomeIcon className="mr-2" icon={faSpinner} spin />)}
          Download search metrics
        </Button>
      </FormProvider>
    </Page>
  )
}