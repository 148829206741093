import { useMemo, useRef, useState } from "react";
import { useGetLatest } from "react-table";
import { useUpdateTour } from "../queries/useUpdateTour";
import { useTourSectionSteps } from "./useTourSectionSteps";
import { useNavigate } from "react-router";
import { useRetrieveTour } from "../queries/useRetrieveTour";
import { tour_section_label_mapping, tour_sections } from "../constants";
import { useDeleteTour } from "../queries/useDeleteTour";
import { findLastIndex } from "lodash";

export const useTour = ({ user }) => {
  const instanceRef = useRef({});
  const getInstance = useGetLatest(instanceRef.current);
  const updateTourMutation = useUpdateTour();
  const deleteTourMutation = useDeleteTour();
  const instance = instanceRef.current;
  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);
  const [steps, setSteps] = useState(null);
  const [section, setSection] = useState(null);
  const sectionSteps = useTourSectionSteps();
  const navigate = useNavigate();

  const tourQuery = useRetrieveTour({
    config: {
      enabled: !!user && !user?.is_patient,
    },
  });
  const { refetch: refetchTour, data } = tourQuery;

  const percentComplete = useMemo(() => {
    if (!data) return 0;

    const numCompleted = tour_sections.reduce((acc, i) => {
      if (data[i]) acc++;
      return acc;
    }, 0);

    return Math.floor((numCompleted / tour_sections.length) * 100);
  }, [data]);

  const sections = useMemo(() => {
    if (!data) return [];

    return tour_sections.map((key) => {
      return {
        label: tour_section_label_mapping[key],
        key,
        complete: !!data[key],
      };
    });
  }, [data]);

  const isComplete = percentComplete === 100;

  const lastCompleteIndex = useMemo(() => {
    if (!sections.length) return -1;
    return findLastIndex(sections, { complete: true });
  }, [sections]);

  const isLastComplete = lastCompleteIndex === sections.length - 1;

  const nextSection = useMemo(() => {
    if (!sections.length) return null;

    if (isLastComplete) {
      return sections.find((i) => !i.complete)?.key ?? null;
    } else {
      return (
        sections.find((i, idx) => idx > lastCompleteIndex && !i.complete)
          ?.key ?? null
      );
    }
  }, [sections, lastCompleteIndex, isLastComplete]);

  const toggleStart = () => setIsStartOpen((v) => !v);
  const toggleList = () => setIsListOpen((v) => !v);

  const startTour = () => {
    setIsStartOpen(true);
  };

  const getStartTourProps = (props = {}) => {
    return {
      isOpen: isStartOpen,
      toggle: toggleStart,
      onReject: onTourRejected,
      onConfirm: onTourAccepted,
      ...props,
    };
  };

  const startSection = async (key) => {
    if (key === "food_diaries") {
      navigate(`/healthcare/patients/tour/food_diary`, {
        replace: true,
      });
    } else if (key === "patients") {
      navigate("/healthcare/patients", {
        replace: true,
      });
    } else if (key === "meal_plans") {
      navigate(`/healthcare/patients/tour/meal_plans`, {
        replace: true,
      });
    } else if (key === "targets") {
      navigate(`/healthcare/patients/tour/diet_and_targets`, {
        replace: true,
      });
    } else if (key === "reports") {
      navigate(`/healthcare/patients/tour/reports`, {
        replace: true,
      });
    }
    setSection(key);
    await Promise.delay(1000);
    setIsListOpen(false);
    setIsStartOpen(false);
    await Promise.delay(500);
    setSteps(sectionSteps[key]);
  };

  const isTourActive = !!(section || isListOpen || steps);

  const handleContinue = async () => {
    await startSection(nextSection);
  };

  const handleJoyrideCallback = async (state) => {
    const { type } = state;
    if (type === "tour:end") {
      setIsListOpen(true);
      setSteps(null);
      setSection(null);
      await updateTour({
        [section]: true,
      });
    }
  };

  const getJoyrideProps = (props) => {
    return {
      steps: steps ?? [],
      run: !!steps,
      callback: handleJoyrideCallback,
      ...props,
    };
  };

  const handleMoreHelp = () => {
    setIsListOpen(false);
    navigate("/healthcare/help");
  };

  const handleFinish = () => {
    setIsListOpen(false);
    navigate("/healthcare/patients");
  };

  const getTourListProps = (props = {}) => {
    return {
      isOpen: isListOpen,
      toggle: toggleList,
      onFinish: handleFinish,
      onContinue: handleContinue,
      sections,
      percentComplete,
      isComplete,
      onMoreHelp: handleMoreHelp,
      startSection,
      isLastComplete,
      ...props,
    };
  };

  async function resetTour() {
    await deleteTourMutation.mutateAsync();
    await refetchTour();
  }

  const isRunning = !!steps;

  Object.assign(instance, {
    data: tourQuery.data,
    dataQuery: tourQuery,
    ready: tourQuery.isSuccess,
    isStartOpen,
    setIsStartOpen,
    toggleList,
    toggleStart,
    isListOpen,
    setIsListOpen,
    startTour,
    getTourListProps,
    getStartTourProps,
    getInstance,
    startSection,
    getJoyrideProps,
    steps,
    percentComplete,
    sections,
    isComplete,
    nextSection,
    resetTour,
    isLastComplete,
    isRunning,
    isTourActive,
  });

  async function updateTour(payload) {
    await updateTourMutation.mutateAsync(payload);
    await refetchTour();
  }

  async function onTourRejected() {
    await updateTour({
      accepted: false,
    });
    setIsStartOpen(false);
  }

  async function onTourAccepted() {
    await updateTour({
      accepted: true,
    });
    await startSection("patients");
  }

  return instance;
};
