import React from "react";
import { Page } from "components/common";
import { useUserContext } from "hooks";
import { Button } from "reactstrap";
import { useNavigate } from "react-router";
import { formatDate } from "./services/formatters";

export const SelectUserType = () => {
  const { user, setUserGroup, setParticipant } = useUserContext();
  const {
    teachers,
    participants,
    researchers,
    is_superuser,
    healthcare_user,
  } = user;
  const navigate = useNavigate();

  const noAccess = !(
    is_superuser ||
    teachers.length ||
    participants.length ||
    researchers.length ||
    healthcare_user
  );

  if (noAccess) {
    return (
      <Page className="prose">
        <h3 className="my-0">
          You do not currently have access to any active projects
        </h3>
      </Page>
    );
  }

  return (
    <Page title="Please select an account">
      {is_superuser ? (
        <div className="mb-4">
          <h3>Administrator</h3>
          <ul>
            <li>
              <Button
                color="link"
                onClick={() => {
                  setUserGroup("admin");
                  setTimeout(() => {
                    navigate("/");
                  }, 0);
                }}
              >
                {user.full_name}
              </Button>
            </li>
          </ul>
        </div>
      ) : null}
      {teachers.length ? (
        <div className="mb-4">
          <h3>Teacher</h3>
          <ul>
            {teachers.map((i) => (
              <li key={i.id}>
                <Button
                  color="link"
                  onClick={() => {
                    setUserGroup("teacher");
                    setTimeout(() => {
                      navigate("/");
                    }, 0);
                  }}
                >
                  {user.full_name}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {researchers.length ? (
        <div className="mb-4">
          <h3>Researcher</h3>
          <ul>
            {researchers.map((i) => (
              <li key={i.id}>
                <Button
                  color="link"
                  onClick={() => {
                    setUserGroup("researcher");
                    setTimeout(() => {
                      navigate("/");
                    }, 0);
                  }}
                >
                  {user.full_name}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
      {healthcare_user ? (
        <div className="mb-4">
          <h3>Healthcare user</h3>
          <ul>
            <li>
              <Button
                color="link"
                onClick={() => {
                  setUserGroup("healthcare_user");
                  setTimeout(() => {
                    navigate("/");
                  }, 0);
                }}
              >
                {user.full_name}
              </Button>
            </li>
          </ul>
        </div>
      ) : null}
      {participants.length ? (
        <div className="mb-4">
          <h3>Participant</h3>
          <ul>
            {participants.map((i) => (
              <li key={i.id}>
                <Button
                  color="link"
                  onClick={() => {
                    setUserGroup("participant");
                    setParticipant(i.id);
                    setTimeout(() => {
                      navigate("/");
                    }, 0);
                  }}
                >
                  {i.project__title}
                  {i.project__is_multiple_project && i.participant_date
                    ? ` (${formatDate(i.participant_date)})`
                    : null}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </Page>
  );
};

export default SelectUserType;
