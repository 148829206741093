import React, { forwardRef } from "react";
import { useFormikContext, isFunction } from "formik";
import { useEventCallback } from "hooks";

/**
 * Formiks default Form component swallows the submitForm rejection which causes issues with our generic handler
 */
export const Form = forwardRef((props, ref) => {
  const { action, ...rest } = props;
  const _action = action || "#";
  const { handleReset, submitForm } = useFormikContext();

  const handleSubmit = useEventCallback((e) => {
    if (e && e.preventDefault && isFunction(e.preventDefault)) {
      e.preventDefault();
    }

    if (e && e.stopPropagation && isFunction(e.stopPropagation)) {
      e.stopPropagation();
    }

    submitForm();
  });

  return (
    <form
      onSubmit={handleSubmit}
      ref={ref}
      onReset={handleReset}
      action={_action}
      {...rest}
    />
  );
});

export default Form;
