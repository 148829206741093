import { createContext, useContext } from "react";

const TourContext = createContext(null);

export const TourProvider = ({ children, ...props }) => {
  return <TourContext.Provider value={props}>{children}</TourContext.Provider>;
};

export const useTourContext = () => {
  return useContext(TourContext);
};
