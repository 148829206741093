import { useMutation, useQuery } from "react-query";
import { useClient } from "../../hooks";

export const VERSION = "1.0";
export const APPS_URL = `${VERSION}/apps`;
export const APP_API_KEYS_URL = `${VERSION}/app_api_keys`;

export function useApp(id, config) {
  const client = useClient();

  return useQuery({
    queryKey: [`${APPS_URL}/${id}`],
    queryFn: ({ queryKey }) => client.get(...queryKey).get("data"),
    ...config,
  });
}

export function useFoodDatabases(config) {
  const client = useClient();

  return useQuery({
    queryKey: ["food_databases"],
    queryFn: ({ queryKey }) => client.get(...queryKey).get("data"),
    ...config,
  });
}

export function useAppSave() {
  const client = useClient();

  return useMutation({
    mutationFn: (v) =>
      (!v.id
        ? client.post(APPS_URL, v)
        : client.put(`${APPS_URL}/${v.id}`, v)
      ).get("data"),
  });
}

export function useAppAPIKeySave() {
  const client = useClient();

  return useMutation({
    mutationFn: (v) => client.post(APP_API_KEYS_URL, v).get("data"),
  });
}

export function useAppAPIKeyRevoke() {
  const client = useClient();

  return useMutation({
    mutationFn: (pk) =>
      client.post(`${APP_API_KEYS_URL}/${pk}/revoke`).get("data"),
  });
}
