import YesNoField from "./YesNoField";
import {
  FastInput,
  FileField,
  FormGroup as BaseFormGroup,
} from "../../../components/common";
import React, { useEffect } from "react";
import {Field, useFormikContext} from "formik";
import { Alert } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";

const FormGroup = (props) => (
  <BaseFormGroup translateLabel={false} {...props} />
);

export const ConsentQuestion = () => {
  const { values, errors, setFieldValue } = useFormikContext();
  const { show_consent_question, consent_document__name, id } = values;
  const { t } = useTranslation();

  useEffect(() => {
    if (show_consent_question === 0) {
      setFieldValue("local.consent_document", null, true);
    }
  }, [show_consent_question, setFieldValue]);

  return (
    <>
      <FormGroup label={t("Show consent question?")} required>
        <YesNoField name="show_consent_question" />
      </FormGroup>
      {show_consent_question === 1 ? (
        <>
          <FormGroup
            name="consent_text"
            label={t('Enter consent text')}
            required
          >
            <Field
              name="consent_text"
              className="form-control"
              placeholder={t("e.g. You are being invited to take part in the myfood24 study.")}
              as={FastInput}
              validate={(v) => !v}
            />
          </FormGroup>
          <FormGroup
            name="consent_document"
            label={t("Upload consent document (PDF) (optional)")}
          >
            <FileField name="consent_document" />
            {consent_document__name ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`/api/projects/${id}/consent_document/${consent_document__name}`}
                className="ml-2"
              >
                <Trans>View document</Trans>
              </a>
            ) : null}
            {errors.consent_document === "invalid_type" ? (
              <Alert color="danger" className="mt-1">
                <Trans>Consent document must be an be a PDF file (*.pdf)</Trans>
              </Alert>
            ) : null}
          </FormGroup>
        </>
      ) : null}
    </>
  );
};
