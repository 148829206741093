import {FormProvider, useForm} from "react-hook-form";
import {SubmitButton} from "../../healthcare/components";
import {useClient, useModal} from "../../hooks";
import {useState} from "react";
import {NotificationManager} from "react-notifications";
import {Trans, useTranslation} from "react-i18next";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export function PIIUserButton({ userId, onSubmitted }) {
  const { t } = useTranslation();
  const form = useForm();
  const { register, handleSubmit } = form;
  const client = useClient();
  const [projects, setProjects] = useState(null);
  const {
    Modal: ModalConfirm,
    toggle: toggleConfirm,
    CloseButton: CloseButtonConfirm,
    isOpen: isOpenConfirm,
    setIsOpen: setIsOpenConfirm
  } = useModal();
  const {
    Modal: ModalResult,
    toggle: toggleResult,
    CloseButton: CloseButtonResult,
    isOpen: isOpenResult,
    setIsOpen: setIsOpenResult
  } = useModal();

  async function onSubmit(x) {
    const { projects } = await client.post(`users/${userId}/pii`, x).get('data');

    if (onSubmitted) {
      await onSubmitted();
    }

    const projectsWithSkips = projects.filter(i => i.skipped.length);

    setIsOpenConfirm(false);

    if (projectsWithSkips.length) {
      setProjects(projectsWithSkips);
      setIsOpenResult(true);
    } else {
      NotificationManager.success(t('PII successful'));
    }
  }

  return (
    <>
      {isOpenResult && projects ? (
        <ModalResult size="lg">
          <ModalHeader toggle={toggleResult}>
            <Trans>
              Result
            </Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>
              The following participants were not able to be processed due to one of the following reasons:
              <ul className="list-disc list-inside pl-3">
                <li>The user is a participant in other projects</li>
                <li>The user is a healthcare user</li>
                <li>The user is the owner of projects</li>
              </ul>
            </Trans>
            <hr/>
            {projects.map(i => (
              <div key={i.project.id} className="mb-2">
                <strong>{i.project.id} - {i.project.title}</strong>
                {i.skipped.map(i => (
                  <li key={i.id}>
                    {i.full_name} ({i.email})
                  </li>
                ))}
              </div>
            ))}
          </ModalBody>
          <ModalFooter>
            <CloseButtonResult />
          </ModalFooter>
        </ModalResult>
      ) : null}
      {isOpenConfirm ? (
        <ModalConfirm>
          <ModalHeader toggle={toggleConfirm}>
            <Trans>PII User</Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>
              Are you sure you want to remove personally identifiable
              information from this user. <strong>This cannot be undone.</strong>
            </Trans>
            <hr/>
            <div className="form-check">
              <label htmlFor="pii_associated_projects" className="form-check-label">
                <input id="pii_associated_projects" name="pii_associated_projects" className="form-check-input" type="checkbox" ref={register} />
                <Trans>Also PII associated projects</Trans>
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <CloseButtonConfirm />
            <FormProvider {...form}>
              <SubmitButton onClick={handleSubmit(onSubmit)}>
                PII User
              </SubmitButton>
            </FormProvider>
          </ModalFooter>
        </ModalConfirm>
      ) : null}
      <Button color="primary" onClick={toggleConfirm}>
        <Trans>
          Delete PII
        </Trans>
      </Button>
    </>
  )
}