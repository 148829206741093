import { createContext, useMemo, useContext } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  healthcareImpersonatorAtom,
  healthcarePatientAtom,
  healthcareTokenAtom,
  healthcareUserGroupAtom
} from "../../atoms";
import { useClient } from "./useClient";

const UserContext = createContext(null);

export const UserContextProvider = ({ ...props }) => {
  const resetToken = useResetRecoilState(healthcareTokenAtom);
  const [userGroup, setUserGroup] = useRecoilState(healthcareUserGroupAtom);
  const [patient, setPatient] = useRecoilState(healthcarePatientAtom);
  const [token, setToken] = useRecoilState(healthcareTokenAtom);
  const [impersonator, setImpersonator] = useRecoilState(healthcareImpersonatorAtom);
  const client = useClient();
  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery(
    ["user", token],
    async () => {
      if (!token) return null;

      let user = null;

      const onAuthFailed = () => {
        resetToken();
        return null;
      };

      try {
        user = await client.get("users/me").get("data");
      } catch (ex) {
        const { code } = ex;

        if (code === "authentication_failed") {
          onAuthFailed();
        } else {
          throw ex;
        }
      }

      return user;
    },
    {
      refetchOnReconnect: true,
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 15,
    }
  );

  const user = query.data;

  const value = useMemo(
    () => {
      function impersonate(impersonateToken, patient) {
        setImpersonator({
          token,
          userGroup,
          patient,
          returnTo: location,
        });
        setToken(impersonateToken);
        setUserGroup("patient");
        setPatient(patient);
      }

      async function revertImpersonate() {
        const { token, userGroup, patient, returnTo } = impersonator;
        setToken(token);
        setUserGroup(userGroup);
        setPatient(patient);
        setImpersonator(null);
        navigate(returnTo || "/");
      }

      return {
        user,
        query,
        userGroup,
        setUserGroup,
        patient,
        setPatient,
        impersonator,
        impersonate,
        revertImpersonate,
      }
    },
    [user, query, userGroup, setUserGroup, patient, setPatient, impersonator, setImpersonator, token, location, setToken, navigate]
  );

  if (query.isFetching && !query.data) return null;

  return <UserContext.Provider value={value} {...props} />;
};

export const useUserContext = () => useContext(UserContext);
