import React, { useState, useCallback, useRef } from "react";
import {
  Modal as BaseModal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from "reactstrap";
import { useEventCallback } from "hooks";
import { Trans } from "react-i18next";

export const useModal = ({ initialIsOpen = false } = {}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const ref = useRef();
  const toggle = () => setIsOpen((v) => !v);
  ref.current = { isOpen, setIsOpen, toggle };

  const Modal = useCallback((props) => {
    const { isOpen, toggle } = ref.current;

    return <BaseModal isOpen={isOpen} toggle={toggle} {...props} />;
  }, []);

  const CloseButton = useEventCallback((props) => {
    return (
      <Button color="link" onClick={() => setIsOpen(false)} {...props}>
        <Trans>Close</Trans>
      </Button>
    );
  });

  return {
    isOpen,
    setIsOpen,
    Modal,
    toggle,
    ModalHeader,
    ModalFooter,
    ModalBody,
    CloseButton,
  };
};

export default useModal;
