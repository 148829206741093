import React from "react";
import { Header } from "./Header";
import { SideNav } from "./SideNav";
import { useUserContext } from "../hooks";

export const MainContainer = ({ children }) => {
  const { user } = useUserContext();

  return (
    <div className="min-h-screen bg-white flex flex-col">
      <Header />
      <div className="flex flex-1">
        {user ? <SideNav /> : null}
        {children}
      </div>
    </div>
  );
};
