import { useField } from "formik";
import React, { useState } from "react";
import { FormCheck } from "components/common";
import { uniqueId } from "lodash";

export const IntegerRadioField = ({ name, validate, value, ...props }) => {
  const [field, , { setValue }] = useField({
    name,
    validate,
    type: "radio",
    value,
  });

  return (
    <input
      type="radio"
      {...field}
      onChange={(e) => {
        setValue(parseInt(e.target.value, 10));
      }}
      {...props}
    />
  );
};

export const YesNoField = ({
  name,
  yesLabel = "Yes",
  noLabel = "No",
  inline = false,
}) => {
  const [yesId] = useState(() => uniqueId("yes_no_field"));
  const [noId] = useState(() => uniqueId("yes_no_field"));

  return (
    <div className={inline ? "flex space-x-2" : ""}>
      <FormCheck
        label={yesLabel}
        translateLabel
        input={
          <IntegerRadioField
            name={name}
            value={1}
            id={yesId}
            className="form-check-input"
          />
        }
      />
      <FormCheck
        label={noLabel}
        translateLabel
        input={
          <IntegerRadioField
            name={name}
            id={noId}
            value={0}
            className="form-check-input"
          />
        }
      />
    </div>
  );
};

export default YesNoField;
