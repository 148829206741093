import React, { useState, useMemo } from "react";
import { Page, SubmitButton, FormCheck } from "components/common";
import { useFormik, FormikProvider } from "formik";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import ProjectFields from "./components/ProjectFields";
import DiaryOptionsField from "./components/DiaryOptionsField";
import ContentField from "./components/ContentField";
import { Trans } from "react-i18next";
import { get } from "lodash";

const SubHeading = ({ children }) => {
  return (
    <h3 className="text-lg mb-4 text-gray-900 border-b border-gray-200">
      {children}
    </h3>
  );
};

const initialValues = {};

export const TeacherProjectDefaults = () => {
  const formik = useFormik({
    onSubmit,
    initialValues,
    validate: (values) => {
      const errors = {};

      for (const field of [
        "project_content.welcome_message",
        "project_content.getting_started",
        "project_content.about",
        "project_content.contact_us",
      ]) {
        if (!get(values, field)) {
          errors[field] = true;
        }
      }

      return errors;
    },
  });
  const [foodDatabases, setFoodDatabases] = useState();
  const { resetForm, values, setFieldValue } = formik;
  const { food_databases } = values;

  const selectedDatabases = useMemo(
    () =>
      food_databases?.reduce((acc, i) => {
        acc[i] = true;
        return acc;
      }, {}),
    [food_databases]
  );
  const client = useClient();
  const url = "teacher_project_defaults";
  const title = "Edit Project Defaults";

  async function onSubmit(x) {
    const values = await client.post(url, x).get("data");
    NotificationManager.success("Project defaults saved successfully");
    resetForm({ values });
  }

  useAsyncEffect(async () => {
    const [values, foodDatabases] = await Promise.all([
      client.get(url).get("data"),
      client.get("food_databases").get("data"),
    ]);
    setFoodDatabases(foodDatabases);
    resetForm({ values });
  }, []);

  if (values === initialValues) return null;

  return (
    <Page title={title}>
      <FormikProvider value={formik}>
        <div className="mb-8">
          <SubHeading>
            <Trans>Project</Trans>
          </SubHeading>
          <ProjectFields />
        </div>
        <div className="mb-8">
          <SubHeading>
            <Trans>Diary options</Trans>
          </SubHeading>
          <DiaryOptionsField />
        </div>
        <div className="mb-8">
          <SubHeading>
            <Trans>Content</Trans>
          </SubHeading>
          <ContentField />
        </div>
        <div className="mb-8">
          <SubHeading>
            <Trans>Databases</Trans>
          </SubHeading>
          <ul>
            {foodDatabases.map((i) => (
              <li key={i.id}>
                <FormCheck
                  input={
                    <input
                      id={`food_database_${i.id}`}
                      type="checkbox"
                      className="form-check-input"
                      checked={!!selectedDatabases[i.id]}
                      onChange={(e) => {
                        if (selectedDatabases[i.id]) {
                          setFieldValue(
                            "food_databases",
                            food_databases.filter((j) => j !== i.id)
                          );
                        } else {
                          setFieldValue("food_databases", [
                            ...food_databases,
                            i.id,
                          ]);
                        }
                      }}
                    />
                  }
                  label={i.name}
                />
              </li>
            ))}
          </ul>
        </div>

        <div className="flex justify-end">
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};

export default TeacherProjectDefaults;
