import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";

export const Header = () => {
  return (
    <div className="bg-hc-secondary px-5 py-6 bg-image-2 bg-no-repeat bg-right-bottom bg-auto">
      <Link to="/healthcare">
        <img src={logo} alt="Logo" />
      </Link>
    </div>
  );
};
