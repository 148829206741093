import { useQuery } from "react-query";
import useClient from "../../../hooks/useClient";
import { Trans } from "react-i18next";
import { formatDateTime } from "../../../services/formatters";
import {
  faCheckCircle,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "reactstrap";

export const OfflineTab = ({ projectId }) => {
  const url = `projects/${projectId}/offline_database`;
  const client = useClient();

  const query = useQuery(url, async () => client.get(url).get("data"), {
    refetchInterval: 30 * 1000,
    useErrorBoundary: false,
  });
  const { isLoading, data, error } = query;

  if (isLoading) return null;

  if (error?.response?.data?.code === "no_offline_database") {
    return (
      <Alert color="warning">
        <Trans>This project does not have an offline bundle</Trans>
      </Alert>
    );
  } else if (error) {
    throw error;
  }

  return (
    <div>
      <div>
        {data.status === "loading" || data.status === "pending" ? (
          <>
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              className="text-gray-500  mr-2"
            />
            <Trans>
              The offline bundle for this project is being created and will be
              available soon
            </Trans>
          </>
        ) : null}
        {data.status === "ready" ? (
          <>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-green-500 mr-2"
            />
            <Trans>
              The offline bundle for this project has been created and ready to
              use
            </Trans>
          </>
        ) : null}
        {data.status === "error" ? (
          <>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="text-red-500 mr-2"
            />
            <Trans>
              The offline bundle for this project could not be created, please
              contact support
            </Trans>
          </>
        ) : null}
      </div>
      {data.last_updated ? (
        <div className="text-sm text-gray-700 mt-1">
          <Trans>
            Last updated: {{ date: formatDateTime(data.last_updated) }}
          </Trans>
        </div>
      ) : null}
    </div>
  );
};
