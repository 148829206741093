import React, { useLayoutEffect, useState } from "react";

export const FastInput = ({
  tag: Tag = "input",
  value: valueProp,
  onBlur,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState(valueProp);

  useLayoutEffect(() => {
    setValue(valueProp);
  }, [valueProp, setValue]);

  return (
    <Tag
      onChange={(e) => {
        setValue(e.target.value);
      }}
      value={value}
      onBlur={(e) => {
        onChange(e);
        setTimeout(() => onBlur(e), 0)
      }}
      {...props}
    />
  );
};
