import React, { useEffect, useMemo, useState } from "react";
import {
  Route as BaseRoute,
  Routes,
  Outlet,
  useNavigate,
  useParams,
  useMatch,
} from "react-router";
import {
  Login,
  Participant,
  Users,
  Projects,
  Researchers,
  Teachers,
  Organisations,
  Participants,
  AcceptInvite,
  DatabaseImport,
  Healthcare,
  HealthcareDashboard,
} from "./Pages";
import { Page } from "components/common";
import { useUserContext } from "hooks";
import { useTranslation } from "react-i18next";
import AuthenticatedBase from "./AuthenticatedBase";
import { includes } from "lodash";
import SelectUserType from "./SelectUserType";
import { Support } from "./Pages/Support";
import {TwoFactorAuthentication} from "./coherent/totp/routes";
import {SearchMetricsView} from "./features/search_metrics";
import {AppList, AppView} from "./features/apps";

const Forbidden = () => {
  return (
    <AuthenticatedBase>
      <Page title="Unauthorised">
        You do not have permission to view this page
      </Page>
    </AuthenticatedBase>
  );
};

const NotFound = () => {
  return (
    <AuthenticatedBase>
      <Page title="Not found">
        The page you have requested could not be found
      </Page>
    </AuthenticatedBase>
  );
};

const Route = ({
  path,
  exact,
  test,
  checkUser,
  checkUserGroup,
  userGroups,
  checkParticipant,
  element,
  children,
}) => {
  const { user, userGroup, participant } = useUserContext();
  const { locale } = useParams();
  const navigate = useNavigate();

  const validGroup = useMemo(() => {
    if (!checkUserGroup) return true;
    if (!userGroups) return true;
    return includes(userGroups, userGroup);
  }, [userGroups, userGroup, checkUserGroup]);

  useEffect(() => {
    const l = locale || "en";

    if (checkUser && !user) {
      navigate(`/${l}/login`, { replace: true });
    } else if (checkUserGroup && !userGroup) {
      navigate(`/${l}/participant/select-user-type`);
    } else if (checkParticipant && !participant) {
      navigate(`/${l}/participant/select-user-type`);
    }
  });

  if (checkUser && !user) {
    return null;
  } else if (checkUserGroup && !userGroup) {
    return null;
  } else if (checkParticipant && !participant) {
    return null;
  } else if (!validGroup) {
    return <Forbidden />;
  } else if (test !== undefined && !test) {
    return <Forbidden />;
  }

  return (
    <BaseRoute
      path={path}
      exact={exact}
      element={element}
      children={children}
    />
  );
};

const AuthenticatedRoute = ({ element, ...props }) => {
  return (
    <Route
      checkUser
      element={<AuthenticatedBase>{element}</AuthenticatedBase>}
      {...props}
    />
  );
};

const UnauthenticatedRoute = ({ element, ...props }) => {
  return (
    <Route
      element={<AuthenticatedBase>{element}</AuthenticatedBase>}
      {...props}
    />
  );
};

const BackendRoute = (props) => {
  const [userGroups] = useState(() => ["teacher", "researcher", "admin"]);

  return (
    <AuthenticatedRoute userGroups={userGroups} checkUserGroup {...props} />
  );
};

const ParticipantRoute = (props) => {
  const [userGroups] = useState(() => ["participant"]);

  return (
    <div className="h-full min-h-screen bg-white">
      <AuthenticatedRoute
        userGroups={userGroups}
        checkUserGroup
        checkParticipant
        {...props}
      />
    </div>
  );
};

const TranslationContainer = () => {
  const { locale } = useParams();
  const { i18n } = useTranslation();
  const { setLocale } = useUserContext();

  const valid = useMemo(
    () => includes(["en", "no", "da", "de", "fr", "ar", "es", "en_us", "it"], locale),
    [locale]
  );

  useEffect(() => {
    if (valid) {
      i18n.changeLanguage(locale);
      setLocale(locale);
    }
  }, [locale, valid, i18n, setLocale]);

  if (!valid) {
    return <NotFound />;
  }

  return <Outlet />;
};

export const AppRoutes = () => {
  const {
    userGroup,
    isParticipant,
    isResearcher,
    isTeacher,
    isAdmin,
    isBackend,
    isHealthcareUser,
    user,
    locale,
  } = useUserContext();
  useTranslation();

  const isRoot = useMatch("/");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isRoot) return;

    let path = "";

    if (isAdmin) {
      path = `/${locale}/users`;
    } else if (isResearcher || isTeacher) {
      path = `/${locale}/projects`;
    } else if (isParticipant) {
      path = `/${locale}/participant`;
    } else if (isHealthcareUser) {
      window.location.pathname = "/healthcare";
      return;
    } else if (user && !userGroup) {
      path = `/${locale}/participant/select-user-type`;
    } else {
      path = `/${locale}/login`;
    }

    if (path) {
      navigate(path, { replace: true });
    }
  });

  return (
    <Routes>
      <BackendRoute test={isAdmin} path="two_factor_authentication" element={<TwoFactorAuthentication />} />
      <Route path="/:locale" element={<TranslationContainer />}>
        <Route path="/" element={<NotFound />} />
        <Route path="login" element={<Login.Login />} />
        <Route
          path="participant/teaching/start"
          element={<Login.Teaching.Start />}
        />
        <Route
          path="participant/teaching/login"
          element={<Login.Teaching.Login />}
        />
        <Route
          path="participant/teaching/activate-user/:user/:signature"
          element={<Login.Teaching.Activate />}
        />
        <Route
          path="participant/teaching/register"
          element={<Login.Teaching.Register />}
        />
        <Route path="reset_password/:token" element={<Login.ResetPassword />} />
        <Route
          path="reset_password_request"
          element={<Login.ResetPasswordRequest />}
        />

        <AuthenticatedRoute
          path="participant/select-user-type"
          element={<SelectUserType />}
        />

        <UnauthenticatedRoute
          path="users/invite/:token"
          element={<AcceptInvite />}
        />
        <UnauthenticatedRoute
          path="users/invite/:token/:respondent_id"
          element={<AcceptInvite />}
        />
        <BackendRoute path="users" element={<Users.List />} test={isAdmin} />
        <BackendRoute
          path="users/:id"
          element={<Users.Edit />}
          test={isAdmin}
        />
        <BackendRoute
          path="teachers"
          element={<Teachers.List />}
          test={isAdmin}
        />

        <BackendRoute
          path="researchers"
          element={<Researchers.List />}
          test={isAdmin}
        />

        <BackendRoute
          path="organisations"
          element={<Organisations.List />}
          test={isAdmin}
        />
        <BackendRoute
          path="organisations/:id"
          element={<Organisations.Edit />}
          test={isAdmin}
        />

        <BackendRoute
          path="participants/accepted"
          element={<Participants.Accepted.List />}
          test={isBackend}
        />
        <BackendRoute
          path="participants/invited"
          element={<Participants.Invited.List />}
          test={isBackend}
        />

        <BackendRoute
          path="participants/invited/:id"
          element={<Participants.Invited.Edit />}
          test={isBackend}
        />

        <BackendRoute
          path="participants/accepted/:id"
          element={<Participants.Accepted.Edit />}
          test={isBackend}
        />

        <BackendRoute
          path="teachers/invited"
          element={<Teachers.Invited.List />}
          test={isAdmin}
        />

        <BackendRoute
          path="teachers/invited/:id"
          element={<Teachers.Invited.Edit />}
          test={isAdmin}
        />

        <BackendRoute
          path="teacher_project_defaults"
          element={<Projects.TeacherProjectDefaults />}
          test={isTeacher}
        />

        <BackendRoute
          path="researchers/invited"
          element={<Researchers.Invited.List />}
          test={isAdmin}
        />

        <BackendRoute
          path="researchers/invited/:id"
          element={<Researchers.Invited.Edit />}
          test={isAdmin}
        />

        <BackendRoute
          path="projects"
          element={<Projects.List />}
          test={isBackend}
        />
        <BackendRoute
          path="projects/:id"
          element={<Projects.Edit />}
          test={isBackend}
        />

        <BackendRoute
          path="projects/:id/reports"
          element={<Projects.Reports />}
          test={isAdmin || isTeacher}
        />

        <BackendRoute
          path="database_import"
          element={<DatabaseImport />}
          test={isAdmin}
        />
        <BackendRoute
          path="search_metrics"
          element={<SearchMetricsView />}
          test={isAdmin}
        />
        <BackendRoute
          path="support"
          element={<Support />}
          test={isResearcher || isTeacher}
        />
        <BackendRoute
          path="apps/:id"
          element={<AppView />}
          test={isAdmin}
        />
        <BackendRoute
          path="apps"
          element={<AppList />}
          test={isAdmin}
        />

        <ParticipantRoute
          path="participant"
          element={<Participant.Container />}
          test={isParticipant}
        >
          <Route path="/" element={<Participant.Welcome />} />
          <Route path="/help" element={<Participant.Help />} />
          <Route
            path="diary/submit-success"
            element={<Participant.DiarySuccess />}
          />
          <Route path="diary/*" element={<Participant.Diary />} />
        </ParticipantRoute>
        <Route path="hc">
          <BackendRoute
            test={isAdmin}
            path="hcp_users"
            element={<Healthcare.HCPUserList />}
          />
          <BackendRoute
            test={isAdmin}
            path="hcp_users/:id"
            element={<Healthcare.HCPUserEdit />}
          />
          <BackendRoute
            test={isAdmin}
            path="settings"
            element={<Healthcare.HealthcareSettings />}
          />
          <BackendRoute
            test={isAdmin}
            path="healthcare_organisations"
            element={<Healthcare.HealthcareOrganisationList />}
          />
          <BackendRoute
            test={isAdmin}
            path="healthcare_organisations/:id"
            element={<Healthcare.HealthcareOrganisationEdit />}
          />
          <BackendRoute
            path="dashboard"
            element={<HealthcareDashboard />}
            test={isAdmin}
          />
        </Route>
      </Route>
      {!isRoot ? <Route path="*" element={<NotFound />} /> : null}
    </Routes>
  );
};

export default AppRoutes;
