import {useModal} from "../../hooks";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Trans} from "react-i18next";

export const useConfirmMultiModal = ({ onConfirm }) => {
  const { Modal, isOpen, setIsOpen, CloseButton, toggle } = useModal();

  const confirmMultiModal = isOpen ? (
    <Modal>
      <ModalHeader toggle={toggle}>
        <Trans>
          Allow multiple diary submission?
        </Trans>
      </ModalHeader>
      <ModalBody>
        <Trans>
          Are you sure you want allow multiple diary submissions for this project? <strong>Note: This cannot be undone.</strong>
        </Trans>
      </ModalBody>
      <ModalFooter>
        <CloseButton />
        <Button onClick={onConfirm}>
          <Trans>
            Confirm
          </Trans>
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

  return {
    confirmMultiModal,
    setIsOpen,
  }
}