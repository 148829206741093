import { useMemo } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { set, get } from "lodash";
import { tokenAtom as defaultTokenAtom } from "atoms";
import { useResetRecoilState, useRecoilValue } from "recoil";

export const createClient = ({
  baseURL = "/api",
  token,
  headers,
  ...props
} = {}) => {
  const result = axios.create({
    baseURL,
    headers: {
      ...(headers ?? {}),
      Pragma: "no-cache",
      "Cache-Control": "no-cache, no-store",
    },
    ...props,
  });

  result.interceptors.request.use((config) => {
    if (token) {
      set(config, "headers.Authorization", `JWT ${token}`);
    }
    return config;
  });

  result.interceptors.response.use(null, (err) => {
    const response = err?.response;
    const data = response?.data;
    err.status = response?.status;
    err.detail = data?.detail;
    err.code = data?.code;
    err.validation_errors = data?.validation_errors;
    err.forError = (key, cb) => {
      const value = get(err.validation_errors, key);
      if (!value || !value.length) return;

      value.forEach(cb);
      return true;
    };
    err.forNonFieldErrors = (cb) => err.forError("non_field_errors", cb);
    return Promise.reject(err);
  });

  return result;
};

export const useClient = ({
  baseURL = "/api",
  sendAuth = true,
  redirectOnUnauthorized = true,
  tokenAtom = defaultTokenAtom,
} = {}) => {
  const navigate = useNavigate();
  const resetToken = useResetRecoilState(tokenAtom);
  const token = useRecoilValue(tokenAtom);

  return useMemo(() => {
    const result = createClient({
      baseURL,
      token: sendAuth ? token : undefined,
    });

    result.interceptors.response.use(null, (err) => {
      const status = err?.response?.status;
      if (status === 401 && redirectOnUnauthorized) {
        err.handled = true;
        resetToken();
        navigate("/login");
      }
      throw err;
    });
    return result;
  }, [baseURL, sendAuth, redirectOnUnauthorized, token, resetToken, navigate]);
};

export default useClient;
