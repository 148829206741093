import React from "react";
import classNames from "classnames";

export const SubTitle = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames(
        "text-center text-sm mb-4 text-brand-dark font-semibold",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
