import React from "react";
import { useTable, useClient, useUserContext } from "hooks";
import { Field, FormikProvider, useFormik } from "formik";
import {
  Page,
  DebouncedInput,
  FiltersForm,
  SubmitButton,
} from "components/common";
import { Table } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";

const AcceptAndTakeControlButton = ({ onSubmit, ...props }) => {
  const form = useFormik({
    onSubmit,
    initialValues: {},
  });

  return (
    <FormikProvider value={form}>
      <SubmitButton {...props}>
        <Trans>Accept invite + Take control</Trans>
      </SubmitButton>
    </FormikProvider>
  );
};

export const List = () => {
  const url = "participants/invited";
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    setFilters,
  } = useTable({
    url,
    resource: "",
  });
  const client = useClient();
  const { impersonate } = useUserContext();
  const navigate = useNavigate();

  return (
    <Page title="Invited participants" fluid translateTitle>
      <TableSearch />
      <div className="flex justify-end mb-3">
        <AddNewButton />
      </div>
      <TableContainer>
        <Table size="sm" hover striped>
          <thead>
            <tr>
              <th sortBy="invitation__first_name">
                <Trans>Forename</Trans>
              </th>
              <th sortBy="invitation__last_name">
                <Trans>Surname</Trans>
              </th>
              <th sortBy="invitation__email">
                <Trans>Email address</Trans>
              </th>
              <th sortBy="respondent_id">
                <Trans>Respondent ID</Trans>
              </th>
              <th sortBy="project__title">
                <Trans>Project</Trans>
              </th>
              <th />
            </tr>
            <FiltersForm
              persistKey="invitedParticipants"
              onChange={setFilters}
              initialValues={{
                invitation__first_name: "",
                invitation__last_name: "",
                invitation__email: "",
                project__title: "",
                respondent_id: "",
              }}
            >
              <tr>
                <td>
                  <Field
                    name="invitation__first_name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="invitation__last_name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="invitation__email"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="respondent_id"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="project__title"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td />
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td>{i.invitation__first_name}</td>
                <td>{i.invitation__last_name}</td>
                <td>{i.invitation__email}</td>
                <td>{i.respondent_id}</td>
                <td>
                  <Link to={`../../projects/${i.project}`}>
                    {i.project__title}
                  </Link>
                </td>
                <td>
                  <AcceptAndTakeControlButton
                    size="sm"
                    className="whitespace-no-wrap"
                    onSubmit={async () => {
                      const { token, participant } = await client
                        .post(`${url}/${i.id}/accept_invite_and_take_control`)
                        .get("data");
                      impersonate(token, participant);
                      navigate("../../participant");
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};

export default List;
