import { Card } from "reactstrap";
import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export const Forbidden = () => {
  return (
      <Card body>
        <div className="text-center">
          <Trans>
            You do not have permission to access this page.{" "}
            <Link to={-1}>Go back</Link>.
          </Trans>
        </div>
      </Card>
  );
};
