import { useUserContext } from "hooks";
import { SubmitButton } from "./common";
import React from "react";
import { useTranslation } from "react-i18next";
import { useFormik, FormikProvider } from "formik";

const ImpersonateNotification = () => {
  const { user, impersonator, revertImpersonate } = useUserContext();
  const form = useFormik({
    initialValues: {},
    onSubmit,
  });

  async function onSubmit() {
    await revertImpersonate();
  }

  const { t } = useTranslation();

  if (!impersonator || !user) return null;

  return (
    <div className="fixed top-0 left-0 ml-1 mt-4 p-2 bg-gray-100 border border-gray-500 rounded-md">
      <p className="text-sm">
        {t("You are impersonating")} {user.email}
      </p>
      <div className="flex justify-center mt-2">
        <FormikProvider value={form}>
          <SubmitButton size="sm" color="secondary">
            {t("Stop Impersonating")}
          </SubmitButton>
        </FormikProvider>
      </div>
    </div>
  );
};

export default ImpersonateNotification;
