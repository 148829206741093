import DatePicker from "react-datepicker";
import { useController } from "react-hook-form";
import cx from 'classnames';

export const DateRangePicker = ({
  tag: Tag = "div",
  rules,
  name,
  control,
  defaultValue,
  onChangeProp = null,
  className,
}) => {
  const { field } = useController({
    rules,
    name,
    control,
    defaultValue,
  });
  const { value, onChange, onBlur } = field;
  const [startDate, endDate] = value;

  return (
    <Tag className="flex">
      <DatePicker
        autocomplete="off"
        dateFormat="dd/MM/yyyy"
        className={cx("form-control w-28", className)}
        onBlur={onBlur}
        selected={startDate}
        onChange={(v) => {
          if (onChangeProp) onChangeProp();
          onChange([v, endDate]);
        }}
      />
      <span className="mx-1 self-center">-</span>
      <DatePicker
        autocomplete="off"
        dateFormat="dd/MM/yyyy"
        className={cx("form-control w-28", className)}
        selected={endDate}
        onBlur={onBlur}
        onChange={(v) => {
          if (onChangeProp) onChangeProp();
          onChange([startDate, v]);
        }}
      />
    </Tag>
  );
};
