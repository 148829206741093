import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const FormCheck = ({
  input,
  label,
  translateLabel = false,
  className,
  ...props
}) => {
  const id = input?.props?.id;
  const { t } = useTranslation();

  return (
    <div className={classNames("form-check", className)} {...props}>
      {input}
      {label ? (
        <label className="form-check-label" htmlFor={id}>
          {translateLabel ? t(label) : label}
        </label>
      ) : null}
    </div>
  );
};

export default FormCheck;
