/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "./scss/index.scss";
import App from "./App";
import Promise from "bluebird";
import "./registerUnhandledRejections";
import "chartjs-plugin-labels";
import "chartjs-plugin-annotation";
import reportWebVitals from "./reportWebVitals";

import "./config/i18n";

global.Promise = Promise;

Promise.prototype.cancel = undefined;

Promise.config({
  warnings: false,
  cancellation: true,
});

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();
