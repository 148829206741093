import React from "react";
import { Trans } from "react-i18next";
import { Table } from "reactstrap";
import { Field } from "formik";
import { Link } from "react-router-dom";
import { useTable } from "hooks";
import { Page, FiltersForm, DebouncedInput } from "components/common";
import BooleanIcon from "../../components/common/BooleanIcon";

export const HealthcareOrganisationList = () => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    setFilters,
    BulkDeleteButton,
  } = useTable({
    url: "healthcare_organisations",
    resource: "Healthcare Organisation",
  });

  return (
    <Page title={<Trans>Healthcare Organisations</Trans>} fluid>
      <TableSearch />
      <div className="flex justify-between mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th select />
              <th sortBy="name">
                <Trans>Name</Trans>
              </th>
              <th sortBy="doe_enabled">
                <Trans>DOE enabled?</Trans>
              </th>
              <th sortBy="exclude_from_dashboard">
                <Trans>Exclude from dashboard?</Trans>
              </th>
              <th sortBy="exclude_from_self_registration">
                <Trans>Exclude from patient self-registration?</Trans>
              </th>
            </tr>
            <FiltersForm
              persistKey="healthcare_organisations"
              onChange={(x) => {
                setFilters({
                  ...x,
                });
              }}
              initialValues={{
                name: "",
              }}
            >
              <tr>
                <td />
                <td>
                  <Field
                    name="name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td />
                <td />
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${i.id}`}>{i.name}</Link>
                </td>
                <td>
                  <BooleanIcon test={i.doe_enabled} />
                </td>
                <td>
                  <BooleanIcon test={i.exclude_from_dashboard} />
                </td>
                <td>
                  <BooleanIcon test={i.exclude_from_self_registration} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};
