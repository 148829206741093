import { isEmail, isURL } from "validator";
import { isDecimal, isFloat } from "validator";

const getValue = (v) => {
  if (v === null || v === undefined) v = "";
  return v.toString();
};

export const validateEmail = ({
  required = true,
  requiredError = "required",
  invalidError = "invalid",
} = {}) => (v) => {
  v = getValue(v);
  if (!required && !v) return null;
  if (required && !v) return requiredError;
  return isEmail(v) ? null : invalidError;
};

export const validateUrl = ({
  required = true,
  stripeWhitespace = false,
  ...options
} = {}) => (v) => {
  v = getValue(v);
  if (!required && !v) return null;
  if (stripeWhitespace) {
    v = v.replace(/\s+/g, "");
  }
  return isURL(v, options) ? null : "invalid";
};

export const validateDecimal = ({
  required = true,
  decimal_digits = "0,2",
  min = 0,
} = {}) => (v) => {
  v = getValue(v);
  if (!required && !v) return null;
  const valid = isDecimal(v, { decimal_digits }) && isFloat(v, { min });
  return valid ? undefined : "invalid";
};

export const validateInt = ({ required = true, min = 0 } = {}) => (v) => {
  return validateDecimal({ required, decimal_digits: "0", min })(v);
};

export const validate = (cb, { required = true, error = "invalid" } = {}) => (
  v
) => {
  v = getValue(v);
  if (!required && !v) return null;
  return cb(v) ? undefined : error;
};
