import React, { useRef } from "react";
import { Button, PopoverBody, Row, Col } from "reactstrap";
import { usePopover, useOnClickOutside } from "hooks";
import { useNavigate, useLocation, useParams } from "react-router";
import germany from "./assets/germany_flag_64-trimmy.png";
import uk from "./assets/united_kingdom_flag_64-trimmy.png";
import denmark from "./assets/denmark_flag_64-trimmy.png";
import norway from "./assets/norway_flag_64-trimmy.png";
import france from "./assets/france_flag_64-trimmy.png";
import spanish from "./assets/spanish.png";
import arabic from "./assets/arabic.png";
import italy from "./assets/italy.png";
import us from './assets/america.png';
import classNames from "classnames/dedupe";

const LanguageButton = ({ image, language, setIsOpen }) => {
  const { pathname, search, hash } = useLocation();
  const { locale } = useParams();
  const navigate = useNavigate();

  return (
    <Button
      color="link"
      className="p-0"
      onClick={() => {
        const newPathname = pathname.replace(`/${locale}`, `/${language}`);
        navigate(newPathname + search + hash);
        setIsOpen(false);
      }}
    >
      <img src={image} style={{ height: 25 }} alt="" className="rounded" />
    </Button>
  );
};

export const LanguageSelector = ({ className, ...props }) => {
  const { Popover, isOpen, setIsOpen, id } = usePopover();
  const { locale } = useParams();
  const ref = useRef();

  useOnClickOutside(ref, () => setIsOpen(false));

  const currentImage = {
    en: uk,
    de: germany,
    da: denmark,
    no: norway,
    fr: france,
    ar: arabic,
    es: spanish,
    en_us: us,
    it: italy
  }[locale];

  return (
    <>
      <Button
        color="link"
        className={classNames("p-0", className)}
        id={id}
        onClick={() => setIsOpen(true)}
        {...props}
      >
        <img src={currentImage} style={{ height: 18 }} alt="" className="rounded-lg" />
      </Button>
      {isOpen ? (
        <Popover
          innerRef={ref}
          placement="bottom-end"
          popperClassName="popper--xl"
        >
          <PopoverBody>
            <Row form className="flex-nowrap">
              <Col xs="auto">
                <LanguageButton
                  image={uk}
                  language="en"
                  setIsOpen={setIsOpen}
                />
              </Col>
              <Col xs="auto">
                <LanguageButton
                  image={germany}
                  language="de"
                  setIsOpen={setIsOpen}
                />
              </Col>
              <Col xs="auto">
                <LanguageButton
                  image={denmark}
                  language="da"
                  setIsOpen={setIsOpen}
                />
              </Col>
              <Col xs="auto">
                <LanguageButton
                  image={norway}
                  language="no"
                  setIsOpen={setIsOpen}
                />
              </Col>
              <Col xs="auto">
                <LanguageButton
                  image={france}
                  language="fr"
                  setIsOpen={setIsOpen}
                />
              </Col>
              <Col xs="auto">
                <LanguageButton
                  image={italy}
                  language="it"
                  setIsOpen={setIsOpen}
                />
              </Col>
              <Col xs="auto">
                <LanguageButton
                  image={spanish}
                  language="es"
                  setIsOpen={setIsOpen}
                />
              </Col>
              <Col xs="auto">
                <LanguageButton
                  image={arabic}
                  language="ar"
                  setIsOpen={setIsOpen}
                />
              </Col>
                <Col xs="auto">
                    <LanguageButton
                        image={us}
                        language="en_us"
                        setIsOpen={setIsOpen}
                    />
                </Col>
            </Row>
          </PopoverBody>
        </Popover>
      ) : null}
    </>
  );
};

export default LanguageSelector;
