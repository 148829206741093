import React from "react";
import { Field, useFormikContext, useField } from "formik";
import { FormGroup } from "components/common";
import { Alert } from "reactstrap";
import { Trans } from "react-i18next";

const PasswordMatchValidator = ({
  name = "password_mismatch",
  passwordKey = "password",
  confirmPasswordKey = "confirm_password",
}) => {
  const { values } = useFormikContext();

  useField({
    name,
    validate: () => {
      return values[passwordKey] !== values[confirmPasswordKey];
    },
  });

  return null;
};

export const PasswordFields = ({ required = true, showError }) => {
  const { errors, touched, submitCount } = useFormikContext();

  return (
    <>
      <PasswordMatchValidator required={required} />
      <FormGroup
        label="Password"
        showError={showError}
        required={required}
        name="password"
      >
        <Field
          type="password"
          name="password"
          validate={required ? (v) => !v : undefined}
          className="form-control"
        />
      </FormGroup>
      <FormGroup
        label="Repeat password"
        required={required}
        name="confirm_password"
      >
        <Field
          type="password"
          name="confirm_password"
          validate={required ? (v) => !v : undefined}
          className="form-control"
        />
      </FormGroup>
      {errors.password_mismatch &&
      ((touched.password && touched.confirm_password) || submitCount) ? (
        <Alert color="danger">
          <Trans>Passwords do not match</Trans>
        </Alert>
      ) : null}
    </>
  );
};

export default PasswordFields;
