import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames/dedupe";

export const BackLink = ({ to: toProp, ...props }) => {
  const { state } = useLocation();
  const { backLocation } = state ?? {};

  if (!toProp && !backLocation) {
    return null;
  }

  return (
    <Link
      to={backLocation?.pathname ?? toProp}
      {...props}
      className={classNames("btn btn-link btn-lg")}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </Link>
  );
};

export const BackLinkContainer = ({ children, ...props }) => {
  return (
    <div className="flex -ml-4 items-center">
      <BackLink {...props} />
      <div className="text-center flex-1">{children}</div>
    </div>
  );
};

export default BackLink;
