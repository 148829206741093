import React, { useState, useMemo } from "react";
import {
  Row,
  Col,
  ModalBody,
  PopoverBody,
  Alert,
  ButtonGroup,
} from "reactstrap";
import {
  useAsyncEffect,
  useParticipantClient,
  useSearchParams,
  useModalContext,
  usePopover,
} from "hooks";
import { useFormik, FormikProvider, Field, useFormikContext } from "formik";
import { FormGroup, Select, SubmitButton, Button } from "components/common";
import { Trans, useTranslation } from "react-i18next";
import { find, map, range, get, uniqueId, partition, reduce } from "lodash";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { validateDecimal } from "services/validators";
import classNames from "classnames";
import { NotificationManager } from "react-notifications";
import { useDiaryContext } from "./DiaryContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faEquals,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useParticipantContext } from "../../contexts/useParticipantContext";
import numeral from "numeral";
import { BackLinkContainer } from "./BackLink";
import AccompanyingItems from "./AccompanyingItems";
import { ModalHeader } from "./components/ModalHeader";
import Checkbox from "rc-checkbox";

const IMAGE = "IMAGE";
const SIZE = "SIZE";

const HOURS = range(0, 24).map((i) => ({
  label: i < 10 ? "0" + i : i,
  value: i < 10 ? "0" + i : i,
}));

const MINUTES = ["00", "15", "30", "45"].map((i) => ({
  label: i,
  value: i,
}));

const Portion = ({ value, className }) => {
  const { setFieldValue, values } = useFormikContext();
  const { food_portion, exact_size_convertible_unit } = values;
  const { Popover, id } = usePopover();
  const { t } = useTranslation();

  const selected = food_portion === value.id;

  return (
    <Col xs={6} lg={3} className={classNames("mb-2", className)}>
      <div
        role="button"
        className={classNames(
          "border rounded p-1 h-full",
          selected ? "bg-secondary text-white" : "bg-gray-100 border-gray-300"
        )}
        onClick={() => {
          if (food_portion === value.id) {
            setFieldValue(
              "actual_size_convertible_unit__to_base_conversion_rate",
              exact_size_convertible_unit.to_base_conversion_rate,
              false
            );
            setFieldValue("exact_size", "", false);
            setFieldValue("food_portion", null, false);
            setFieldValue("food_portion__size", null);
          } else {
            const { size, id, convertible_unit } = value;
            const exactSize = size;
            setFieldValue(
              "actual_size_convertible_unit__to_base_conversion_rate",
              convertible_unit.to_base_conversion_rate,
              false
            );
            setFieldValue(
              "exact_size",
              numeral(exactSize).format("0[.]00"),
              false
            );
            setFieldValue("food_portion", id, false);
            setFieldValue("food_portion__size", size);
            setFieldValue("actual_size", Number(size));
          }
        }}
      >
        {value.image_name ? (
          <>
            <Popover trigger="hover" className="hidden md:block" popperClassName="popover--lg">
              <PopoverBody>
                <div className="flex justify-center mb-1">
                  <img
                    alt=""
                    src={`/api/food_portion_images/${value.id}`}
                    className="max-w-full flex-shrink-0"
                  />
                </div>
              </PopoverBody>
            </Popover>
            <div className="flex justify-center mb-1">
              <img
                alt=""
                id={id}
                src={`/api/food_portion_images/${value.id}?width=175&height=175&type=thumbnail`}
                className="max-w-full"
              />
            </div>
          </>
        ) : null}
        <div
          className={classNames(
            "text-xl font-medium",
            !selected ? "text-brand-dark" : ""
          )}
        >
          {numeral(value.size).format("0[.][00]")}
          <Trans i18nKey={value.convertible_unit.unit} />
        </div>
        {value.description ? (
          <div className="text-sm">{t(`(${value.description})`)}</div>
        ) : null}
      </div>
    </Col>
  );
};

const ConverterField = ({ name, label, inverted, ...props }) => {
  const { setFieldValue } = useFormikContext();

  function convert(e) {
    const { name, value } = e.target;
    const { name: toName, multiplier } = {
      g: {
        name: "oz",
        multiplier: 0.035274,
      },
      oz: {
        name: "g",
        multiplier: 28.35,
      },
      flOz: {
        name: "ml",
        multiplier: 29.5735,
      },
      ml: {
        name: "flOz",
        multiplier: 0.033814,
      },
    }[name];

    setFieldValue(name, value, false);

    if (value === "") {
      setFieldValue(toName, "0.00", false);
    } else {
      let v = Number(value);
      v = !isNaN(v) ? Number(v * multiplier).toFixed(2) : "";
      setFieldValue(toName, v, false);
    }
  }

  return (
    <FormGroup
      name={name}
      label={label}
      className="mb-0"
      getLabelProps={(v) => ({
        ...v,
        className: !inverted ? `${v.className} text-right` : v.className,
      })}
    >
      <Field
        name={name}
        onChange={convert}
        className="form-control"
        readOnly={inverted}
        {...props}
      />
    </FormGroup>
  );
};

const Converter = ({ className, tag: Tag = "div", onClose, ...props }) => {
  const form = useFormik({
    initialValues: {
      g: "",
      oz: "",
      flOz: "",
      ml: "",
      ozSwitched: false,
      flOzSwitched: false,
    },
  });
  const { values, setFieldValue } = form;
  const { ozSwitched, flOzSwitched } = values;
  const { t } = useTranslation();

  const oz = (
    <ConverterField name="oz" label={t("oz (ounces)")} inverted={ozSwitched} />
  );
  const g = (
    <ConverterField name="g" label={t("g (grams)")} inverted={!ozSwitched} />
  );
  const ml = (
    <ConverterField
      name="ml"
      label={t("ml (milliliters)")}
      inverted={!flOzSwitched}
    />
  );
  const flOz = (
    <ConverterField
      name="flOz"
      label={t("fl oz (Fluid Ounces)")}
      inverted={flOzSwitched}
    />
  );

  return (
    <Tag className={classNames("converter", className)} {...props}>
      <FormikProvider value={form}>
        <div>
          <Row form className="mb-2">
            <Col>{ozSwitched ? g : oz}</Col>
            <Col xs="auto" className="text-gray-500">
              <div className="flex flex-col">
                <Button
                  size="sm"
                  color="link"
                  tabIndex={-1}
                  className="p-0 mb-2"
                  onClick={() => setFieldValue("ozSwitched", !ozSwitched)}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </Button>
                <FontAwesomeIcon icon={faEquals} />
              </div>
            </Col>
            <Col>{ozSwitched ? oz : g}</Col>
          </Row>
          <Row form>
            <Col>{flOzSwitched ? ml : flOz}</Col>
            <Col xs="auto" className="text-gray-500">
              <div className="flex flex-col">
                <Button
                  size="sm"
                  color="link"
                  tabIndex={-1}
                  className="p-0 mb-2"
                  onClick={() => setFieldValue("flOzSwitched", !flOzSwitched)}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                </Button>
                <FontAwesomeIcon icon={faEquals} />
              </div>
            </Col>
            <Col>{flOzSwitched ? flOz : ml}</Col>
          </Row>
          <div className="flex justify-end mt-4">
            <Button outline onClick={onClose} size="sm">
              <Trans>Close</Trans>
            </Button>
          </div>
        </div>
      </FormikProvider>
    </Tag>
  );
};

const exactSizeTransform = ({ exact_size, actual_size, food_portion, quantity }) => {
  const format = v => numeral(v).format("0.[00]");

  return {
    input: () => {
      return format(Number(exact_size) / Number(quantity))
    },
    output: () => {
      return format(Number(exact_size) * Number(quantity));
    }
  }
}

export const FoodEntry = ({ reload }) => {
  const [view, setView] = useState(IMAGE);
  const [isConverterOpen, setIsConverterOpen] = useState();
  const [meals, setMeals] = useState();
  const [accompanyingItems, setAccompanyingItems] = useState();
  const [foodItem, setFoodItem] = useState();
  const [portionSizeChecked, setPortionSizeChecked] = useState();
  const [consumptionMethods, setConsumptionMethods] = useState();
  const client = useParticipantClient();
  const { id } = useParams();
  const isNew = id === "new";
  const { food_item } = useSearchParams();
  const navigate = useNavigate();
  const { toggle, setIsOpen } = useModalContext();
  const { fromMakeAList } = useLocation().state ?? {};

  const formik = useFormik({
    onSubmit,
  });
  const { values, resetForm, setFieldValue } = formik;
  const { t } = useTranslation();
  const {
    reload: reloadDiary,
    currentRecipe,
    setCurrentRecipe,
    setUnsavedRecipe,
    meal,
    setLastTime,
    lastTime,
    listItems,
  } = useDiaryContext();
  const { project, date } = useParticipantContext();

  useAsyncEffect(async () => {
    let [values, meals, consumptionMethods] = await Promise.all([
      isNew ? null : client.get(`diary/food_entries/${id}`).get("data"),
      client.get(`diary/meal_choices`).get("data"),
      client.get("diary/consumption_method_choices").get("data"),
    ]);

    const foodItemId = values ? values.food_item : food_item;
    const foodItem = await client.get(`food_items/${foodItemId}`).get("data");
    setFoodItem(foodItem);
    setMeals(
      map(meals, (label, value) => ({
        value,
        label: t(label),
      }))
    );
    setConsumptionMethods(
      map(consumptionMethods, (label, value) => ({
        value,
        label: t(label),
      }))
    );

    const defaultPortion = find(foodItem.portions, { is_default: true });
    const exact_size_convertible_unit = get(
      foodItem.portions,
      "0.convertible_unit"
    );

    if (!values) {
      values = {
        key: uniqueId("entry"),
        food_portion: defaultPortion ? defaultPortion.id : null,
        food_portion__size: defaultPortion?.size,
        actual_size: defaultPortion ? defaultPortion.size : "",
        actual_size_convertible_unit__to_base_conversion_rate:
          exact_size_convertible_unit?.to_base_conversion_rate ?? 1,
        food_item,
        food_item__description: foodItem.description,
        food_item__food_brand__name: foodItem.food_brand__name,
        method_of_consumption: "",
        quantity: "1.00",
        meal,
        hour: lastTime?.hour ?? null,
        minute: lastTime?.minute ?? null,
        create_recipe: !!currentRecipe,
      };
    } else {
      values = {
        ...values,
        hour: values.time ? values.time.substr(0, 2) : "",
        minute: values.time ? values.time.substr(3, 2) : "",
      };
    }

    resetForm({
      values: {
        ...values,
        exact_size_convertible_unit,
        exact_size: exactSizeTransform(values).input(),
      },
    });
  }, []);

  async function onSubmit({ hour, minute, create_recipe, ...x }) {
    if (!portionSizeChecked && foodItem.portions.length) {
      const [max, min] = reduce(
        foodItem.portions,
        (acc, i) => {
          if (i.size > acc[0] || acc[0] === null) acc[0] = i.size;
          if (i.size < acc[1] || acc[1] === null) acc[1] = i.size;
          return acc;
        },
        [null, null]
      );

      const exactSize = Number(x.exact_size) * Number(x.quantity);

      if (exactSize > max * 5 || exactSize < min / 5) {
        setPortionSizeChecked(true);
        return;
      }
    }

    const values = {
      ...x,
      exact_size: exactSizeTransform(x).output(),
      date,
      exact_size_convertible_unit: x.exact_size_convertible_unit?.id,
      actual_size_convertible_unit__unit: x.exact_size_convertible_unit?.unit,
      time: project.log_food_time ? `${hour}:${minute}` : null,
    };

    if (create_recipe) {
      const _currentRecipe = currentRecipe || {
        name: "",
        total_servings: "1",
        total_size: "0.00",
        ingredients: [],
      };

      const newRecipe = {
        ..._currentRecipe,
        ingredients: [..._currentRecipe.ingredients, values],
      };
      setCurrentRecipe(newRecipe);
      if (!newRecipe.id) {
        setUnsavedRecipe(newRecipe);
        navigate("../../search/recipes/new");
      } else {
        navigate(`../../recipes/${newRecipe.id}`);
      }

      return;
    }

    isNew
      ? await client.post("diary/food_entries", values).get("data")
      : await client.put(`diary/food_entries/${values.id}`, values).get("data");
    NotificationManager.success(
      t("The diary entry has been successfully added")
    );
    await reloadDiary();

    if (isNew && project.log_food_time) {
      setLastTime({
        hour,
        minute,
      });
    }

    if (fromMakeAList && listItems.length) {
      navigate("../../search/all", {
        state: {
          fromMakeAList,
        },
      });
    } else {
      const accompanying = await client
        .get(`food_items/${x.food_item}/accompanying_food_items`)
        .get("data");

      if (!accompanying.length) {
        setIsOpen(false);
      } else {
        setAccompanyingItems(accompanying);
      }
    }
  }

  const portions = foodItem?.portions;

  const [sizePortions, imagePortions] = useMemo(() => {
    return partition(portions ?? [], (i) => !i.image_name);
  }, [portions]);

  const showPortionTabs = !!(sizePortions.length && imagePortions.length);

  if (accompanyingItems) {
    return (
      <AccompanyingItems
        title={foodItem.description}
        items={accompanyingItems}
        onAdd={(id) => {
          navigate(`.?food_item=${id}`);
          reload();
        }}
      />
    );
  }

  if (!values) return null;

  const { create_recipe } = values;

  return (
    <>
      <ModalHeader toggle={toggle}>
        <BackLinkContainer to="../../search/all">
          <Trans>Portion options</Trans>
        </BackLinkContainer>
      </ModalHeader>
      <ModalBody>
        <div>
          <p className="text-center text-xl font-semibold text-brand-dark mb-4">
            {foodItem?.description}
          </p>
          {showPortionTabs ? (
            <ButtonGroup className="w-full mb-2">
              <Button
                color={
                  view === IMAGE ? "brand-primary" : "outline-brand-primary"
                }
                onClick={() => setView(IMAGE)}
              >
                <Trans>Portion Image</Trans>
              </Button>
              <Button
                color={
                  view === SIZE ? "brand-primary" : "outline-brand-primary"
                }
                onClick={() => setView(SIZE)}
              >
                <Trans>Portion Size</Trans>
              </Button>
            </ButtonGroup>
          ) : null}

          <FormikProvider value={formik}>
            <div>
              <div className="mb-4 text-center opacity-75">
                <Trans>Please select your portion</Trans>
              </div>
              <Row form className="mb-4">
                <Col xs={6} lg={3} className="mb-2">
                  <div
                    className={classNames(
                      "border rounded p-1 h-full",
                      !values.food_portion && values.exact_size
                        ? "bg-secondary text-white"
                        : "bg-gray-100 border-gray-300"
                    )}
                  >
                    <FormGroup name="exact_size">
                      <Field
                        name="exact_size"
                        className="form-control"
                        onChange={(e) => {
                          const { name, value } = e.target;
                          setFieldValue(name, value, false);
                          setFieldValue("actual_size", Number(value), false);
                          setFieldValue("food_portion", null);
                        }}
                        validate={validateDecimal()}
                      />
                    </FormGroup>
                    <div className="text-sm">
                      <Trans>Enter Amount</Trans> (
                      <Trans
                        i18nKey={values.exact_size_convertible_unit.unit}
                      />
                      )
                    </div>
                  </div>
                </Col>
                {imagePortions.map((i) => (
                  <Portion
                    key={i.id}
                    value={i}
                    className={
                      !showPortionTabs || view === IMAGE ? "" : "hidden"
                    }
                  />
                ))}
                {sizePortions.map((i) => (
                  <Portion
                    key={i.id}
                    value={i}
                    className={
                      !showPortionTabs || view === SIZE ? "" : "hidden"
                    }
                  />
                ))}
              </Row>

              {portionSizeChecked ? (
                <Alert color="danger">
                  <Trans>Are you sure that portion size is correct?</Trans>
                </Alert>
              ) : null}

              {!project.hide_diary_converter ? (
                <div className="flex flex-col justify-center">
                  <div className="text-right">
                    <Button
                      color="link"
                      onClick={() => setIsConverterOpen((v) => !v)}
                    >
                      <Trans>Need a converter?</Trans>
                      <FontAwesomeIcon
                        icon={faCalculator}
                        className="ml-1"
                      />{" "}
                    </Button>
                  </div>

                  {isConverterOpen ? (
                    <div className="flex justify-center">
                      <Converter
                        className="max-w-sm w-full"
                        onClose={() => setIsConverterOpen(false)}
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}

              <FormGroup label="Quantity:" name="quantity">
                <Field
                  name="quantity"
                  validate={validateDecimal()}
                  className="form-control"
                />
              </FormGroup>
              {!create_recipe ? (
                <>
                  <FormGroup label="Meal:" name="meal">
                    <Select
                      isSearchable={false}
                      name="meal"
                      validate={(v) => !v}
                      options={meals}
                      simpleValue
                    />
                  </FormGroup>
                  {project.log_food_time ? (
                    <FormGroup label={t("What time?")}>
                      <Row form>
                        <Col>
                          <FormGroup name="hour" className="mb-0">
                            <Select
                              placeholder={t("Hour")}
                              name="hour"
                              validate={(v) => !v}
                              options={HOURS}
                              simpleValue
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup name="minute" className="mb-0">
                            <Select
                              placeholder={t("Minute")}
                              name="minute"
                              validate={(v) => !v}
                              options={MINUTES}
                              simpleValue
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </FormGroup>
                  ) : null}

                  {project.log_method_of_consumption ? (
                    <FormGroup
                      label="Consumption method:"
                      name="method_of_consumption"
                    >
                      <Select
                        isSearchable={false}
                        options={consumptionMethods}
                        name="method_of_consumption"
                        simpleValue
                        validate={(v) => !v}
                      />
                    </FormGroup>
                  ) : null}
                </>
              ) : null}
              <FormGroup label="Create recipe">
                <Field as={Checkbox} name="create_recipe" type="checkbox" />
              </FormGroup>
              <div className="flex justify-end">
                <SubmitButton color="primary">
                  {create_recipe
                    ? t("Add to Recipe")
                    : values.id
                    ? t("Save Changes")
                    : t("Add to Diary")}
                </SubmitButton>
              </div>
            </div>
          </FormikProvider>
        </div>
      </ModalBody>
    </>
  );
};

export const Container = (props) => {
  const [key, setKey] = useState(0);
  return <FoodEntry {...props} key={key} reload={() => setKey((v) => v + 1)} />;
};

export default Container;
