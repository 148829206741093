import React from "react";
import { useUserContext } from "../hooks";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { SubmitButton } from "./SubmitButton";

const ImpersonateNotification = () => {
  const { user, impersonator, revertImpersonate } = useUserContext();
  const methods = useForm({
    defaultValues: {}
  });
  const { handleSubmit } = methods;

  async function onSubmit() {
    await revertImpersonate();
  }

  const { t } = useTranslation();

  if (!impersonator || !user) return null;

  return (
    <div className="fixed top-0 z-50 right-0 ml-1 mt-4 p-2 bg-gray-100 border border-gray-500 rounded-md">
      <p className="text-sm">
        {t("You are completing a diary on behalf of ")} {user.email}
      </p>
      <div className="flex justify-center mt-2">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SubmitButton size="sm" color="primary">
              {t("Finish")}
            </SubmitButton>
          </form>
        </FormProvider>
      </div>
    </div >
  );
};

export default ImpersonateNotification;
