import React from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";

export const SimpleContainer = ({ children }) => {
  return (
    <div className="min-h-screen bg-hc-secondary items-center flex flex-col justify-center py-12">
      <div className="max-w-md w-full">
        <div className="mb-8">
          <Link to="">
            <img className="mx-auto h-12 w-auto" src={logo} alt="Logo" />
          </Link>
        </div>
        {children}
      </div>
    </div>
  );
};
