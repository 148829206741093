import React, { useMemo, useEffect, useState } from "react";
import { keys, reduce } from "lodash";
import { ReportContainer, ReportTabs } from "./components";
import { Pie } from "react-chartjs-2";
import { useTranslation, Trans } from "react-i18next";
import { Legend } from "./Legend";

export const MealAnalysis = ({
  nutrientIntake,
  title = <Trans>Meal Analysis</Trans>,
  mealChoices,
}) => {
  const [active, setActive] = useState();
  const [chartRef, setChartRef] = useState();
  const [options, setOptions] = useState([]);
  const { meals, core } = nutrientIntake;
  const { t } = useTranslation();

  useEffect(() => {
    setActive(null);
    setOptions([]);
    if (!core) return;
    const options = keys(core);
    const active = options[0];
    setOptions(options);
    setActive(active);
  }, [core]);

  const { data, labels } = useMemo(() => {
    if (!meals || !active) return { data: null, labels: null };

    return reduce(
      meals,
      (acc, i, meal) => {
        const i18nKey = mealChoices[meal];
        if (!i18nKey) {
          return acc;
        }

        acc.labels.push(t(i18nKey));
        acc.data.push(i[active] ?? 0);
        return acc;
      },
      { labels: [], data: [] }
    );
  }, [meals, active, mealChoices, t]);

  const renderData = () => {
    return {
      datasets: [
        {
          data,
          backgroundColor: [
            "#5790c7",
            "#E08435",
            "#ec4785",
            "#B6EF6C",
            "#732576",
          ],
        },
      ],
      labels,
    };
  };

  return (
    <ReportContainer title={title}>
      <ReportTabs options={options} active={active} setActive={setActive} />
      <Legend chartRef={chartRef} />
      <Pie
        data={renderData}
        ref={setChartRef}
        options={{
          legend: {
            display: false,
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          events: false,
          plugins: {
            labels: [
              {
                render: "percentage",
                position: "outside",
                outsidePadding: 4,
                textMargin: 9,
                showZero: false,
                arc: true,
              },
            ],
          },
        }}
      />
    </ReportContainer>
  );
};
