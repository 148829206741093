
import React from "react";
import Calendar from 'react-calendar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../util";
import moment from 'moment';
import { Button } from ".";
import { Col } from 'reactstrap';
const _formatDate = (date) => formatDate(date, "YYYY-MM-DD");

const AngleButton = ({ left = false, numMonths = 6, setDate, startDate, ...props }) => (
  <Button className="btn btn-link btn-sm"
    onClick={() => setDate((left ? moment(startDate).subtract(numMonths, "months")
      : moment(startDate).add(numMonths, "months"))
      .toDate())}
  >
    <FontAwesomeIcon
      className="meal-plan-icon mr-2"
      icon={left ? faAngleLeft : faAngleRight}
      {...props}
    />
  </Button>
);

const Month = ({ date, diaries, view = "month", onClickDay }) => {
  return (
    <Calendar
      activeStartDate={date}
      // eslint-disable-next-line
      formatShortWeekday={(_, date) => formatDate(date, 'dd')[0]}
      nextLabel={null} next2Label={null}
      prevLabel={null} prev2Label={null}
      onClickMonth={null} onViewChange={null}
      onClickDay={onClickDay}
      // eslint-disable-next-line
      tileClassName={({ _, date, view }) => {
        return view === 'month' &&
        !!diaries &&
        diaries.has(_formatDate(date)) ? 'bg-hc-secondary' : null
      }}
      view={view}
    />
  );
}

// TODO: gets squished at smaller sizes, adding Rows/Cols should be fine
export const MultiMonthCalendar = ({ startDate, setDate, diaries, numMonths = 5, onClickDay }) => {
  return (
    <>
      <div className="col-auto h-100 flex items-center pr-0">
        <AngleButton setDate={setDate} startDate={startDate} left size="lg" />
      </div>
      {Array.from(Array(numMonths + 1).keys()).map((idx) => {
        return (
          <Col className="calendar-col" key={idx}>
            <Month onClickDay={onClickDay} diaries={diaries} date={moment(startDate).subtract(numMonths - idx, "months").toDate()} />
          </Col>
        );
      })}
      <div className="col-auto h-100 flex items-center pl-0">
        <AngleButton setDate={setDate} startDate={startDate} size="lg" />
      </div>
    </>
  );
}

export default MultiMonthCalendar;