import React from "react";
import LoginBase from "../components/LoginBase";
import Title from "../components/Title";
import { useClient } from "hooks";
import { FormGroup, SubmitButton, Form } from "components/common";
import { useFormik, FormikProvider, Field } from "formik";
import TermsLinks from "components/TermsLinks";
import { useNavigate } from "react-router";
import { validateEmail } from "services/validators";
import { useRecoilState } from "recoil";
import { lastStudentProjectCodeAtom, lastStudentEmailAtom } from "atoms";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import { SubTitle } from "../components/SubTitle";

export const Start = () => {
  const [lastStudentProjectCode, setLastStudentProjectCode] = useRecoilState(
    lastStudentProjectCodeAtom
  );
  const [lastStudentEmail, setLastStudentEmail] = useRecoilState(
    lastStudentEmailAtom
  );
  const formik = useFormik({
    initialValues: {
      project_code: lastStudentProjectCode,
      email_address: lastStudentEmail,
    },
    onSubmit,
  });
  const { setFieldError } = formik;
  const client = useClient({ sendAuth: false });
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function onSubmit({ project_code, email_address }) {
    try {
      const { username, project } = await client
        .post("teaching_login/check", {
          project_code,
          email_address,
        })
        .get("data");

      setLastStudentEmail(email_address);
      setLastStudentProjectCode(project_code);

      if (!username) {
        navigate("../register", {
          state: {
            project,
            initialValues: {
              email: email_address,
            },
          },
        });
      } else {
        navigate("../login", {
          state: {
            username,
            project,
          },
        });
      }
    } catch (ex) {
      const code = ex?.response?.data?.code;

      if (code === "InvalidProjectCode") {
        setFieldError("project_code", t("Please enter a valid project code"));
      } else {
        throw ex;
      }
    }
  }

  return (
    <LoginBase
      footer={<TermsLinks className="text-center mt-4" />}
      leftSide={
        <>
          <Title className="mb-4">
            <Trans>myfood24 teaching</Trans>
          </Title>
          <SubTitle className="prose prose-sm">
            <p>
              <Trans>
                Before using myfood24, you must login or register. Please enter
                the project code you have been given and your email address. If
                you have not used myfood24 before you will be asked for some
                details and we will send you an email containing a verification
                link. Clicking on the link will activate your account.
              </Trans>
            </p>
            <p>
              <Trans>
                By submitting this form, you agree to us contacting you by
                email.
              </Trans>
            </p>
          </SubTitle>
        </>
      }
      rightSide={
        <FormikProvider value={formik}>
          <Card body>
            <Form>
              <FormGroup label="Project code" name="project_code" showError>
                <Field
                  name="project_code"
                  validate={(v) => !v}
                  className="form-control"
                />
              </FormGroup>
              <FormGroup label="Email address" name="email_address" showError>
                <Field
                  name="email_address"
                  validate={validateEmail({
                    requiredError: t("Please enter your email address"),
                    invalidError: t("Please enter a valid email address"),
                  })}
                  className="form-control"
                />
              </FormGroup>
              <div className="flex justify-end">
                <SubmitButton type="submit">
                  <Trans>Next</Trans>
                </SubmitButton>
              </div>
            </Form>
          </Card>
        </FormikProvider>
      }
    />
  );
};

export default Start;
