import { atom, atomFamily, DefaultValue } from "recoil";

const localStorageEffect = (key) => ({ setSelf, onSet, node }) => {
  const _key = key ?? node.key;
  const value = localStorage.getItem(_key);
  if (value !== null) {
    try {
      setSelf(JSON.parse(value));
    } catch (ex) {
      localStorage.removeItem(_key);
    }
  }

  onSet((value) => {
    if (value instanceof DefaultValue) {
      localStorage.removeItem(_key);
    } else {
      localStorage.setItem(_key, JSON.stringify(value));
    }
  });
};

export const userGroupAtom = atom({
  key: "userGroup",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});

export const participantAtom = atom({
  key: "participant",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});

export const localeAtom = atom({
  key: "language",
  default: "en",
  effects_UNSTABLE: [localStorageEffect()],
});

export const impersonatorAtom = atom({
  key: "impersonator",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});

export const tokenAtom = atom({
  key: "token",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});

export const healthcareTokenAtom = atom({
  key: "healthcareToken",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});

export const activeProjectAtom = atom({
  key: "activeProject",
});

export const lastStudentProjectCodeAtom = atom({
  key: "lastStudentProjectCode",
  default: "",
  effects_UNSTABLE: [localStorageEffect()],
});

export const lastStudentEmailAtom = atom({
  key: "lastStudentEmail",
  default: "",
  effects_UNSTABLE: [localStorageEffect()],
});

export const listFiltersAtom = atomFamily({
  key: "listFilters",
  effects_UNSTABLE: [localStorageEffect()],
});

export const healthcareUserGroupAtom = atom({
  key: "healthcareUserGroup",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});

export const healthcarePatientAtom = atom({
  key: "healthcarePatient",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});

export const healthcareImpersonatorAtom = atom({
  key: "healthcareImpersonator",
  default: null,
  effects_UNSTABLE: [localStorageEffect()],
});
