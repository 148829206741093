import { Page } from "../../components/common";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Table,
} from "reactstrap";
import { formatDate, formatDateTime } from "../../services/formatters";
import { FormProvider, useForm } from "react-hook-form";
import { DatePicker } from "../../healthcare/components";
import { useAppAPIKeyRevoke, useAppAPIKeySave } from "./api";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { SubmitButton } from "./components";

export function AppAPIKeysView({ appId, apiKeys, onSubmitted }) {
  const { t } = useTranslation();
  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      name: "",
      expiry_date: null,
    },
  });
  const { register, handleSubmit, reset } = form;
  const { mutateAsync } = useAppAPIKeySave();
  const { mutateAsync: revoke } = useAppAPIKeyRevoke();
  const [key, setKey] = useState(null);

  async function onSubmit(x) {
    const { key } = await mutateAsync({
      ...x,
      expiry_date: x.expiry_date ? moment(x.expiry_date).format() : null,
      app: appId,
    });
    setKey(key);
    reset();
    NotificationManager.success(t("App API Key created"));
    await onSubmitted?.();
  }

  async function onRevoke({ prefix }) {
    const confirm = window.confirm(
      "Are you sure you want to revoke this App API Key. This cannot be undone."
    );

    if (!confirm) {
      return;
    }

    await revoke(prefix);
    await onSubmitted?.();
  }

  return (
    <Page title={t("App API Keys")}>
      {key ? (
        <>
          <h4 className="font-medium mb-3">
            <Trans>New App API Key</Trans>
          </h4>
          <FormGroup>
            <InputGroup>
              <Input
                key={key}
                readOnly
                defaultValue={key}
                className="form-control"
              />
              <InputGroupAddon addonType="append">
                <CopyToClipboard
                  text={key}
                  onCopy={() => {
                    NotificationManager.success(t("Copied to clipboard"));
                  }}
                >
                  <Button color="primary" outline>
                    <FontAwesomeIcon icon={faClipboard} />
                  </Button>
                </CopyToClipboard>
              </InputGroupAddon>
            </InputGroup>

            <FormText>
              <Trans>
                Make sure you save it - you won't be able to access it again.
              </Trans>
            </FormText>
          </FormGroup>
          <hr />
        </>
      ) : null}
      <h4 className="font-medium mb-3">Add an App API Key</h4>

      <FormProvider {...form}>
        <FormGroup>
          <Label for="api_key_name">
            <Trans>Name</Trans>
          </Label>
          <Input
            name="name"
            id="api_key_name"
            innerRef={register({
              required: true,
            })}
          />
          <FormText>
            <Trans>
              For example, the application using the key or the purpose of the
              key.
            </Trans>
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="expiry_date">
            <Trans>Expiration date (optional)</Trans>
          </Label>
          <DatePicker
            className="form-control"
            name="expiry_date"
            id="expiry_date"
          />
        </FormGroup>
        <div className="flex justify-end">
          <SubmitButton onClick={handleSubmit(onSubmit)}>
            <Trans>Create App API Key</Trans>
          </SubmitButton>
        </div>
      </FormProvider>
      <hr />
      <h4 className="font-medium mb-3">
        <Trans>Active App API Keys ({{ active: apiKeys.length }})</Trans>
      </h4>
      <Table size="sm" className={!apiKeys.length ? "hidden small" : "small"}>
        <thead>
          <tr>
            <th>
              <Trans>Name</Trans>
            </th>
            <th>
              <Trans>Created</Trans>
            </th>
            <th>
              <Trans>Expires</Trans>
            </th>
            <th>
              <Trans>Prefix</Trans>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {apiKeys.map((i) => (
            <tr key={i.id}>
              <td>{i.name}</td>
              <td>{formatDateTime(i.created)}</td>
              <td>{i.expiry_date ? formatDate(i.expiry_date) : t("Never")}</td>
              <td>{i.prefix}</td>
              <td className="text-right">
                <Button
                  size="sm"
                  color="danger"
                  outline
                  onClick={() => onRevoke(i)}
                >
                  <Trans>Revoke</Trans>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Page>
  );
}
