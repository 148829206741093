import React from "react";
import { ModalBody, Table, Button, Card, CardBody } from "reactstrap";
import { useModalContext } from "hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { ModalHeader } from "./components/ModalHeader";
import classNames from "classnames";

export const AccompanyingItems = ({ title, items, onAdd }) => {
  const { toggle } = useModalContext();

  return (
    <>
      <ModalHeader toggle={toggle}>
        <Trans>Would you like to add anything else?</Trans>
      </ModalHeader>
      <ModalBody>
        <div className="text-center mb-4">
          <Trans>The food item you added is commonly consumed with</Trans>:
        </div>
        <Card className="border-brand-light">
          {items.map((i, idx) => (
            <CardBody
              role="button"
              className={classNames(
                "items-center flex py-3",
                idx ? "border-t border-brand-light" : ""
              )}
              onClick={() => onAdd(i.id)}
              key={i.id}
            >
              <span className="flex-1 font-medium text-brand-dark">
                {i.description}
              </span>
              <FontAwesomeIcon
                icon={faPlusCircle}
                size="lg"
                className="text-brand-dark"
              />
            </CardBody>
          ))}
        </Card>
        <Table>
          <tbody></tbody>
        </Table>
        <div className=" flex justify-end">
          <Link to={"../../search/all"} className="btn btn-link mr-2">
            <Trans>Continue Adding</Trans>
          </Link>
          <Button color="primary" onClick={toggle} className="ml-2">
            <Trans>Finished</Trans>
          </Button>
        </div>
      </ModalBody>
    </>
  );
};

export default AccompanyingItems;
