import React from "react";
import { ButtonGroup } from "reactstrap";
import { Routes, Route, Navigate } from "react-router-dom";
import MyRecipes from "./MyRecipes";
import EditRecipe from "./EditRecipe";
import { Trans } from "react-i18next";
import { NavLink } from "./components/NavLink";

export const Recipes = () => {
  return (
    <div>
      <ButtonGroup className="max-w-full w-full">
        <NavLink to="my_recipes">
          <Trans>My Recipes</Trans>
        </NavLink>
        <NavLink to="new">
          <Trans>New Recipe</Trans>
        </NavLink>
      </ButtonGroup>
      <div className="mt-4">
        <Routes>
          <Route path="my_recipes" element={<MyRecipes />} />
          <Route path="new" element={<EditRecipe />} />
          <Navigate to="my_recipes" replace />
        </Routes>
      </div>
    </div>
  );
};

export default Recipes;
