import React from "react";
import classNames from "classnames";

export const H2 = ({ className, children, ...props }) => {
  return (
    <h2
      className={classNames("text-3xl font-bold text-hc-dark", className)}
      {...props}
    >
      {children}
    </h2>
  );
};
