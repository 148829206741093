import React from "react";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "selectors";
import { Trans } from "react-i18next";

export const ReferenceInformation = () => {
  return (
    <ul className="text-left flex flex-wrap text-gray-600 text-sm mt-2">
      <li className="mr-2">
        <span style={{ color: "#8FBE76" }}>&bull;</span>{" "}
        <Trans>Reference intake of an average female</Trans>
      </li>
      <li>
        <span style={{ color: "#E1B073" }}>&bull;</span>{" "}
        <Trans>Reference intake of an average male</Trans>
      </li>
    </ul>
  );
};

export const useReferenceAnnotations = (active) => {
  const { maleReferenceIntake, femaleReferenceIntake } = useRecoilValue(
    reportingStaticSelector
  );

  return {
    annotations: [
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: maleReferenceIntake[active] ?? 0,
        borderColor: "#E1B073",
        borderWidth: 2,
      },
      {
        type: "line",
        mode: "horizontal",
        scaleID: "y-axis-0",
        value: femaleReferenceIntake[active] ?? 0,
        borderColor: "#8FBE76",
        borderWidth: 2,
      },
    ],
    ReferenceInformation,
  };
};

export default useReferenceAnnotations;
