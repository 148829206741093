import React, {useState} from "react";
import { Pie } from "react-chartjs-2";
import {ReportContainer} from "./components";
import {useTranslation} from "react-i18next";
import {Legend} from "./Legend";

export const MacronutrientReport = ({ data }) => {
  const { t } = useTranslation();
  const [chartRef, setChartRef] = useState();

  const renderData = () => {
    return {
      datasets: [
        {
          data: [data['protein'], data['carbohydrates'], data['fat']],
          backgroundColor: [
            "#5790c7",
            "#E08435",
            "#ec4785",
          ],
        },
      ],
      labels: [t('Protein'), t('Carbohydrates'), t('Fat')],
    };
  };

  return (
    <ReportContainer title={t('Macronutrient Analysis')}>
      <Legend chartRef={chartRef} />
      <Pie
        data={renderData}
        ref={setChartRef}
        options={{
          legend: {
            display: false,
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          events: false,
          plugins: {
            labels: [
              {
                render: "percentage",
                position: "outside",
                outsidePadding: 4,
                textMargin: 9,
                showZero: false,
                arc: true,
              },
            ],
          },
        }}
      />
    </ReportContainer>
  );
};
