import { Alert } from "reactstrap";
import { Trans } from "react-i18next";
import download from "downloadjs";
import { toPng } from 'html-to-image';

export * from "./validators";
export * from "./formatters";

export async function elementToImage(element, filename) {
  const result = await toPng(element, {
    backgroundColor: 'white',
    skipAutoScale: true,
    pixelRatio: 1,
    quality: 1,
    filter: v => {
      return v?.dataset?.html2canvasIgnore !== 'true';
    }
  });
  download(result, filename);
}

export function getPasswordError(ex) {
  const { code } = ex;

  if (code === "invalid_token") {
    return (
      <Alert color="danger" className="text-sm text-center">
        <Trans>This link is not valid or has already been used.</Trans>
      </Alert>
    );
  } else if (code === "expired_token") {
    return (
      <Alert color="info" className="text-sm text-center">
        <Trans>This link has expired and can no longer be used.</Trans>
      </Alert>
    );
  } else if (code === "invalid_organisation_token") {
    return (
      <Alert color="danger" className="text-sm text-center">
        <Trans>This link is not valid.</Trans>
      </Alert>
    );
  } else if (code === "invalid_password") {
    return (
      <Alert color="danger" className="text-sm text-center">
        <Trans>The provided password is not strong enough.</Trans>
      </Alert>
    );
  } else if (code === "password_mismatch") {
    return (
      <Alert color="danger" className="text-sm text-center">
        <Trans>The provided passwords do not match.</Trans>
      </Alert>
    );
  } else {
    throw ex;
  }
}
