import React from "react";
import { useFormik, FormikProvider, Field } from "formik";
import { Link } from "react-router-dom";
import { isEmail } from "validator";
import TermsLinks from "components/TermsLinks";
import { SubmitButton, FormGroup, Form } from "components/common";
import LoginBase from "./components/LoginBase";
import Title from "./components/Title";
import { SubTitle } from "./components/SubTitle";
import { createClient } from "hooks/useClient";
import { Trans } from "react-i18next";
import { Card } from "reactstrap";
import { BackgroundWave } from "./components/BackgroundWave";

export const ResetPasswordRequest = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      success: false,
    },
    onSubmit,
  });
  const { setFieldValue } = formik;
  const { success } = formik.values;

  async function onSubmit(x) {
    const client = createClient();
    await client.post("password_reset_request", x);
    setFieldValue("success", true);
  }

  const backToLogin = (
    <div className="text-right">
      <Link to={"../login"} className="text-sm link--white">
        <Trans>Back to login</Trans>
      </Link>
    </div>
  );

  return (
    <LoginBase
      rightSide={
        <div className="max-w-md mx-auto relative">
          <BackgroundWave />
          <Title>
            <Trans>Forgot Password</Trans>
          </Title>
          {!success ? (
            <>
              <SubTitle>
                <Trans>
                  Enter your email address below to set or reset your password
                </Trans>
              </SubTitle>
              <FormikProvider value={formik}>
                <Form className="mt-4">
                  <FormGroup
                    label="Email address"
                    name="email"
                    className="mb-2"
                  >
                    <Field
                      name="email"
                      className="form-control form-control--dark-border"
                      validate={(v) => !isEmail(v)}
                    />
                  </FormGroup>
                  {backToLogin}
                  <SubmitButton type="submit" block className="mt-6">
                    <Trans>Request reset password</Trans>
                  </SubmitButton>
                </Form>
              </FormikProvider>
            </>
          ) : (
            <>
              <Card body>
                <div className="prose prose-sm text-center mb-4">
                  <p>
                    <Trans>
                      We have sent you an email containing a link to reset your
                      password.
                    </Trans>
                  </p>
                  <p>
                    <Trans>
                      If you don't get an email check your spam folder or try
                      again.
                    </Trans>
                  </p>
                </div>
              </Card>
              {backToLogin}
            </>
          )}
        </div>
      }
      footer={<TermsLinks className="text-center mt-4" />}
    />
  );
};

export default ResetPasswordRequest;
