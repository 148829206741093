import { reduce } from "lodash";
import { useNavigate, useLocation } from "react-router";
import { useRecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import {
  localeAtom,
  userGroupAtom,
  participantAtom,
  impersonatorAtom,
  tokenAtom,
} from "atoms";
import { userSelector, userGroupsSelector } from "selectors";
import { createClient } from "../hooks/useClient";

export const useUserContext = () => {
  const user = useRecoilValue(userSelector);
  const [userGroup, setUserGroup] = useRecoilState(userGroupAtom);
  const [participant, setParticipant] = useRecoilState(participantAtom);
  const [locale, setLocale] = useRecoilState(localeAtom);
  const [token, setToken] = useRecoilState(tokenAtom);
  const userGroups = useRecoilValue(userGroupsSelector);
  const [impersonator, setImpersonator] = useRecoilState(impersonatorAtom);
  const navigate = useNavigate();
  const location = useLocation();

  const clear = useRecoilCallback(({ reset }) => () => {
    reset(tokenAtom);
    reset(participantAtom);
    reset(userGroupAtom);
    reset(impersonatorAtom);
  });

  const logout = clear;

  function impersonate(impersonateToken, participant) {
    setImpersonator({
      token,
      userGroup,
      participant,
      returnTo: location,
    });
    setToken(impersonateToken);
    setUserGroup("participant");
    setParticipant(participant);
  }

  async function switchToUser(token, user_group, participant_id) {
    setToken(token);
    setUserGroup(user_group || null);
    setParticipant(participant_id || null);
  }

  async function revertImpersonate() {
    const { token, userGroup, participant, returnTo } = impersonator;
    setToken(token);
    setUserGroup(userGroup);
    setParticipant(participant);
    setImpersonator(null);
    navigate(returnTo || "/");
  }

  async function getToken(username, password, totp) {
    return createClient()
      .post("api-token-auth", {
        username,
        password,
        totp: totp ? totp : undefined
      })
      .get("data")
      .get("token");
  }

  const login = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) => async (username, password, totp) => {
      const token = await getToken(username, password, totp);

      snapshot = snapshot.map(({ set }) => {
        set(tokenAtom, token);
        return null;
      });

      const user = await snapshot.getPromise(userSelector);

      const { teachers, participants, researchers, is_superuser } = user;

      const { userGroup, participant } = reduce(
        {
          teacher: teachers.length,
          participant: participants,
          researcher: researchers.length,
          admin: is_superuser,
        },
        (acc, v, k) => {
          const { userGroup } = acc;

          if (k === "participant") {
            if (v.length > 0) {
              acc.userGroup = userGroup === undefined ? k : null;
            }

            if (v.length === 1) {
              acc.participant = v[0].id;
            }
          } else {
            if (!!v) {
              // if a user group has already been set, then set user group to null so it can be picked
              acc.userGroup = userGroup === undefined ? k : null;
            }
          }

          return acc;
        },
        {
          userGroup: undefined,
          participant: null,
        }
      );

      snapshot = snapshot.map(({ set }) => {
        set(participantAtom, participant);
        set(userGroupAtom, userGroup);
        return null;
      });

      gotoSnapshot(snapshot);
    }
  );

  return {
    user,
    userGroup,
    participant,
    locale,
    impersonator,
    switchToUser,
    login,
    logout,
    getToken,
    setUserGroup,
    setLocale,
    setParticipant,
    clear,
    impersonate,
    revertImpersonate,
    ...userGroups,
  };
};

export default useUserContext;
