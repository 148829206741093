import {FormProvider, useForm} from "react-hook-form";
import {SubmitButton} from "../../healthcare/components";
import {useClient, useModal} from "../../hooks";
import {useState} from "react";
import {NotificationManager} from "react-notifications";
import {Trans, useTranslation} from "react-i18next";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

export function PIIProjectButton({ projectId, onSubmitted }) {
  const { t } = useTranslation();
  const form = useForm();
  const { handleSubmit } = form;
  const client = useClient();
  const [skipped, setSkipped] = useState(null);
  const {
    Modal: ModalConfirm,
    toggle: toggleConfirm,
    CloseButton: CloseButtonConfirm,
    isOpen: isOpenConfirm,
    setIsOpen: setIsOpenConfirm
  } = useModal();
  const {
    Modal: ModalResult,
    toggle: toggleResult,
    CloseButton: CloseButtonResult,
    isOpen: isOpenResult,
    setIsOpen: setIsOpenResult
  } = useModal();

  async function onSubmit() {
    const { skipped } = await client.post(`projects/${projectId}/pii`).get('data');

    setIsOpenConfirm(false);

    if (skipped.length) {
      setSkipped(skipped);
      setIsOpenResult(true);
    } else {
      NotificationManager.success(t('PII successful'));
    }

    if (onSubmitted) {
      await onSubmitted();
    }
  }

  return (
    <>
      {isOpenResult && skipped ? (
        <ModalResult size="lg">
          <ModalHeader toggle={toggleResult}>
            <Trans>
              Result
            </Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>
              The following participants were not able to be processed due to one of the following reasons:
              <ul className="list-disc list-inside pl-3">
                <li>The user is a participant in other projects</li>
                <li>The user is a healthcare user</li>
                <li>The user is the owner of projects</li>
              </ul>
            </Trans>
            <hr/>
            <ul>
              {skipped.map(i => (
                <li key={i.id}>
                  {i.full_name} ({i.email})
                </li>
              ))}
            </ul>
          </ModalBody>
          <ModalFooter>
            <CloseButtonResult />
          </ModalFooter>
        </ModalResult>
      ) : null}
      {isOpenConfirm ? (
        <ModalConfirm>
          <ModalHeader toggle={toggleConfirm}>
            <Trans>PII Project</Trans>
          </ModalHeader>
          <ModalBody>
            <Trans>
              Are you sure you want to remove personally identifiable
              information from this project. <strong>This cannot be undone.</strong>
            </Trans>
          </ModalBody>
          <ModalFooter>
            <CloseButtonConfirm />
            <FormProvider {...form}>
              <SubmitButton onClick={handleSubmit(onSubmit)}>
                PII Project
              </SubmitButton>
            </FormProvider>
          </ModalFooter>
        </ModalConfirm>
      ) : null}
      <Button color="primary" onClick={toggleConfirm}>
        <Trans>
          Delete PII
        </Trans>
      </Button>
    </>
  )
}