import React, { useMemo } from "react";
import BaseSelect, { components } from "react-select";
import { keyBy } from "lodash";
import { useField } from "formik";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const NoOptionsMessage = (props) => {
  const { t } = useTranslation();

  return (
    <components.NoOptionsMessage {...props}>
      {t("No Options")}
    </components.NoOptionsMessage>
  );
};

export const Select = ({
  name,
  validate,
  onChange: onChangeProp,
  options,
  simpleValue,
  labelKey = "label",
  valueKey = "value",
  className,
  isMulti,
  styles,
  ...props
}) => {
  const [field, , { setValue }] = useField({
    name,
    validate,
  });
  const { value: _value } = field;
  const keyedOptions = useMemo(
    () => (options && simpleValue ? keyBy(options, valueKey) : {}),
    [options, valueKey, simpleValue]
  );
  const { t } = useTranslation();

  const value = useMemo(() => (simpleValue ? keyedOptions[_value] : _value), [
    _value,
    simpleValue,
    keyedOptions,
  ]);

  return (
    <BaseSelect
      {...field}
      value={value}
      onChange={(e) => {
        if (simpleValue) {
          setValue(e ? e[valueKey] : null);
        } else {
          setValue(e);
        }

        if (onChangeProp) {
          setTimeout(() => onChangeProp(e), 0);
        }
      }}
      getOptionLabel={(v) => v[labelKey]}
      getOptionValue={(v) => v[valueKey]}
      className={classNames("react-select", className)}
      classNamePrefix="react-select"
      options={options}
      isMulti={isMulti}
      components={{
        NoOptionsMessage,
      }}
      styles={
        styles ?? {
          menu: (provided) => ({ ...provided, zIndex: 5 }),
        }
      }
      placeholder={t("Select...")}
      {...props}
    />
  );
};

export default Select;
