import { SimpleContainer } from "./SimpleContainer";
import { Button, Card } from "reactstrap";
import { ErrorBoundary as BaseErrorBoundary } from "react-error-boundary";
import React from "react";
import { useQueryErrorResetBoundary } from "react-query";
import { Trans } from "react-i18next";

export const ErrorBoundary = ({ children }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <BaseErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <SimpleContainer>
          <Card body>
            <div className="text-center">
              <Trans>
                Oops, something went wrong.{" "}
                <Button color="link" size="sm" onClick={resetErrorBoundary}>
                  Try again.
                </Button>
              </Trans>
            </div>
          </Card>
        </SimpleContainer>
      )}
    >
      {children}
    </BaseErrorBoundary>
  );
};
