import React, { useState, useMemo } from "react";
import { Page, FormGroup, SubmitButton, Select } from "components/common";
import { Field, useFormik, FormikProvider } from "formik";
import { useNavigate, useParams, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { find } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import isEmail from "validator/es/lib/isEmail";
import FormCheck from "../../components/common/FormCheck";

export const HCPUserEdit = () => {
  const formik = useFormik({
    onSubmit,
  });
  const {
    resetForm,
    values,
    setSubmitting,
    setFieldError,
    setFieldValue,
  } = formik;
  const [organisations, setOrganisations] = useState();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const client = useClient();
  const isNew = id === "new";
  const url = "hcp_users";
  const title = isNew ? t("Create HCP User") : t("Edit HCP User");

  const organisation = values?.user_group;
  const teams = values?.teams;

  const selectedTeams = useMemo(() => {
    if (!teams) return {};
    return teams.reduce((acc, i) => {
      acc[i] = true;
      return acc;
    }, {});
  }, [teams]);

  const selectedOrganisation = useMemo(() => {
    if (!organisation) return null;
    return find(organisations, { id: organisation }) ?? null;
  }, [organisations, organisation]);

  async function onSubmit(x) {
    try {
      const values = await (isNew
        ? client.post(url, x)
        : client.put(`${url}/${id}`, x)
      ).get("data");
      navigate(`../${values.id}`, { replace: true });
      resetForm({ values });
      NotificationManager.success(t("Saved successfully"));
    } catch (ex) {
      const { forError, code } = ex;

      if (code === "email_failed") {
        NotificationManager.error(t("Unable to send invitation email"));
        return;
      } else {
        forError("healthcare_user.email", (i) => {
          if (i.code === "unique") {
            setFieldError(
              "healthcare_user.email",
              t("A healthcare user with this email already exists")
            );
            ex.handled = true;
          }
        });

        forError("non_field_errors", (i) => {
          if (i.code === "existing_user") {
            setFieldError(
              "healthcare_user.email",
              t("A user with this email is already linked to a healthcare user")
            );
            ex.handled = true;
          }
        });
      }

      if (!ex.handled) {
        throw ex;
      }
    }
  }

  async function onResendInvitation() {
    setSubmitting(true);

    try {
      await client.post(`${url}/${id}/resend_invitation_email`).get("data");
      NotificationManager.success(t("Email sent successfully"));
    } catch (ex) {
      if (ex.code === "email_failed") {
        NotificationManager.error(t("Failed to send invitation email"));
      } else {
        throw ex;
      }
    } finally {
      setSubmitting(false);
    }
  }

  useAsyncEffect(async () => {
    const [values, organisations] = await Promise.all([
      client.get(`${url}/${id}`).get("data"),
      client.get("healthcare_organisations").get("data"),
    ]);

    setOrganisations(organisations);

    resetForm({
      values,
    });
  }, []);

  if (!values) return null;

  return (
    <Page title={title}>
      <FormikProvider value={formik}>
        <FormGroup
          label={t("Email")}
          required
          name="healthcare_user.email"
          showError
        >
          <Field
            name="healthcare_user.email"
            validate={(v) => !isEmail(v)}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label={t("Name")} required name="healthcare_user.name">
          <Field
            name="healthcare_user.name"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label={t("Job title")} required name="job_title">
          <Field
            name="job_title"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label={t("Organisation")} name="user_group">
          <Select
            options={organisations}
            validate={(v) => !v}
            simpleValue
            name="user_group"
            labelKey="name"
            valueKey="id"
          />
        </FormGroup>
        {selectedOrganisation?.teams?.length ? (
          <FormGroup label={t("Teams")}>
            <ul>
              {selectedOrganisation.teams.map((i) => (
                <li key={i.id}>
                  <FormCheck
                    label={i.name}
                    input={
                      <input
                        id={`team-${i.id}`}
                        type="checkbox"
                        className="form-check-input"
                        checked={!!selectedTeams[i.id]}
                        onChange={(e) => {
                          const { checked } = e.target;
                          const { id } = i;
                          setFieldValue(
                            "teams",
                            checked
                              ? [...teams, id]
                              : teams.filter((i) => i !== id)
                          );
                        }}
                      />
                    }
                  />
                </li>
              ))}
            </ul>
          </FormGroup>
        ) : null}
        {values.id ? (
          <>
            <hr />
            <FormGroup label={t("User")}>
              <Link to={`../../../users/${values.healthcare_user__user}`}>
                {values.healthcare_user__user__email}
              </Link>
            </FormGroup>
            {values.healthcare_user__invitation_link ? (
              <FormGroup label={t("Invitation link")} className="text-sm">
                <a href={values.healthcare_user__invitation_link}>
                  {values.healthcare_user__invitation_link}
                </a>
              </FormGroup>
            ) : null}
          </>
        ) : null}
        <div className="flex">
          {values.id ? (
            <SubmitButton outline onClick={onResendInvitation}>
              <Trans>Resend invitation</Trans>
            </SubmitButton>
          ) : null}
          <SubmitButton className="ml-auto">
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};
