import { Nav, NavItem, NavLink } from "reactstrap";
import React, { useMemo } from "react";
import { getLabel } from "../utils";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "selectors";
import { useTranslation } from "react-i18next";

export const ReportTabs = ({ options: optionsProp, active, setActive }) => {
  const { nutrientNames } = useRecoilValue(reportingStaticSelector);
  const { t } = useTranslation();

  const options = useMemo(() => {
    if (!optionsProp) return [];
    return optionsProp.map((i) => ({
      label: nutrientNames[i] ?? getLabel(i),
      key: i,
    }));
  }, [optionsProp, nutrientNames]);

  return (
    <Nav tabs className="justify-center mb-2">
      {options.map(({ key, label }) => (
        <NavItem key={key}>
          <NavLink
            href="#"
            active={active === key}
            onClick={() => {
              setActive(key);
            }}
          >
            {t(label)}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default ReportTabs;
