import moment from "moment";

export const formatDate = (v, format = "DD/MM/YYYY") => {
  const m = moment(v);
  if (!m.isValid()) return null;
  return m.format(format);
};

export const formatISODate = (v, format = "YYYY-MM-DD") =>
  formatDate(v, format);

export const formatDateTime = (v, format = "DD/MM/YYYY HH:mm") => {
  const m = moment(v);
  if (!m.isValid()) return null;
  return m.format(format);
};
