import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: ["en"],
    supportedLngs: ["en", "no", "da", "de", "fr", "ar", "es", "en_us", "it"],
    preload: ["en"],
    debug: process.env.REACT_APP_I18NEXT_DEBUG === "true",
    backend: {
      loadPath: "/api/static/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    nsSeparator: false,
    react: {
      wait: true,
      useSuspense: true,
    },
  });

export default i18n;
