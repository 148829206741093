import React from "react";
import { Page, FormGroup, SubmitButton, Form } from "components/common";
import { Field, useFormik, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { Trans, useTranslation } from "react-i18next";

export const Edit = () => {
  const formik = useFormik({
    onSubmit,
  });
  const { resetForm, values } = formik;
  const navigate = useNavigate();
  const { id } = useParams();
  const client = useClient();
  const isNew = id === "new";
  const url = "organisations";
  const title = isNew ? "Create Organisation" : "Edit Organisation";
  const { t } = useTranslation();

  async function onSubmit(x) {
    const values = await (isNew
      ? client.post(url, x)
      : client.put(`${url}/${id}`, x)
    ).get("data");
    navigate(`../${values.id}`, { replace: true });
    resetForm({ values });
    NotificationManager.success(t("Saved successfully"));
  }

  useAsyncEffect(async () => {
    const input = isNew
      ? {
          name: "",
        }
      : client.get(`${url}/${id}`).get("data");

    const [values] = await Promise.all([input]);

    resetForm({ values });
  }, []);

  if (!values) return null;

  return (
    <Page title={title} translateTitle>
      <FormikProvider value={formik}>
        <Form>
          <FormGroup label="Organisation" name="name" required>
            <Field name="name" className="form-control" validate={(v) => !v} />
          </FormGroup>
          <div className="flex justify-end">
            <SubmitButton>
              <Trans>Save</Trans>
            </SubmitButton>
          </div>
        </Form>
      </FormikProvider>
    </Page>
  );
};

export default Edit;
