import React, { useMemo, useState } from "react";
import { Button } from "reactstrap";
import { useModal } from "hooks";
import { map, keys } from "lodash";
import Select from "react-select";
import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";

const createOptionGroup = (label, options, includeAll, t) => {
  const all = includeAll ? [{
    label: `${t("All")} ${label}`,
    value: keys(options).join("|"),
  }] : [];

  return {
    label,
    options: [
      ...all,
      ...map(options, (label, value) => ({
        label,
        value,
      })),
    ],
  };
};

export const useNutrientOptions = ({ nutrientGroups, includeAll = true }) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!nutrientGroups) return [];
    return [
      createOptionGroup(t("Back of pack"), nutrientGroups["back_of_pack"], includeAll, t),
      createOptionGroup(
        t("Macronutrients"),
        nutrientGroups["macronutrients"],
        includeAll,
        t,
      ),
      createOptionGroup(t("Vitamins"), nutrientGroups["vitamins"], includeAll, t),
      createOptionGroup(t("Minerals"), nutrientGroups["minerals"], includeAll, t),
      //createOptionGroup(t("Sustainability"), nutrientGroups["sustainability"], t),
    ];
  }, [nutrientGroups, t, includeAll]);
}

export const NutrientSelect = ({ nutrientGroups, keys, setKeys, ...props }) => {
  const { t } = useTranslation();
  const nutrientOptions = useNutrientOptions({ nutrientGroups })

  return (
    <Select
      ref={(r) => {
        if (r) {
          setTimeout(() => {
            r.focus();
          }, 0);
        }
      }}
      placeholder={t("Select nutrients...")}
      options={nutrientOptions}
      isMulti
      value={keys}
      onChange={(v) => {
        setKeys(v);
      }}
      {...props}
    />
  )
}

export const ChangeReportSettingsButton = ({
  className,
  style,
  setReportNutrients,
  reportNutrients,
  nutrientGroups,
}) => {
  const { isOpen, setIsOpen, Modal, ModalBody, ModalHeader } = useModal();
  const [_keys, _setKeys] = useState(reportNutrients);

  return (
    <>
      {isOpen ? (
        <Modal>
          <ModalHeader>
            <Trans>Report Settings</Trans>
          </ModalHeader>
          <ModalBody>
            <NutrientSelect nutrientGroups={nutrientGroups} keys={_keys} setKeys={_setKeys} />
            <div className="flex justify-center mt-4">
              <Button
                color="primary"
                onClick={() => {
                  setReportNutrients(_keys);
                  setIsOpen(false);
                }}
              >
                <Trans>View report</Trans>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
      <Button
        color="secondary"
        outline
        className={classNames(className)}
        style={style}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <Trans>Change Report Settings</Trans>
      </Button>
    </>
  );
};

export default ChangeReportSettingsButton;
