import React from "react";
import classNames from "classnames/dedupe";
import { get } from "lodash";
import { useFormContext } from "react-hook-form";
import cx from "classnames";

export const HelpText = props => {
  return (
    <div className="mt-1 opacity-75 text-sm" {...props} />
  )
}

export const FormGroup = ({
  label,
  name,
  children,
  className,
  helpText,
  required,
  htmlFor,
  showError,
  labelProps,
  hideLabel,
  getLabelProps = (v) => v,
  ...props
}) => {
  const { errors, formState } = useFormContext();
  const { submitCount, touched } = formState;
  const error =
    get(touched, name) || submitCount ? get(errors, name, null) : null;

  return (
    <div
      className={classNames(
        "form-group",
        {
          "has-error": error,
        },
        className
      )}
      {...props}
    >
      {label ? (
        <label
          {...getLabelProps({
            className: cx("form-group__label", {
              'sr-only': hideLabel
            }),
          })}
          htmlFor={htmlFor}
        >
          {label} {required ? <span className="text-red-500">*</span> : null}
        </label>
      ) : null}
      {children}
      {helpText ? (
        <HelpText>{helpText}</HelpText>
      ) : null}
      {showError && error?.message ? (
        <div className="mt-1 text-sm text-red-500">{error?.message}</div>
      ) : null}
    </div>
  );
};
