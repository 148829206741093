import React from "react";
import { useTable } from "hooks";
import { Page, FiltersForm, DebouncedInput } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { Field } from "formik";
import { Trans } from "react-i18next";

export const List = () => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    setFilters,
  } = useTable({
    url: "teachers",
  });

  return (
    <Page title="Teachers" fluid>
      <TableSearch />
      <TableContainer>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th sortBy="user__first_name">
                <Trans>Name</Trans>
              </th>
              <th sortBy="user__email">
                <Trans>Email address</Trans>
              </th>
              <th sortBy="organisation__name">
                <Trans>Organisation</Trans>
              </th>
            </tr>
            <FiltersForm
              persistKey="teachers"
              onChange={setFilters}
              initialValues={{
                name: "",
                user__email: "",
                organisation__name: "",
              }}
            >
              <tr>
                <td>
                  <Field
                    name="user__full_name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="user__email"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="organisation__name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td>
                  <Link to={`../users/${i.user}`}>{i.user__full_name}</Link>
                </td>
                <td>{i.user__email}</td>
                <td>{i.organisation__name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};

export default List;
