import React from "react";
import { CardHeader as BaseCardHeader } from "reactstrap";
import classNames from "classnames";

const sizeMapping = {
  md: "text-2xl",
  lg: "text-3xl",
  sm: "text-xl",
  xs: "text-lg",
};

export const CardHeader = ({
  children,
  className,
  selected,
  size = "md",
  ...props
}) => {
  return (
    <BaseCardHeader
      className={classNames(
        `font-medium ${sizeMapping[size] ?? ""}`,
        !selected
          ? "bg-brand-light text-brand-dark"
          : "bg-brand-dark text-white",
        className
      )}
      {...props}
    >
      {children}
    </BaseCardHeader>
  );
};
