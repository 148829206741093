import React from "react";
import { CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames/dedupe";

export const FoodCardBody = ({
  onClick,
  description,
  brand,
  idx,
  className,
  append,
  amount,
  ...props
}) => {
  let cardProps = {};

  if (onClick) {
    cardProps = {
      onClick,
      role: "button",
    };
  }

  return (
    <CardBody
      {...cardProps}
      className={classNames(
        "py-2",
        idx > 0 ? "border-t border-brand-light" : "",
        className
      )}
      {...props}
    >
      <Row className="items-center">
        <Col>
          <div className="text-brand-dark font-medium">{description}</div>
          {brand ? <div className="text-sm">{brand}</div> : null}
        </Col>
        {amount ? (
          <Col xs="auto" className="text-sm text-muted text-gray-700">
            {amount}
          </Col>
        ) : null}
        {onClick ? (
          <Col xs="auto" className="text-brand-dark">
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          </Col>
        ) : null}
        {append ?? null}
      </Row>
    </CardBody>
  );
};
