import React from "react";
import { useTable } from "hooks";
import { Page } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export const List = () => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
  } = useTable({
    url: "organisations",
    resource: "Organisation",
  });

  return (
    <Page title="Organisations" fluid translateTitle>
      <TableSearch />
      <div className="flex justify-between mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table size="sm" hover striped>
          <thead>
            <tr>
              <th select />
              <th sortBy="name">
                <Trans>Organisation</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${i.id}`}>{i.name}</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
