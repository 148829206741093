import React, { useState } from "react";
import { useTable, useOnMount, useClient } from "hooks";
import {
  Page,
  FiltersForm,
  BooleanIcon,
  DebouncedInput,
  Select,
} from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { Field } from "formik";
import { Trans } from "react-i18next";

export const List = () => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    setFilters,
    BulkDeleteButton,
  } = useTable({
    url: "users",
    resource: "User",
  });
  const client = useClient();
  const [groups, setGroups] = useState();

  useOnMount(async () => {
    const groups = await client
      .get("users/groups")
      .get("data")
      .map((i) => ({
        label: i,
        value: i,
      }));
    setGroups(groups);
  });

  return (
    <Page title={<Trans>Users</Trans>} fluid>
      <TableSearch />
      <div className="flex justify-between mb-3">
        <AddNewButton resource="administrator" />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th select />
              <th sortBy="username">
                <Trans>Username</Trans>
              </th>
              <th sortBy="email">
                <Trans>Email address</Trans>
              </th>
              <th>
                <Trans>Groups</Trans>
              </th>
              <th className="text-center">
                <Trans>Enabled</Trans>
              </th>
            </tr>
            <FiltersForm
              persistKey="users"
              onChange={({ groups, ...x }) => {
                setFilters({
                  ...x,
                  groups: groups
                    ? groups.map((i) => i.value).join(",")
                    : undefined,
                });
              }}
              initialValues={{
                email: "",
                username: "",
                groups: "",
              }}
            >
              <tr>
                <td />
                <td>
                  <Field
                    name="username"
                    className="form-control form-control-sm"
                    style={{ height: 38 }}
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="email"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                    style={{ height: 38 }}
                  />
                </td>
                <td style={{ minWidth: "15rem" }}>
                  <Select
                    name="groups"
                    className="text-xs"
                    isMulti
                    options={groups}
                  />
                </td>
                <td />
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${i.id}`}>{i.username}</Link>
                </td>
                <td>{i.email}</td>
                <td className="text-sm">{i.groups.join(", ")}</td>
                <td className="text-center">
                  <BooleanIcon test={i.is_active} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};

export default List;
