import {useParticipantClient} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {useParticipantContext} from "../../../contexts";
import {useSustainabilityReportQuery} from "../queries/useSustainabilityReportQuery";
import React from "react";
import {SustainabilityReport} from "../../../components/Reports/SustainabilityReport";

export const ParticipantSustainabilityReport = () => {
  const client = useParticipantClient();
  const {t} = useTranslation();
  const {project} = useParticipantContext();

  const {data} = useSustainabilityReportQuery({
    client,
    options: {
      suspense: true
    }
  });
  const {is_student_project} = project;

  const title = is_student_project ? (
    t('Sustainability analysis')
  ) : (
    t('Your Sustainability Summary')
  )

  return <SustainabilityReport data={data} title={title}/>
}