import React, { useState } from "react";
import LoginBase from "../components/LoginBase";
import Title from "../components/Title";
import { useClient } from "hooks";
import { FormGroup, SubmitButton, Select, Form } from "components/common";
import { useFormik, FormikProvider, Field } from "formik";
import TermsLinks from "components/TermsLinks";
import { useLocation, Navigate, Link } from "react-router-dom";
import { validateEmail, validateInt } from "services/validators";
import { Alert, Card } from "reactstrap";
import { Trans } from "react-i18next";
import { SubTitle } from "../components/SubTitle";

//2c11d2520

const validateAge = (...args) => (v) => {
  const result = validateInt(...args)(v);
  if (result === "invalid") return "Please enter your age as a number";
  return result;
};

const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const Register = () => {
  const { state } = useLocation();
  const { initialValues, project } = state ?? {};
  const [success, setSuccess] = useState();

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate: ({ password, confirm_password }) => {
      const errors = {};
      if (password !== confirm_password) errors.password_mismatch = true;
      return errors;
    },
  });
  const { touched, submitCount, errors } = formik;

  const client = useClient({ sendAuth: false });

  if (!state) {
    return <Navigate to="../start" replace />;
  }

  async function onSubmit(x) {
    try {
      await client.post("teaching_login/register", {
        ...x,
        project: project.id,
      });
      setSuccess(true);
    } catch (ex) {
      throw ex;
    }
  }

  return (
    <LoginBase
      footer={<TermsLinks className="text-center mt-4" />}
      leftSide={
        <>
          <Title className="mb-4">
            <Trans>myfood24 teaching</Trans>
          </Title>
          <SubTitle className="prose prose-sm">
            {success ? (
              <>
                <p>
                  <Trans>
                    Before accessing{" "}
                    <strong>{{ projectName: project.title }}</strong> please
                    confirm your email address.
                  </Trans>
                </p>
              </>
            ) : (
              <>
                <p>
                  <Trans>
                    Before accessing{" "}
                    <strong>{{ projectName: project.title }}</strong> you must
                    register. Please enter your details and we will send you an
                    email containing a verification link. Clicking on the link
                    will activate your account.
                  </Trans>
                </p>
                <p>
                  <Trans>
                    By submitting this form, you agree to us contacting you by
                    email.
                  </Trans>
                </p>
              </>
            )}
          </SubTitle>
        </>
      }
      rightSide={
        <Card body>
          <FormikProvider value={formik}>
            {success ? (
              <div className="prose prose-sm">
                <Trans>
                  An email has been sent to you containing an activation link.
                  Please click on the link to activate your account
                </Trans>
              </div>
            ) : (
              <Form>
                <FormGroup label="Email address" required name="email">
                  <Field
                    name="email"
                    readOnly
                    validate={validateEmail()}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup label="First name" required name="first_name">
                  <Field
                    name="first_name"
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup label="Last name" required name="last_name">
                  <Field
                    name="last_name"
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
                {project.is_age_required ? (
                  <FormGroup label="Age" required name="age" showError>
                    <Field
                      name="age"
                      validate={
                        validateAge()
                      }
                      className="form-control"
                    />
                  </FormGroup>
                ) : null}
                {project.is_gender_required ? (
                  <FormGroup label="Gender" required name="gender">
                    <Select
                      options={GENDERS}
                      name="gender"
                      simpleValue
                      validate={
                        (v) => !v
                      }
                    />
                  </FormGroup>
                ) : null}
                <FormGroup label="Password" required name="password">
                  <Field
                    type="password"
                    name="password"
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup
                  label="Repeat password"
                  required
                  name="confirm_password"
                >
                  <Field
                    type="password"
                    name="confirm_password"
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
                {errors.password_mismatch &&
                ((touched.password && touched.confirm_password) ||
                  submitCount) ? (
                  <Alert color="danger">
                    <Trans>Passwords do not match</Trans>
                  </Alert>
                ) : null}
                <div className="flex justify-between">
                  <Link className="btn btn-secondary" to="../start">
                    <Trans>Back</Trans>
                  </Link>
                  <SubmitButton type="submit">
                    <Trans>Register</Trans>
                  </SubmitButton>
                </div>
              </Form>
            )}
          </FormikProvider>
        </Card>
      }
    />
  );
};

export default Register;
