import React, { useMemo, useState } from "react";
import { keys, map } from "lodash";
import { ReportContainer, ReportTabs } from "./components";
import { Line } from "react-chartjs-2";
import { getPallet } from "./utils";
import { useActiveNutrient } from "./useActiveNutrient";
import { Legend } from "./Legend";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "../../selectors";

export const DiaryDateIndividual = ({ data: dataProp, dates, title }) => {
  const { options, active, activeData, setActive } = useActiveNutrient(
    dataProp
  );
  const { fieldUnits } = useRecoilValue(reportingStaticSelector);
  const [chartRef, setChartRef] = useState();

  const datasets = useMemo(() => {
    if (!activeData) return null;

    const num = keys(activeData).length;
    const colors = getPallet(num);

    return map(activeData, (i, label) => {
      const color = colors.pop();
      return {
        data: dates.map((j) => (i[j] !== undefined ? i[j].toFixed(2) : null)),
        label,
        showLine: false,
        borderColor: color,
        backgroundColor: color,
      };
    });
  }, [activeData, dates]);

  const renderData = () => {
    return {
      datasets,
      labels: dates,
    };
  };

  return (
    <ReportContainer title={title}>
      <ReportTabs options={options} active={active} setActive={setActive} />
      <Legend chartRef={chartRef} />
      <Line
        data={renderData}
        ref={setChartRef}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: (value) => {
                    return `${value} ${fieldUnits[active] ?? "g"}`;
                  },
                  suggestedMin: 0,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
        }}
      />
    </ReportContainer>
  );
};

export default DiaryDateIndividual;
