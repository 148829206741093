import React from "react";
import { useFormik, FormikProvider, Field } from "formik";
import {useModal, useUserContext} from "hooks";
import { useNavigate, Link } from "react-router-dom";
import {
  SubmitButton,
  LanguageSelector,
  FormGroup,
  Form,
} from "components/common";
import TermsLinks from "components/TermsLinks";
import LoginBase from "./components/LoginBase";
import Title from "./components/Title";
import { Row, Col } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { BackgroundWave } from "./components/BackgroundWave";
import {TwoFactorAuthenticationModal} from "../../coherent/totp/components";

export const Login = () => {
  const { login } = useUserContext();
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit,
  });
  const { submitForm, setFieldValue } = formik;
  const navigate = useNavigate();
  const { Modal, setIsOpen, isOpen, toggle } = useModal();

  async function onSubmit({ username, password, totp }) {
    try {
      await login(username, password, totp);
    } catch (ex) {
      const status = ex?.response?.status;
      const code = ex.response?.data?.code;

      if (code === 'totp_pin_required') {
        setIsOpen(true);
        return;
      } else if (code === 'totp_incorrect_pin') {
        NotificationManager.error(t("Incorrect Two-Factor Authentication pin"));
        ex.handled = true;
        throw ex;
      } else if (status === 400) {
        NotificationManager.error(t("Invalid username or password"));
        return;
      } else {
        throw ex;
      }
    }

    navigate("/");
  }

  return (
    <LoginBase
      rightSide={
        <div className="relative max-w-sm mx-auto">
          {isOpen ? (
            <Modal>
              <TwoFactorAuthenticationModal toggle={toggle} onSubmit={async (x) => {
                try {
                  setFieldValue('totp', x.totp);
                  await submitForm();
                  setIsOpen(false);
                } finally {
                  setFieldValue('totp', null);
                }
              }} />
            </Modal>
          ) : null}
          <BackgroundWave />
          <Title className="text-white">{t("Login")}</Title>
          <p className="text-center text-sm mb-4 text-brand-dark font-semibold">
            {t("Enter your login details below")}
          </p>
          <LanguageSelector className="right-0 top-0 absolute mr-2 mt-1" />
          <FormikProvider value={formik}>
            <Form className="mt-4">
              <FormGroup label="Email address" name="username" className="mb-2">
                <Field
                  name="username"
                  className="form-control form-control--dark-border"
                  validate={(v) => !v}
                />
              </FormGroup>
              <FormGroup label="Password" name="password" className="mb-2">
                <Field
                  name="password"
                  type="password"
                  validate={(v) => !v}
                  className="form-control form-control--dark-border"
                />
              </FormGroup>
              <Row className="items-center mt-4">
                <Col>
                  <Link
                    to={"../reset_password_request"}
                    className="link--white"
                  >
                    {t("Password")}?
                  </Link>
                </Col>
                <Col>
                  <SubmitButton type="submit" block>
                    {t("Login")}
                  </SubmitButton>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </div>
      }
      footer={<TermsLinks className="text-center mt-4" />}
    />
  );
};

export default Login;
