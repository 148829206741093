import { useUserContext} from "../hooks";
import {StartTourModal, TourJoyride, TourListModal, useTour, TourProvider } from "../features/tour";

export const HealthcareTour = ({ children }) => {
  const { user } = useUserContext();

  const tour = useTour({
    user,
  });
  const { getStartTourProps, getTourListProps, getJoyrideProps, ready } = tour;

  return (
    <TourProvider {...tour}>
      {children}
      {ready ? (
        <>
          <StartTourModal
            size="lg"
            {...getStartTourProps()}
          />
          <TourJoyride {...getJoyrideProps()} />
          <TourListModal {...getTourListProps()} />
        </>
      ) : null}
    </TourProvider>
  );
};
