import React, { useState } from "react";
import { Page } from "components/common";
import { useOnMount, useClient } from "hooks";
import { useParams, useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useUserContext } from "hooks";

export const AcceptInvite = () => {
  const { token, respondent_id } = useParams();
  const [error, setError] = useState();
  const client = useClient({
    sendAuth: false,
    redirectOnUnauthorized: false,
  });
  const { switchToUser } = useUserContext();
  const navigate = useNavigate();

  useOnMount(async () => {
    try {
      const { user_group, participant_id, token: userToken } = await client
        .post("accept_invite", {
          token,
          respondent_id,
        })
        .get("data");
      switchToUser(userToken, user_group, participant_id);
      setTimeout(() => {
        navigate("/");
      }, 0);
    } catch (ex) {
      const code = ex?.response?.data?.code;

      if (code === "AlreadySubmitted") {
        setError(
          <Alert color="info">
            <Trans>You have already completed your diary</Trans>
          </Alert>
        );
      } else {
        setError(
          <Alert color="danger" className="mb-0">
            <Trans>
              There has been a problem - please report this to myfood24
            </Trans>
          </Alert>
        );
      }
    }
  });

  return (
    <Page>
      {error || (
        <>
          <FontAwesomeIcon icon={faSpinner} spin /> Please wait...
        </>
      )}
    </Page>
  );
};

export default AcceptInvite;
