import React from "react";
import { SubmitButton } from "components/common";
import { useFormik, FormikProvider } from "formik";
import { ModalBody } from "reactstrap";
import { useModalContext, useParticipantClient } from "hooks";
import { useParams, useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { Trans, useTranslation } from "react-i18next";
import { ModalHeader } from "./components/ModalHeader";

export const DeleteRecipe = () => {
  const { id } = useParams();
  const formik = useFormik({
    onSubmit,
    initialValues: {},
  });
  const client = useParticipantClient();
  const { toggle } = useModalContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function onSubmit() {
    await client.delete(`recipes/${id}`).get("data");
    NotificationManager.success(t("The recipe has been successfully deleted!"));
    navigate("../../my_recipes", { replace: true });
  }

  return (
    <>
      <ModalHeader toggle={toggle}>
        <Trans>Delete Recipe</Trans>
      </ModalHeader>
      <ModalBody className="text-center">
        <div className="mb-4">
          <Trans>
            Are you sure you wish to delete this recipe? This operation is not
            reversible.
          </Trans>
        </div>
        <FormikProvider value={formik}>
          <SubmitButton color="primary">
            <Trans>Delete Recipe</Trans>
          </SubmitButton>
        </FormikProvider>
      </ModalBody>
    </>
  );
};

export default DeleteRecipe;
