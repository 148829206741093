import { SimpleContainer } from "./SimpleContainer";
import { Card } from "reactstrap";
import React from "react";
import { Trans } from "react-i18next";

export const NotFound = () => {
  return (
    <SimpleContainer>
      <Card body>
        <div className="text-center">
          <Trans>The page you have requested is not available.</Trans>
        </div>
      </Card>
    </SimpleContainer>
  );
};
