import React, { useState, useMemo, useEffect, useRef } from "react";
import { Field, useFormik, FormikProvider } from "formik";
import { debounce, some } from "lodash";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useParticipantClient } from "hooks";
import { Select } from "components/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useDiaryContext } from "./DiaryContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { Trans, useTranslation } from "react-i18next";
import { CardHeader } from "./components/CardHeader";
import { FoodCardBody } from "./components/FoodCardBody";
import classNames from "classnames";

const DebouncedInput = ({ onChange, value: valueProp, innerRef, ...props }) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const onDebounced = useMemo(() => debounce(onChange, 1000), [onChange]);

  return (
    <input
      value={value}
      onChange={(e) => {
        e.persist();
        setValue(e.target.value);
        onDebounced(e);
      }}
      ref={innerRef}
      {...props}
    />
  );
};

export const Search = () => {
  const { search, setSearch, popListItem } = useDiaryContext();
  const formik = useFormik({
    initialValues: {
      query: search || "",
    },
    onSubmit,
  });
  const limit = 25;
  const client = useParticipantClient();
  const { fromMakeAList: _fromMakeAList } = useLocation().state ?? {};
  const [fromMakeAList, setFromMakeAList] = useState(!!_fromMakeAList);

  const { values, submitForm, isSubmitting, setFieldValue } = formik;
  const { query } = values;
  const [count, setCount] = useState();
  const [offset, setOffset] = useState(0);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [results, setResults] = useState();
  const searchRef = useRef();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const firstItemGeneric = results?.[0]?.is_generic_item;

  let hasGenericSwitched = false;

  const hasMore = useMemo(() => {
    return count - offset > 0;
  }, [offset, count]);

  async function onFetchNext() {
    const { food_category, food_brand } = values;
    const nextOffset = offset + limit;

    const [{ categories, brands }, { count, results }] = await Promise.all([
      client
        .get("food_items/categories_and_brands", {
          params: {
            query,
            food_category__name: food_category
              ? food_category.value
              : undefined,
            food_brand__name: food_brand ? food_brand.value : undefined,
          },
        })
        .get("data"),
      client
        .get("food_items", {
          params: {
            query,
            food_category__name: food_category
              ? food_category.value
              : undefined,
            food_brand__name: food_brand ? food_brand.value : undefined,
            limit,
            offset: nextOffset,
          },
        })
        .get("data"),
    ]);

    if (food_category && !some(categories, { value: food_category.value })) {
      setFieldValue("food_category", null);
    }

    if (food_brand && !some(brands, { value: food_brand.value })) {
      setFieldValue("food_brand", null);
    }

    setCategories(categories);
    setBrands(brands);
    setResults((v) => [...v, ...results]);
    setCount(count);
    setOffset(nextOffset);
  }

  useEffect(() => {
    if (fromMakeAList) {
      const search = popListItem();

      if (search) {
        setFieldValue("query", search);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromMakeAList]);

  async function onSubmit({ query, food_category, food_brand }) {
    setSearch(query);

    const [{ categories, brands }, { count, results }] = await Promise.all([
      client
        .get("food_items/categories_and_brands", {
          params: {
            query,
            food_category__name: food_category
              ? food_category.value
              : undefined,
            food_brand__name: food_brand ? food_brand.value : undefined,
          },
        })
        .get("data"),
      client
        .get("food_items", {
          params: {
            query,
            food_category__name: food_category
              ? food_category.value
              : undefined,
            food_brand__name: food_brand ? food_brand.value : undefined,
            limit,
            offset: 0,
          },
        })
        .get("data"),
    ]);

    if (food_category && !some(categories, { value: food_category.value })) {
      setFieldValue("food_category", null);
    }

    if (food_brand && !some(brands, { value: food_brand.value })) {
      setFieldValue("food_brand", null);
    }

    setOffset(0);
    setCount(count);
    setCategories(categories);
    setBrands(brands);
    setResults(results);
  }

  useEffect(() => {
    if (query.length < 2) return;

    (async () => {
      await submitForm();
    })();
  }, [query, submitForm]);

  useEffect(() => {
    searchRef.current.focus();
  }, [searchRef]);

  return (
    <div>
      <FormikProvider value={formik}>
        <div className="mt-2">
          <div className="relative">
            <Field
              autoComplete="off"
              innerRef={searchRef}
              onChange={(e) => {
                const { name, value } = e.target;
                setFieldValue(name, value);
                setFromMakeAList(false);
              }}
              placeholder={`${t("Search")}...`}
              as={DebouncedInput}
              name="query"
              className="form-control form-control-lg pr-3"
            />
            {isSubmitting ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin
                className={classNames(
                  "absolute inset-y-0 h-full opacity-50",
                  i18n.dir() === "rtl" ? "left-0 ml-3" : "right-0 mr-3"
                )}
                size="lg"
              />
            ) : null}
          </div>
        </div>
        <Row form className="mt-3">
          <Col md className="mb-2 md:md-0">
            <Select
              isClearable
              onChange={submitForm}
              options={categories}
              placeholder={t("Category")}
              name="food_category"
            />
          </Col>
          <Col md>
            <Select
              isClearable
              onChange={submitForm}
              options={brands}
              placeholder={t("Brand")}
              name="food_brand"
            />
          </Col>
        </Row>
      </FormikProvider>
      {results ? (
        <div>
          <div className="text-center my-4">
            <Trans>
              <strong>{{ num: count }} item/s</strong> matching your search
            </Trans>
          </div>
          <Card className="border-brand-light">
            <InfiniteScroll
              style={{ overflowX: "hidden" }}
              height={650}
              dataLength={results.length}
              next={onFetchNext}
              hasMore={hasMore}
              loader={
                <CardBody>
                  <FontAwesomeIcon spin icon={faSpinner} className="mr-1" />{" "}
                  <Trans>Loading</Trans>...
                </CardBody>
              }
            >
              {results.map((i, idx) => (
                <React.Fragment key={i.id}>
                  {idx === 0 ? (
                    <CardHeader size="xs">
                      {firstItemGeneric ? t("Non-Branded") : t("Branded")}
                    </CardHeader>
                  ) : !hasGenericSwitched &&
                    i.is_generic_item !== firstItemGeneric ? (
                    <>
                      {(hasGenericSwitched = true)}
                      <CardHeader size="xs">
                        {!firstItemGeneric ? t("Non-Branded") : t("Branded")}
                      </CardHeader>
                    </>
                  ) : null}
                  <FoodCardBody
                    idx={idx}
                    description={i.description}
                    brand={i.food_brand__name}
                    onClick={() => {
                      navigate(`../../food_entry/new?food_item=${i.id}`, {
                        state: { fromMakeAList },
                      });
                    }}
                  />
                </React.Fragment>
              ))}
            </InfiniteScroll>
          </Card>
        </div>
      ) : null}
    </div>
  );
};

export default Search;
