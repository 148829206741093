import React from "react";
import { Page, FormGroup, SubmitButton, Select } from "components/common";
import { Field, useFormik, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { validateEmail, validateInt } from "services/validators";
import { Trans } from "react-i18next";

const GENDERS = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
];

export const Edit = () => {
  const formik = useFormik({
    onSubmit,
  });
  const { resetForm, values, setFieldError } = formik;
  const { id } = useParams();
  const navigate = useNavigate();
  const client = useClient();
  const url = "participants/accepted";
  const title = `Edit Participant`;

  async function onSubmit(x) {
    try {
      const { project } = await client.put(`${url}/${id}`, x).get("data");
      navigate(`../../../projects/${project}?tab=participants`);
    } catch (ex) {
      const errors = ex?.response?.data?.validation_errors;
      let handled = false;

      if (errors?.email) {
        setFieldError("email", "A user already exists with this email address");
        handled = true;
      }
      if (errors?.username) {
        setFieldError("username", "A user already exists with this username");
        handled = true;
      }

      if (!handled) {
        throw ex;
      }
    }

    NotificationManager.success("Participant successfully saved");
  }

  useAsyncEffect(async () => {
    const [values] = await Promise.all([
      client.get(`${url}/${id}`).get("data"),
    ]);
    resetForm({ values });
  }, []);

  if (!values) return null;

  return (
    <Page title={title}>
      <FormikProvider value={formik}>
        <FormGroup label="First name" required name="first_name">
          <Field
            name="first_name"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Last name" required name="last_name">
          <Field
            name="last_name"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Username" required name="username" showError>
          <Field
            name="username"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Email address" required name="email" showError>
          <Field
            name="email"
            validate={validateEmail({
              requiredError: true,
              invalidError: true,
            })}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Age" name="age">
          <Field
            name="age"
            validate={validateInt({ required: false })}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Gender" name="gender">
          <Select name="gender" options={GENDERS} simpleValue />
        </FormGroup>

        <div className="flex justify-end">
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};

export default Edit;
