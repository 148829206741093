import React from "react";
import { Trans } from "react-i18next";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { useTable } from "hooks";
import { Page } from "components/common";
import { APPS_URL } from "./api";

export const AppList = () => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
  } = useTable({
    url: APPS_URL,
    resource: "App",
  });

  return (
    <Page title={<Trans>Apps</Trans>} fluid>
      <TableSearch />
      <div className="flex justify-between mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th select />
              <th sortBy="name">
                <Trans>Name</Trans>
              </th>
              <th className="text-right">
                <Trans>Active keys</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${i.id}`}>{i.name}</Link>
                </td>
                <td className="text-right">{i.api_keys.length}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};
