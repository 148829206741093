import { FormGroup } from "../../../components/common";
import React from "react";
import YesNoField from "./YesNoField";
import { useTranslation } from "react-i18next";

export const ParticipantUniqueIdentifier = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FormGroup
        label={t("Show participant unique identifier? (if redirecting after the diary)")}
        translateLabel={false}
        required
      >
        <YesNoField name="show_participant_unique_identifier" />
      </FormGroup>
    </div>
  );
};
