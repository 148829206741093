import React, { useState, useMemo } from "react";
import LoginBase from "../components/LoginBase";
import Title from "../components/Title";
import { useUserContext, useOnMount, useEventCallback } from "hooks";
import { find, chain, uniqueId } from "lodash";
import {
  FormGroup,
  SubmitButton,
  DatePicker,
  Select,
  Form,
} from "components/common";
import { useFormik, FormikProvider, Field } from "formik";
import { createClient } from "hooks/useClient";
import TermsLinks from "components/TermsLinks";
import {
  useNavigate,
  useLocation,
  Link,
  Navigate,
  useParams,
} from "react-router-dom";
import { formatDate } from "services/formatters";
import moment from "moment";
import { Card } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { SubTitle } from "../components/SubTitle";
import { DateInput } from "../../../components/common/DatePicker";

const NEW_SINGLE = 0;
const EXISTING_SINGLE = 1;
const NEW_MULTIPLE_DATE = 2;
const EXISTING_MULTIPLE_DATE = 3;
const MULTIPLE_NAME = 4;

export const Login = () => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      password: "",
      diary_type: null,
      date_consumed: null,
    },
    validate: ({ date_consumed }) => {
      const errors = {};

      if (date_consumed) {
        const m = moment(date_consumed);
        if (m.isBefore(start_date)) {
          errors.date_consumed =
            t("This project does not start until ") + formatDate(start_date);
        }
        if (m.isAfter(end_date)) {
          errors.date_consumed =
            t("This project ended on ") + formatDate(end_date);
        }
      }
      return errors;
    },
    onSubmit,
  });
  const [participants, setParticipants] = useState([]);
  const { locale } = useParams();
  const navigate = useNavigate();
  const { switchToUser, getToken } = useUserContext();
  const { project, token, username } = useLocation().state ?? {};

  useOnMount(async () => {
    if (!project) return;

    const client = createClient();
    const participants = await client
      .post("teaching_login/participants", {
        username,
        project: project.id,
      })
      .get("data");
    setParticipants(participants);
  });

  const { setFieldError, values } = formik;
  const { diary_type } = values;
  const {
    project_stages,
    is_single_project,
    is_multiple_project,
    start_date,
    end_date,
  } = project ?? {};
  const has_project_stages = !!project_stages?.length;
  const has_participants = !!participants?.length;

  const type = useMemo(() => {
    if (is_single_project && !has_participants) {
      return NEW_SINGLE;
    } else if (is_single_project && has_participants) {
      return EXISTING_SINGLE;
    } else if (is_multiple_project && !has_project_stages) {
      return has_participants ? EXISTING_MULTIPLE_DATE : NEW_MULTIPLE_DATE;
    } else if (is_multiple_project && has_project_stages) {
      return MULTIPLE_NAME;
    }
  }, [
    is_single_project,
    is_multiple_project,
    has_project_stages,
    has_participants,
  ]);

  const participantLabel = useEventCallback((i) => {
    let projectStage = null;

    if (i.project_stage) {
      projectStage = find(project_stages, { id: i.project_stage });
    }

    return projectStage
      ? `${projectStage.name} ${
          i.is_diary_submitted ? t("(Submitted)") : t("(Started)")
        }`
      : `${formatDate(i.participant_date)} ${
          i.is_diary_submitted ? t("(Submitted)") : t("(Started)")
        }`;
  });

  const diaryTypeOptions = useMemo(() => {
    if (
      type === NEW_SINGLE ||
      type === EXISTING_SINGLE ||
      type === NEW_MULTIPLE_DATE
    )
      return null;
    if (type === EXISTING_MULTIPLE_DATE) {
      return [
        {
          label: t("Record a new diary"),
          value: uniqueId(),
        },
        ...participants.map((i) => ({
          label: participantLabel(i),
          participant: i.id,
          value: uniqueId(),
        })),
      ];
    }
    if (type === MULTIPLE_NAME) {
      const unusedProjectStages = chain(participants)
        .map("project_stage")
        .compact()
        .uniq()
        .map((i) => [i, true])
        .fromPairs()
        .thru((ids) => project_stages.filter((j) => !ids[j.id]))
        .value();

      return [
        ...participants.map((i) => ({
          label: participantLabel(i),
          participant: i.id,
          value: uniqueId(),
        })),
        ...unusedProjectStages.map((i) => ({
          label: `Record new ${i.name} diary`,
          project_stage: i.id,
          value: uniqueId(),
        })),
      ];
    }
  }, [type, participants, project_stages, participantLabel, t]);

  const showConsumed = useMemo(() => {
    if (type === EXISTING_SINGLE) return false;
    if (type === NEW_SINGLE || type === NEW_MULTIPLE_DATE) return true;
    if (!diary_type) return false;
    return !diary_type.participant;
  }, [type, diary_type]);

  async function onSubmit({ password, date_consumed, diary_type }) {
    let _token = token;
    let participant = diary_type?.participant;

    if (type === EXISTING_SINGLE) {
      participant = participants[0]?.id;
    }

    if (!_token) {
      try {
        _token = await getToken(username, password);
      } catch (ex) {
        const status = ex?.response?.status;

        if (status === 400) {
          setFieldError("password", t("Invalid username or password"));
          return;
        } else {
          throw ex;
        }
      }
    }

    if (participant) {
      switchToUser(_token, "participant", participant);
    } else {
      const client = createClient({ token: _token });
      const { participant } = await client
        .post("teaching_login/setup_participant", {
          date_consumed,
          project_stage: diary_type?.project_stage ?? null,
          project: project.id,
        })
        .get("data");
      switchToUser(t, "participant", participant);
    }

    navigate(`/${locale}/participant`);
  }

  if (!project) {
    return <Navigate to="../start" replace />;
  }

  const projectName = project.name;

  return (
    <LoginBase
      footer={<TermsLinks className="text-center mt-4" />}
      leftSide={
        <>
          <Title className="mb-4">myfood24 teaching</Title>
          <SubTitle className="prose prose-sm">
            <p>
              <Trans>
                Before accessing <strong>{{ projectName }}</strong> please enter
                your password and select a diary type.
              </Trans>
            </p>
            <p>
              <Link
                to={`/${locale}/reset_password_request`}
                className="text-white underline"
              >
                <Trans>Forgot Password</Trans>
              </Link>
            </p>
          </SubTitle>
        </>
      }
      rightSide={
        <Card body>
          <FormikProvider value={formik}>
            <Form>
              {!token ? (
                <FormGroup label="Password" name="password" showError>
                  <Field
                    type="password"
                    name="password"
                    validate={(v) => !v}
                    className="form-control"
                  />
                </FormGroup>
              ) : null}
              {diaryTypeOptions ? (
                <FormGroup label="Diary type" name="diary_type">
                  <Select
                    name="diary_type"
                    options={diaryTypeOptions}
                    validate={(v) => !v}
                  />
                </FormGroup>
              ) : null}
              {showConsumed ? (
                <FormGroup
                  label="Date food consumed"
                  name="date_consumed"
                  showError
                >
                  <DatePicker
                    name="date_consumed"
                    validate={(v) => !v}
                    className="form-control"
                    customInput={<DateInput />}
                  />
                </FormGroup>
              ) : null}
              <div className="flex justify-end">
                <SubmitButton type="submit">
                  <Trans>Next</Trans>
                </SubmitButton>
              </div>
            </Form>
          </FormikProvider>
        </Card>
      }
    />
  );
};

export default Login;
