import React, { useState, useMemo } from "react";
import { ChangeReportSettingsButton } from "components/Reports/components";
import CalorieConsumption from "components/Reports/CalorieConsumption";
import { useAsyncEffect, useClient } from "hooks";
import {useRecoilValue, useSetRecoilState} from "recoil";
import { reportingStaticSelector } from "../../selectors";
import { Row, Col, Alert } from "reactstrap";
import { Page, Loading } from "components/common";
import { useParams } from "react-router";
import { mapValues } from "lodash";
import GenericAnalysis from "components/Reports/GenericAnalysis";
import DiaryStageAnalysis from "components/Reports/DiaryStageAnalysis";
import DiaryStageIndividual from "components/Reports/DiaryStageIndividual";
import ParticipantAnalysis from "components/Reports/ParticipantAnalysis";
import DiaryDateIndividual from "components/Reports/DiaryDateIndividual";
import { useTranslation } from "react-i18next";
import {TopContributorsReport} from "../../components/Reports/TopContributorsReport";
import {MacronutrientReport} from "../../components/Reports/MacronutrientReport";
import {SustainabilityReport} from "../../components/Reports/SustainabilityReport";
import {activeProjectAtom} from "../../atoms";

const ageLabels = { unknown: "Unknown" };
const genderLabels = { unknown: "Unknown", male: "Male", female: "Female" };

export const Reports = () => {
  const [reportNutrients, setReportNutrients] = useState([]);
  const [reports, setReports] = useState();
  const [project, setProject] = useState();
  const [error, setError] = useState();
  const { id } = useParams();
  const setActiveProject = useSetRecoilState(activeProjectAtom);
  const { nutrientGroups, meals: _meals } = useRecoilValue(
    reportingStaticSelector
  );
  const client = useClient();
  const { t } = useTranslation();
  const baseUrl = `projects/${id}`;

  const meals = useMemo(() => mapValues(_meals, (x) => t(x)), [_meals, t]);

  useAsyncEffect(async () => {
    const project = await client.get(baseUrl).get("data");
    setActiveProject(project);
    setProject(project);
  }, [baseUrl]);

  useAsyncEffect(async () => {
    let keys = reportNutrients.reduce((acc, i) => {
      return [...acc, ...i.value.split("|")];
    }, []);

    if (!keys.length) keys = null;

    setReports(null);

    const baseUrl = `projects/${id}`;
    const url = `${baseUrl}/reports`;

    try {
      const [reports] = await Promise.all([
        client
          .post(url, {
            keys,
          })
          .get("data"),
      ]);
      setReports(reports);
    } catch (ex) {
      const code = ex?.response?.data?.code;

      if (code === "NoData") {
        setError(
          <Alert color="info">
            There is no data to report on for this project.
          </Alert>
        );
      } else {
        throw ex;
      }
    }
  }, [reportNutrients, id]);

  if (!reports || !project)
    return <Page title="Reports">{error || <Loading />}</Page>;

  const {
    num_participants,
    project_stages,
    nutrient_intake,
    project_stage_intake,
    age_intake,
    gender_intake,
    date_analysis,
    meal_intake,
    project_stage_individual,
    participant_analysis,
    dates,
    macronutrient_analysis,
    sustainability_analysis
  } = reports;
  const { title } = project;

  const averageTitle = (name) =>
    `${name} - Average (${num_participants} participants)`;

  return (
    <div className="max-w-screen-xl mx-auto">
      <div className="flex justify-center my-4">
        <ChangeReportSettingsButton
          reportNutrients={reportNutrients}
          setReportNutrients={setReportNutrients}
          nutrientGroups={nutrientGroups}
        />
      </div>
      <CalorieConsumption title={averageTitle(title)} data={nutrient_intake} />
      <TopContributorsReport url={`${baseUrl}/top_contributors`} client={client} />
      <Row className="items-stretch" form>
        <Col className="flex">
          <MacronutrientReport title={averageTitle('Macronutrient Analysis')} data={macronutrient_analysis} diaries={num_participants} />
        </Col>
        <Col className="flex">
          <GenericAnalysis
            title={"Meal Analysis"}
            labels={meals}
            data={meal_intake}
          />
        </Col>
      </Row>
      <DiaryStageAnalysis
        title={"Diary Name Analysis"}
        data={project_stage_intake}
      />
      {project_stage_individual ? (
        <DiaryStageIndividual
          title={`Diary Name Breakdown Analysis - Individual`}
          data={project_stage_individual}
          projectStages={project_stages}
        />
      ) : null}
      <ParticipantAnalysis
        title="Participant Analysis - Individual Diaries"
        data={participant_analysis}
      />
      {date_analysis ? (
        <DiaryDateIndividual
          title="Diary Date Analysis - Individual"
          dates={dates}
          data={date_analysis}
        />
      ) : null}
      <Row form>
        <Col lg>
          <GenericAnalysis
            title={"Age Analysis"}
            labels={ageLabels}
            data={age_intake}
          />
        </Col>
        <Col lg>
          <GenericAnalysis
            title={"Gender Analysis"}
            labels={genderLabels}
            data={gender_intake}
          />
        </Col>
      </Row>
      <SustainabilityReport data={sustainability_analysis} title={t('Sustainability Analysis')} />
    </div>
  );
};

export default Reports;
