import {useController} from "react-hook-form";

export const Input = ({ name, control, defaultValue, onFocus, rules, as: Component = 'input', ...props }) => {
  const { field } = useController({
    name,
    control,
    defaultValue,
    onFocus,
    rules,
  });

  return <Component {...field} {...props} />

}