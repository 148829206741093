import {Col, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import numeral from 'numeral';
import {Pie, PieChart} from "recharts";
import React from "react";
import ghgeImage from '../../Pages/Participant/assets/ghge.png';
import wateruseImage from '../../Pages/Participant/assets/wateruse.png';
import landuseImage from '../../Pages/Participant/assets/landuse.png';
import {ReportContainer} from "./components";

const Graph = ({ value: valueProp, alt, color, title, unit, image, mediumImpact, highImpact }) => {
  const value = numeral(valueProp).format('0,0');

  const getImpact = () => {
    let fill = '#75b853';
    let title = 'Low impact';

    if (valueProp >= highImpact) {
      fill = '#ff1616';
      title = 'High impact';
    } else if (valueProp >= mediumImpact) {
      fill = '#ff914d';
      title = 'Medium impact';
    }

    return [fill, title];
  }
  const [impactFill, impactTitle] = getImpact();

  return (
    <div className="text-center" style={{ color }}>
      <h4 className="font-semibold text-lg" style={{ color }}>
        {title}
      </h4>
      <div className="flex justify-center relative">
        <img alt={alt} src={image} style={{ top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%, -55%)', maxWidth: 90, maxHeight: 90}} />
        <PieChart width={150} height={150}>
          <Pie
            startAngle={225}
            endAngle={-45}
            data={[{ value: 50, fill: impactFill }]}
            dataKey="value"
            innerRadius="85%"
            outerRadius="100%"
          >
          </Pie>
        </PieChart>
      </div>
      <p className="-mt-6">
        <span style={{ color: impactFill }} className="block font-semibold">
          {impactTitle}
        </span>
        <span style={{ color: impactFill }} className="font-bold text-2xl block leading-none">
          {value}
        </span>
        <span className="text-sm font-semibold block">
          {unit}
        </span>
      </p>
    </div>
  )
}

export const SustainabilityReport = ({ data, title }) => {
  const { t } = useTranslation();
  const { ghge, landuse, wateruse } = data;

  if (ghge === 0 && landuse === 0 && wateruse === 0) return null;

  return (
    <ReportContainer title={title}>
      <Row className="space-y-4 lg:space-y-0">
        <Col lg>
          <Graph mediumImpact={4.75} highImpact={6.65} image={ghgeImage} value={ghge} color="#a6a6a6" unit="kg CO₂ eq" title={t('Greenhouse gases')} />
        </Col>
        <Col lg>
          <Graph mediumImpact={4.32} highImpact={6.15} image={landuseImage} value={landuse} color="#00a651" unit="m²/year" title={t('Land use')} />
        </Col>
        <Col lg>
          <Graph mediumImpact={452} highImpact={659} image={wateruseImage} value={wateruse} color="#56aeff" unit="Litres eq" title={t('Water use')} />
        </Col>
      </Row>
    </ReportContainer>
  )
}