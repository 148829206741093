import React, { useContext } from "react";
import classNames from "classnames/dedupe";
import { FormikContext, isString } from "formik";
import { useTranslation } from "react-i18next";

export const FormGroup = ({
  label,
  name,
  children,
  className,
  helpText,
  required,
  showError,
  labelProps,
  getLabelProps = (v) => v,
  translateLabel = true,
  ...props
}) => {
  const { getFieldMeta, submitCount } = useContext(FormikContext) ?? {};
  const { t } = useTranslation();

  let error = undefined;

  if (name && getFieldMeta) {
    const meta = getFieldMeta(name);
    error = (meta.touched || submitCount > 0) && meta.error;
  }

  return (
    <div
      className={classNames(
        "form-group",
        {
          "has-error": error,
        },
        className
      )}
      {...props}
    >
      {label ? (
        <label
          {...getLabelProps({
            className: "form-group__label",
          })}
        >
          {translateLabel ? t(label) : label}{" "}
          {required ? <span className="text-red-500">*</span> : null}
        </label>
      ) : null}
      {children}
      {helpText ? (
        <div className="mt-1 opacity-75 text-sm">{helpText}</div>
      ) : null}
      {showError && isString(error) ? (
        <div className="mt-1 text-sm text-red-500">{error}</div>
      ) : null}
    </div>
  );
};

export default FormGroup;
