import React, { useMemo, useEffect, useState } from "react";
import { keys, reduce } from "lodash";
import { ReportContainer, ReportTabs } from "./components";
import { Pie } from "react-chartjs-2";
import { Legend } from "./Legend";

export const GenericAnalysis = ({
  data: dataProp,
  title,
  labels: labelsProp,
}) => {
  const [active, setActive] = useState();
  const [chartRef, setChartRef] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setActive(null);
    setOptions([]);
    if (!dataProp) return;
    const options = keys(dataProp);
    const active = options[0];
    setOptions(options);
    setActive(active);
  }, [dataProp]);

  const { data, labels } = useMemo(() => {
    if (!active || !dataProp) return { data: null, labels: null };

    return reduce(
      dataProp[active],
      (acc, i, label) => {
        acc.labels.push(labelsProp?.[label] ?? label);
        acc.data.push((i ?? 0).toFixed(2));
        return acc;
      },
      { labels: [], data: [] }
    );
  }, [active, dataProp, labelsProp]);

  if (!data) return <ReportContainer title={title} loading />;

  const renderData = () => {
    return {
      datasets: [
        {
          data,
          backgroundColor: [
            "#5790c7",
            "#E08435",
            "#ec4785",
            "#B6EF6C",
            "#732576",
          ],
        },
      ],
      labels,
    };
  };

  return (
    <ReportContainer title={title}>
      <ReportTabs options={options} active={active} setActive={setActive} />
      <Legend chartRef={chartRef} />
      <Pie
        ref={(ref) => setChartRef(ref)}
        data={renderData}
        options={{
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 10,
              bottom: 10,
            },
          },
          legend: {
            display: false,
          },
          plugins: {
            labels: [
              {
                render: "percentage",
                position: "outside",
                outsidePadding: 4,
                textMargin: 9,
                showZero: false,
                arc: true,
              },
            ],
          },
        }}
      />
    </ReportContainer>
  );
};

export default GenericAnalysis;
