import {useQuery} from "react-query";
import {useNutrientOptions} from "./components/ChangeReportSettingsButton";
import React, {memo, useState} from "react";
import {ReportContainer} from "./components";
import {Trans, useTranslation} from "react-i18next";
import Select from "react-select";
import classNames from "classnames";
import {formatNumber} from "../../healthcare/util";
import {useRecoilValue} from "recoil";
import {reportingStaticSelector} from "../../selectors";

const TopContributor = memo(({ value, referenceIntake, unit }) => {
  const { food_item, average } = value;
  const { description } = food_item;
  const percentageOfReference = Math.round((average / referenceIntake) * 100);

  return (
    <div className="w-full md:w-1/2 lg:1/3 px-1 mb-2">
      <div
        className={classNames(
          "shadow-lg h-full p-4 text-sm rounded-md border border-gray-300 w-full"
        )}
      >
        <div className="font-semibold">{description}</div>
        <div className="flex justify-between border-t border-gray-400 mt-4 pt-2 ">
          <div className="text-b">
            {formatNumber(average)}
            {referenceIntake ? (
              <span className="text-gray-700">
                /{formatNumber(referenceIntake)} {unit}
              </span>
            ) : null}
          </div>
          {!isNaN(percentageOfReference) ? (
            <div className="text-gray-700 ml-2">
              <span>{percentageOfReference}%</span>{' '}
              <Trans>of reference</Trans>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
});

export const TopContributorsReport = ({ gender, url, client }) => {
  const { nutrientGroups, fieldUnits, maleReferenceIntake, femaleReferenceIntake } = useRecoilValue(reportingStaticSelector);

  const { t } = useTranslation();
  const [selected, setSelected] = useState({
    label: t('Calories'),
    value: 'calories'
  });
  const key = selected.value;
  const unit = fieldUnits[key] || 'g';
  const referenceIntake = gender === 'male' ? maleReferenceIntake[key] : femaleReferenceIntake[key];
  const nutrientOptions = useNutrientOptions({ nutrientGroups, includeAll: false })

  const queryKey = [url, {
    key
  }];
  const { data, isFetching } = useQuery(queryKey, () => client.post(...queryKey).get('data'), {
    useErrorBoundary: false,
    keepPreviousData: true,
  });

  if (!data) return null;

  return (
    <ReportContainer title={t('Top Contributors')}>
      <div className="mb-4">
        <Select
          isLoading={isFetching}
          options={nutrientOptions}
          value={selected}
          onChange={(v) => {
            setSelected(v);
          }}
        />
      </div>
      <div className="flex flex-wrap -mx-1">
        {data.map((i, idx) => (
          <TopContributor value={i} key={idx} unit={unit} referenceIntake={referenceIntake} />
        ))}
      </div>
    </ReportContainer>
  );
}