import React from "react";
import logo from "../../assets/login_logo.png";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

export const LoginBase = ({ leftSide, rightSide, footer, children }) => {
  return (
    <div className="min-h-screen h-full flex items-center justify-center bg-brand-primary py-12 px-4 sm:px-6 lg:px-8 overflow-hidden">
      <div className="max-w-screen-md w-full">
        <div className="mb-12 mt-8">
          <Link to="/" className="block">
            <img className="mx-auto max-w-full mb-2" src={logo} alt="Logo" />
          </Link>
        </div>
        <Row>
          {leftSide ? (
            <Col lg>
              {leftSide}
              {rightSide ? <hr className="lg:hidden" /> : null}
            </Col>
          ) : null}
          {rightSide ? <Col lg>{rightSide}</Col> : null}
        </Row>
        {children}
        {footer}
      </div>
    </div>
  );
};

export default LoginBase;
