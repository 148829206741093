import React from "react";
import { SideNavItem } from "./SideNavItem";
import {
  faUsers,
  faSlidersH,
  faSignOutAlt,
  faListAlt,
  faQuestionCircle,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { healthcareTokenAtom } from "../../atoms";
import { useResetRecoilState } from "recoil";
import { useUserContext } from "../hooks";

export const SideNav = () => {
  const { t } = useTranslation();
  const resetToken = useResetRecoilState(healthcareTokenAtom);
  const { user } = useUserContext();
  const isPatient = user.is_patient;
  const isHCPUser = user.is_hcp_user;
  return (
    <div className="sidenav">
      <ul>
        <li>
          {isPatient && !isHCPUser && (
            <SideNavItem title={t("Diary")} icon={faUtensils} to="food" />
          )}
        </li>
        <li>
          {isHCPUser && (
            <SideNavItem title={t("Patients")} icon={faUsers} to="patients" />
          )}
        </li>
        <li>
          {isHCPUser && (
            <SideNavItem
              title={t("Nutritional Analysis")}
              icon={faListAlt}
              to="meal_plans"
            />
          )}
        </li>
        <li>
          {isHCPUser && (
            <SideNavItem
              title={t("Settings")}
              icon={faSlidersH}
              to="settings"
            />
          )}
        </li>
        <li>
          {isHCPUser && (
            <SideNavItem title={t("Help")} icon={faQuestionCircle} to="help" />
          )}
        </li>
        <li>
          <SideNavItem
            title={t("Log out")}
            icon={faSignOutAlt}
            to="logout"
            onClick={(e) => {
              e.preventDefault();
              resetToken();
            }}
          />
        </li>
      </ul>
    </div>
  );
};
