import React, { useMemo, useEffect, useState } from "react";
import { keys, reduce } from "lodash";
import { ReportContainer, ReportTabs } from "./components";
import { Line } from "react-chartjs-2";
import { Trans } from "react-i18next";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "../../selectors";

export const DiaryDateAnalysis = ({
  data: dataProp,
  dates,
  title = <Trans>Diary date analysis</Trans>,
}) => {
  const [active, setActive] = useState();
  const [options, setOptions] = useState([]);
  const { fieldUnits } = useRecoilValue(reportingStaticSelector);

  useEffect(() => {
    setActive(null);
    setOptions([]);
    const options = keys(dataProp);
    const active = options[0];
    setOptions(options);
    setActive(active);
  }, [dataProp]);

  const { data, labels } = useMemo(() => {
    if (!active || !dataProp) return { data: null, labels: null };

    return reduce(
      dates,
      (acc, i) => {
        acc.labels.push(i);

        const value = dataProp[active][i];
        acc.data.push(value ? value.toFixed(2) : null);
        return acc;
      },
      { labels: [], data: [] }
    );
  }, [active, dataProp, dates]);

  if (!data) return <ReportContainer title={title} loading />;

  const renderData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 600);
    gradient.addColorStop(0, "#28B1BF");
    gradient.addColorStop(1, "#5991C5");

    return {
      datasets: [
        {
          label: "",
          lineTension: 0,
          fill: false,
          data,
          borderColor: gradient,
          backgroundColor: gradient,
          pointBackgroundColor: gradient,
          pointBorderColor: gradient,
          pointHoverBackgroundColor: gradient,
          pointHoverBorderColor: gradient,
        },
      ],
      labels,
    };
  };

  return (
    <ReportContainer title={title}>
      <ReportTabs options={options} active={active} setActive={setActive} />
      <Line
        data={renderData}
        options={{
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: (value) => {
                    return `${value} ${fieldUnits[active] ?? "g"}`;
                  },
                  suggestedMin: 0,
                },
              },
            ],
          },
        }}
      />
    </ReportContainer>
  );
};

export default DiaryDateAnalysis;
