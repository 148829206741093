import React, { useLayoutEffect, useRef } from "react";
import { pickBy } from "lodash";
import { useOnMount } from "hooks";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import { useRecoilState } from "recoil";
import { listFiltersAtom } from "atoms";

const OnFormikChange = ({ onChange }) => {
  const formik = useFormikContext();
  const { values, isValid } = formik;
  const mounted = useRef();

  useLayoutEffect(() => {
    if (mounted.current) {
      onChange(formik);
    }
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isValid]);

  return null;
};

export const FiltersForm = ({
  initialValues,
  onChange,
  children,
  persistKey,
}) => {
  const [_initialValues, setInitialValues] = useRecoilState(
    listFiltersAtom(persistKey)
  );

  const formik = useFormik({
    initialValues: _initialValues ?? initialValues,
  });

  useOnMount(() => {
    onChange(pickBy(formik.values));
  });

  return (
    <FormikProvider value={formik}>
      <OnFormikChange
        onChange={({ values, isValid }) => {
          if (isValid) {
            if (persistKey) {
              setInitialValues(values);
            }
            onChange(pickBy(values));
          }
        }}
      />
      {children}
    </FormikProvider>
  );
};

export default FiltersForm;
