import { Page } from "../../components/common";
import { useNavigate, useParams } from "react-router-dom";
import { useApp, useAppSave, useFoodDatabases } from "./api";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { Alert, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Trans, useTranslation } from "react-i18next";
import { LANGUAGES } from "../../Pages/Projects/components/ProjectFields";
import { NotificationManager } from "react-notifications";
import { AppAPIKeysView } from "./AppAPIKeysView";
import { SubmitButton } from "./components";

export function AppView() {
  const { id: idParam } = useParams();
  const navigate = useNavigate();
  const form = useForm({
    shouldUnregister: false,
  });
  const { t } = useTranslation();
  const {
    reset,
    control,
    register,
    setValue,
    getValues,
    errors,
    handleSubmit,
  } = form;

  const { id, food_databases, locale } = useWatch({
    name: ["id", "food_databases", "locale"],
    control,
  });

  const { data, refetch } = useApp(idParam);
  const { api_keys, name } = data ?? {};

  const { data: _foodDatabases } = useFoodDatabases({
    placeholderData: [],
  });

  const { mutateAsync } = useAppSave();

  const foodDatabases = useMemo(
    () => _foodDatabases.filter((i) => i.locale === locale),
    [_foodDatabases, locale]
  );

  useEffect(() => reset(data), [data, reset]);

  if (id === undefined) {
    return null;
  }

  register("root", {
    validate: {
      foodDatabasesLength: () => {
        return !!getValues("food_databases").length;
      },
    },
  });

  async function onSubmit(x) {
    const isNew = !x.id;
    const { id } = await mutateAsync(x);
    NotificationManager.success(t("Saved successfully"));

    if (isNew) {
      navigate(`../${id}`, { replace: true });
    } else {
      await refetch();
    }
  }

  return (
    <>
      <Page
        title={
          id
            ? t(`Viewing App: {{ name }}`, {
                name,
              })
            : "Create App"
        }
      >
        <FormProvider {...form}>
          <Row form>
            <Col>
              <FormGroup>
                <Label for="name">
                  <Trans>App Name</Trans>
                </Label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  ref={register({
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="locale">
                  <Trans>Locale</Trans>
                </Label>
                <select
                  className="form-control"
                  name="locale"
                  id="locale"
                  ref={register({
                    required: true,
                  })}
                  onChange={(e) => {
                    const { value, name } = e.target;
                    setValue(name, value);
                    setValue("food_databases", []);
                  }}
                >
                  {LANGUAGES.map((i) => (
                    <option key={i.value} value={i.value}>
                      {i.label}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label>
              <Trans>Databases</Trans>
            </Label>
            {foodDatabases.map(({ id, name }) => (
              <FormGroup check key={id}>
                <Label for={`food_database.${id}`} check>
                  <Input
                    id={`food_database.${id}`}
                    type="checkbox"
                    checked={food_databases.includes(id)}
                    onChange={(e) => {
                      const { checked } = e.target;
                      setValue(
                        "food_databases",
                        checked
                          ? [...food_databases, id]
                          : food_databases.filter((i) => i !== id)
                      );
                    }}
                  />
                  {name}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
          {errors.root?.type === "foodDatabasesLength" ? (
            <Alert color="danger">
              <Trans>At least 1 database must be selected.</Trans>
            </Alert>
          ) : null}
          <div className="flex justify-end">
            <SubmitButton control={control} onClick={handleSubmit(onSubmit)}>
              <Trans>Save App</Trans>
            </SubmitButton>
          </div>
        </FormProvider>
      </Page>
      {api_keys ? (
        <AppAPIKeysView onSubmitted={refetch} apiKeys={api_keys} appId={id} />
      ) : null}
    </>
  );
}
