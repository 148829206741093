import React, { useState, useMemo } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { keys } from "lodash";
import { Button } from "components/common";
import { NotificationManager } from "react-notifications";
import { useClient } from "hooks";
import { Trans } from "react-i18next";

const DeleteModal = ({
  isOpen,
  toggle,
  url,
  onDeleted,
  onConfirm: onConfirmProp,
  selected: selectedProp,
}) => {
  const client = useClient();
  const [pending, setPending] = useState(false);

  const selected = useMemo(() => (selectedProp ? keys(selectedProp) : []), [
    selectedProp,
  ]);

  const numSelected = selected.length;

  function onConfirm() {
    setPending(true);
    let promise = null;
    const failed = [];
    if (onConfirmProp) {
      promise = onConfirmProp();
    } else {
      promise = Promise.mapSeries(
        selected,
        (i) => {
          return client.delete(`${url}/${i}`).catch((err) => {
            failed.push(i);
          });
        },
        { concurrency: 1 }
      ).tap(() => {
        if (failed.length) {
          NotificationManager.error(
            `${failed.length} row(s) could not be deleted`
          );
        } else {
          NotificationManager.error(
            `${numSelected} row(s) deleted successfully`
          );
        }
      });
    }

    promise
      .tap(() => {
        if (isOpen) toggle();
        onDeleted(failed.length ? failed : null);
      })
      .finally(() => setPending(false));
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        Are you sure you want to delete the {numSelected} selected rows?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" pending={pending} onClick={onConfirm}>
          <Trans>Confirm</Trans>
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          <Trans>Cancel</Trans>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteModal;
