import React from "react";
import classNames from "classnames/dedupe";
import { Trans } from "react-i18next";

export const TermsLinks = ({ className, ...props }) => {
  return (
    <div className={classNames("terms-links", className)}>
      <a
        className="terms-links__link"
        href="https://www.myfood24.org/terms-of-use"
        rel="noopener noreferrer"
      >
        <Trans>Terms & Conditions</Trans>
      </a>{" "}
      |{" "}
      <a
        className="terms-links__link"
        href="https://www.myfood24.org/web/about-us"
        rel="noopener noreferrer"
      >
        <Trans>About myfood24</Trans>
      </a>{" "}
      |{" "}
      <a
        className="terms-links__link"
        href="https://www.myfood24.org/privacy-policy"
        rel="noopener noreferrer"
      >
        <Trans>Privacy Policy</Trans>
      </a>
    </div>
  );
};

export default TermsLinks;
