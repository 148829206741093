import React, {
  useState,
  useMemo,
  useRef,
  memo,
  useCallback,
} from "react";
import {
  Page,
  FormGroup,
  SubmitButton,
  DatePicker,
  CKEditor,
  FormCheck,
  BooleanIcon,
  Button,
  Select,
} from "components/common";
import { StickyTable, Row as StickyRow, Cell } from "react-sticky-table";
import { Tab as BaseTab, TabContainer } from "components/Tabs";
import {
  Field,
  useFormik,
  FormikProvider,
  useField,
  useFormikContext,
  FieldArray,
} from "formik";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useAsyncEffect, useClient, useUserContext } from "hooks";
import { includes, uniqueId, reduce, some, get } from "lodash";
import {
  Nav,
  TabPane as BaseTabPane,
  TabContent,
  Row,
  Col,
  Alert,
} from "reactstrap";
import classNames from "classnames";
import Participants from "./Participants";
import ProjectFields from "./components/ProjectFields";
import DiaryOptionsField from "./components/DiaryOptionsField";
import ContentField from "./components/ContentField";
import YesNoField from "./components/YesNoField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import { FastInput } from "../../components/common";
import { OfflineTab } from "./components/OfflineTab";
import {ReferenceIntake} from "./components/ReferenceIntake";
import { isInt } from "validator";
import {EligibilityQuestions} from "./components/EligibilityQuestions";
import {ConsentQuestion} from "./components/ConsentQuestion";
import {ParticipantUniqueIdentifier} from "./components/ParticipantUniqueIdentifier";
import {PIIProjectButton} from "../../features/pii/PIIProjectButton";

const TAB_FIELDS = {
  project: [
    "title",
    "start_date",
    "end_date",
    "timezone",
    "recall_day_type",
    "is_gender_required",
    "is_age_required",
    "default_locale",
    "contact_email",
    "external_link",
    "external_backend_request_link",
    "logo",
    "project_stages",
  ],
  content: [
    "project_content.welcome_message",
    "project_content.getting_started",
    "project_content.about",
    "project_content.contact_us",
    "project_content.email_invite_subject",
    "project_content.email_invite_message",
    "project_content.email_reminder_subject",
    "project_content.email_reminder_message",
    "project_content.email_thankyou_subject",
    "project_content.email_thankyou_message",
  ],
  reference_intakes: [
    "project_reference_intake.heading_statement",
    "project_reference_intake.bottom_statement",
  ],
  reminders: [
    "email_reminder_dates.0",
    "email_reminder_dates.1",
    "email_reminder_dates.2",
    "email_reminder_days.0",
    "email_reminder_days.1",
    "email_reminder_days.2",
  ],
  diary_options: [
    "log_method_of_consumption",
    "can_reset_recall_day",
    "show_calorie_consumption",
    "log_food_time",
    "show_welcome",
    "hide_diary_converter",
  ],
};

const Tab = ({ errors, tabId, children, ...props }) => {
  const hasError = !!errors?.[tabId];

  return (
    <BaseTab tabId={tabId} {...props}>
      <div className={hasError ? "text-red-500" : ""}>
        {children}
        {hasError ? (
          <FontAwesomeIcon icon={faExclamationCircle} className="ml-1" />
        ) : null}
      </div>
    </BaseTab>
  );
};

const TabPane = ({ activeTab, tabId, optimise, ...props }) => {
  if (!optimise || activeTab === tabId) {
    return <BaseTabPane tabId={tabId} {...props} />;
  }

  return null;
};

const QuestionsField = ({ questions, name, validate }) => {
  const [, { value }, { setValue }] = useField({
    name,
    validate,
  });

  const { t } = useTranslation();

  return (
    <>
      {questions.map(({ id, question_text }) => (
        <FormCheck
          key={id}
          label={t(question_text)}
          input={
            <input
              type="checkbox"
              checked={includes(value, id)}
              onChange={(e) => {
                const { checked } = e.target;
                setValue(
                  checked ? [...value, id] : value.filter((i) => i !== id)
                );
              }}
              className="form-check-input"
            />
          }
        />
      ))}
    </>
  );
};

const DatabaseTabComponent = memo(
  ({
    allFoodDatabaseTags,
    foodDatabases,
    selectedDatabases,
    food_databases,
    setFieldValue,
    activeTab,
  }) => {
    const { values } = useFormikContext();
    const { default_locale } = values;

    return (
      <TabPane tabId="databases" optimise activeTab={activeTab}>
        <Trans>
          <p className="mb-2">
            Using the information below, please select the food database you
            wish to use by clicking on the row, it will then become highlighted
            to indicate your selection. (Note: more than one database can be
            selected)
          </p>
          <p>
            The list shows available myfood24 food databases and their
            corresponding nutritional information. The nutrients represented
            with a tick indicate that they will be populated within the database
            of interest.
          </p>
        </Trans>
        <p className="mt-2">
          <strong>
            <ul>
              <Trans>
                We recommend using a combination of a generic database and a branded database where possible to give the
                greatest coverage of foods for your participants e.g. McCance and Widdowson 7 (Generic) with MW7 Branded.
              </Trans>
            </ul>
          </strong>
        </p>
        {default_locale === "en" ? (
          <p className="mt-2">
            Please note: A number of missing values (N and blank values) in the
            McCance and Widdowson 7 (Generic) database have been ‘over-stamped’
            with an equivalent value from a similar product.
            <br />
            In addition, as our MW7 Branded database products have been mapped
            to MW7 generic items for a more complete nutrient profile, some of
            these ‘over-stamped’ values will apply to this database as well.
            <br />
            This is to provide the best and most complete databases possible for
            our customers. For more information, please contact a member of our team.
            <br/>
            Please also note: Sustainability variables (greenhouse gas emissions, land use, and water use) are currently only available for our McCance and Widdowson 7 (Generic) and MW7 Branded databases. If you would like to view these, please scroll right to the end of the variable list below.
          </p>
        ) : null}

        <StickyTable
          leftStickyColumnCount={3}
          stickyHeaderCount={0}
          className="text-sm my-4"
        >
          <StickyRow className="font-bold">
            <Cell>
              <Trans>Country</Trans>
            </Cell>
            <Cell>
              <Trans>Database</Trans>
            </Cell>
            <Cell>
              <Trans>Version</Trans>
            </Cell>
            {allFoodDatabaseTags.map((i) => (
              <Cell key={i}>{i}</Cell>
            ))}
          </StickyRow>
          {foodDatabases.map((i) => (
            <StickyRow
              key={i.id}
              className="cursor-pointer"
              onClick={() => {
                if (selectedDatabases[i.id]) {
                  setFieldValue(
                    "food_databases",
                    food_databases.filter((j) => j !== i.id)
                  );
                } else {
                  setFieldValue("food_databases", [...food_databases, i.id]);
                }
              }}
            >
              <Cell
                className={selectedDatabases[i.id] ? "bg-secondary-light" : ""}
              >
                {i.country}
              </Cell>
              <Cell
                className={selectedDatabases[i.id] ? "bg-secondary-light" : ""}
              >
                {i.description}
              </Cell>
              <Cell
                className={selectedDatabases[i.id] ? "bg-secondary-light" : ""}
              >
                {i.version}
              </Cell>
              {allFoodDatabaseTags.map((j) => (
                <Cell
                  key={j}
                  className={classNames(
                    selectedDatabases[i.id] ? "bg-secondary-light" : "",
                    "text-center"
                  )}
                >
                  <BooleanIcon test={i.tags[j]} showFalsey={false} />
                </Cell>
              ))}
            </StickyRow>
          ))}
        </StickyTable>
      </TabPane>
    );
  }
);

const DatabasesTab = ({
  foodDatabases: foodDatabasesProp,
  defaultLocale,
  activeTab,
}) => {
  let foodDatabases = useMemo(
    () =>
      foodDatabasesProp?.filter((i) => !i.locale || i.locale === defaultLocale),
    [defaultLocale, foodDatabasesProp]
  );
  const { values, setFieldValue } = useFormikContext();
  const { food_databases } = values;

  const selectedDatabases = useMemo(
    () =>
      food_databases?.reduce((acc, i) => {
        acc[i] = true;
        return acc;
      }, {}) || {},
    [food_databases]
  );

  foodDatabases = useMemo(
    () =>
      foodDatabases?.map((i) => ({
        ...i,
        tags: i.tags.reduce((acc, j) => {
          acc[j] = true;
          return acc;
        }, {}),
      })),
    [foodDatabases]
  );

  const allFoodDatabaseTags = useMemo(
    () => {
      const ret = foodDatabases.reduce((acc, i) => {
        const tags = i.all_tags ?? [];
        return new Set([...acc, ...tags]);
      }, new Set());

      return [...ret];
    },
    [foodDatabases]
  );

  return (
    <DatabaseTabComponent
      foodDatabases={foodDatabases}
      food_databases={food_databases}
      allFoodDatabaseTags={allFoodDatabaseTags}
      selectedDatabases={selectedDatabases}
      setFieldValue={setFieldValue}
      activeTab={activeTab}
    />
  );
};

const UnavailableTab = ({ children, ...props }) => {
  return (
    <TabPane {...props}>
      <Trans>Please save the project before using this tab.</Trans>
    </TabPane>
  );
};

export const Edit = () => {
  const formik = useFormik({
    onSubmit,
    validate: (values) => {
      const errors = {};

      if (!values.is_student_project) {
        for (const field of [
          "project_content.email_invite_subject",
          "project_content.email_invite_message",
          "project_content.email_reminder_subject",
          "project_content.email_reminder_message",
          "project_content.email_thankyou_subject",
          "project_content.email_thankyou_message",
          "project_content.welcome_message",
          "project_content.getting_started",
          "project_content.about",
          "project_content.contact_us",
        ]) {
          if (!get(values, field)) {
            errors[field] = true;
          }
        }
      }

      if (values.logo) {
        const { name } = values.logo;

        if (!/\.(jpg|jpeg|png)$/i.test(name)) {
          errors.logo = true;
        }
      }

      if (values.consent_document) {
        const { name } = values.consent_document;

        if (!/\.(pdf)$/i.test(name)) {
          errors.consent_document = "invalid_type";
        }
      }

      return errors;
    },
  });
  const {
    resetForm,
    values,
    submitForm,
    isValid,
    submitCount,
    setFieldValue,
  } = formik;
  const formikRef = useRef();
  formikRef.current = formik;
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const { id } = useParams();
  const [questions, setQuestions] = useState();
  const [timezones, setTimezones] = useState();
  const [foodDatabases, setFoodDatabases] = useState();
  const [tabErrors, setTabErrors] = useState({});
  const client = useClient();
  const isNew = id === "new";
  const url = "projects";
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [activeTab, _setActiveTab] = useState(tab || "project");
  const title = isNew ? "Create Project" : "Edit Project";
  const defaultLocale = values?.default_locale;
  const { origin } = window.location;
  const { userGroup, user } = useUserContext();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState();

  const setActiveTab = (v) => {
    _setActiveTab(v);
    setSearchParams({ tab: v }, { replace: true });
  };

  const validateTabs = () => {
    const tabErrors = reduce(
      TAB_FIELDS,
      (acc, fields, tab) => {
        acc[tab] = some(fields, (i) => get(formikRef.current.errors, i));
        return acc;
      },
      {}
    );
    setTabErrors(tabErrors);
  };

  async function onSubmit(x) {
    const payload = {
      ...x,
      email_reminder_days: x.email_reminder_days.map(i => i === "" ? null : i)
    }

    try {
      const values = await (isNew
        ? client.post("projects", payload)
        : client.put(`${url}/${id}`, payload)
      ).get("data");
      navigate(`../${values.id}`, { replace: true });
      setInitialValues(values);
      resetForm({ values });
      NotificationManager.success("Saved successfully");
    } catch (ex) {
      // check error
      throw ex;
    }
  }

  async function onPIISubmitted() {
    const values = await client.get(`${url}/${id}`).get('data');
    setInitialValues(values);
    resetForm({ values })
  }


  useAsyncEffect(async () => {
    const clone = state?.clone;
    const input = clone ? Promise.resolve(clone) : client
      .get(`${url}/${id}`, {
        params: {
          group: userGroup,
        },
      })
      .get("data");

    const [values, questions, foodDatabases, timezones] = await Promise.all([
      input.then(x => ({
        ...x,
        email_reminder_days: x.email_reminder_days.map(i => i ?? "")
      })),
      client.get("projects/questions").get("data"),
      client.get("food_databases").get("data"),
      client.get("projects/timezones").get("data"),
    ]);

    setInitialValues(values);
    setQuestions(questions);
    setFoodDatabases(foodDatabases);
    setTimezones(
      timezones.map((i) => ({
        label: i,
        value: i,
      }))
    );

    resetForm({ values });
  }, []);

  const updateProjectIInstructions = useCallback(
    async (locale) => {
      const projectInstructions = await client
        .post("projects/default_project_instructions", {
          locale,
        })
        .get("data")
        .get("project_instructions");
      setFieldValue("project_content.getting_started", projectInstructions);
    },
    [client, setFieldValue]
  );

  if (!values) {
    return (
      <Page title={title} fluid>
        <FontAwesomeIcon icon={faSpinner} spin /> <Trans>Loading</Trans>...
      </Page>
    );
  }

  function validateInteger(v) {
    if (v === "" || v === null || v === undefined) return false;
    return !isInt(`${v}`, { min: 0 });
  }

  const { send_reminders_by } = values;

  const offline = initialValues.offline;
  const allowOffline =
    (defaultLocale === "en" || defaultLocale === "es") &&
    (userGroup === "admin" ||
      (userGroup === "researcher" && user.allow_offline_projects));

  return (
    <Page title={title} fluid>
      <FormikProvider value={formik}>
        {values.is_student_project ? (
          <>
            <Nav tabs className="mb-4">
              <TabContainer setActiveTab={setActiveTab} activeTab={activeTab}>
                <Tab errors={tabErrors} tabId="project">
                  <Trans>Create / edit project</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="databases">
                  <Trans>Databases</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="reference_intakes">
                  <Trans>Reference intake</Trans>
                </Tab>
                {allowOffline && offline ? (
                  <Tab tabId="offline">
                    <Trans>Offline</Trans>
                  </Tab>
                ) : null}
              </TabContainer>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="project">
                {values.short_code ? (
                  <FormGroup label="Project code for students">
                    <div className="font-medium">{values.short_code}</div>
                  </FormGroup>
                ) : null}
                <FormGroup label="Title" name="title" required>
                  <Field
                    name="title"
                    className="form-control"
                    as={FastInput}
                    validate={(v) => !v}
                  />
                </FormGroup>
                <FormGroup label="Project type" name="project_type" required>
                  {values.num_participants ? (
                    <span>
                      {values.project_type === "single"
                        ? "Single diary submission"
                        : "Multiple diary submission"}
                    </span>
                  ) : (
                    <>
                      <FormCheck
                        label={t("Single diary submission")}
                        input={
                          <Field
                            name="project_type"
                            value="single"
                            type="radio"
                            className="form-check-input"
                          />
                        }
                      />
                      <FormCheck
                        label={t("Multiple diary submission")}
                        input={
                          <Field
                            name="project_type"
                            value="multiple"
                            type="radio"
                            className="form-check-input"
                          />
                        }
                      />
                    </>
                  )}
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup label="Start date" name="start_date" required>
                      <DatePicker
                        name="start_date"
                        validate={(v) => !v}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup label="End date" name="end_date" required>
                      <DatePicker
                        name="end_date"
                        validate={(v) => !v}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup
                  label={t("Timezone")}
                  name="timezone"
                  translateLabel={false}
                >
                  <Select name="timezone" options={timezones} simpleValue />
                </FormGroup>
                <FormGroup
                  name="is_gender_required"
                  label="Collect sex?"
                  required
                >
                  <YesNoField name="is_gender_required" />
                </FormGroup>
                <FormGroup name="is_age_required" label="Collect age?" required>
                  <YesNoField name="is_age_required" />
                </FormGroup>
                {values.project_type === "multiple" ? (
                  <FieldArray name="project_stages">
                    {({ remove, push }) => (
                      <FormGroup label="Diary names">
                        {values.project_stages.map((i, idx) => (
                          <Row
                            key={i.key || i.id}
                            form
                            className="max-w-md my-1"
                          >
                            <Col>
                              <FormGroup
                                name={`project_stages.${idx}.name`}
                                className="mb-0"
                              >
                                <Field
                                  name={`project_stages.${idx}.name`}
                                  className="form-control form-control-sm"
                                  placeholder="eg Day 1, Vegetarian"
                                  as={FastInput}
                                  validate={(v) => !v}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs="auto" className="w-32">
                              {!values.num_participants || !i.id ? (
                                <Button
                                  size="sm"
                                  color="light"
                                  block
                                  onClick={() => {
                                    remove(idx);
                                  }}
                                >
                                  <Trans>Delete</Trans>
                                </Button>
                              ) : null}
                            </Col>
                          </Row>
                        ))}
                        <Button
                          size="sm"
                          className="mt-2"
                          onClick={() => {
                            push({
                              name: "",
                              key: uniqueId("project-stage"),
                            });
                          }}
                        >
                          <FontAwesomeIcon className="mr-2" icon={faPlus} />
                          {!values.project_stages.length
                            ? "Add the first diary name"
                            : "Add another diary name"}
                        </Button>
                      </FormGroup>
                    )}
                  </FieldArray>
                ) : null}
                <FormGroup label="Diary completion questions">
                  <QuestionsField name="questions" questions={questions} />
                </FormGroup>
              </TabPane>
              <TabPane tabId="reference_intakes" optimise activeTab={activeTab}>
                <ReferenceIntake name="project_reference_intake" />
              </TabPane>
              <DatabasesTab
                defaultLocale={defaultLocale}
                foodDatabases={foodDatabases}
                activeTab={activeTab}
              />
              {allowOffline && offline ? (
                <TabPane tabId="offline" optimise activeTab={activeTab}>
                  <OfflineTab projectId={values.id} />
                </TabPane>
              ) : null}
            </TabContent>
          </>
        ) : (
          <>
            <Nav tabs className="mb-4">
              <TabContainer setActiveTab={setActiveTab} activeTab={activeTab}>
                <Tab errors={tabErrors} tabId="project">
                  <Trans>Create / edit project</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="content">
                  <Trans>Content</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="reference_intakes">
                  <Trans>Reference intakes</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="reminders">
                  <Trans>Reminders</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="diary_options">
                  <Trans>Diary options</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="databases">
                  <Trans>Databases</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="participants">
                  <Trans>Participants</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="opted_out">
                  <Trans>Opted out participants</Trans>
                </Tab>
                <Tab errors={tabErrors} tabId="advanced">
                  <Trans>Advanced</Trans>
                </Tab>
                {allowOffline && offline ? (
                  <Tab tabId="offline">
                    <Trans>Offline</Trans>
                  </Tab>
                ) : null}
              </TabContainer>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="project">
                <FormGroup label="Title" name="title" required>
                  <Field
                    name="title"
                    className="form-control"
                    as={FastInput}
                    validate={(v) => !v}
                  />
                </FormGroup>
                <Row form>
                  <Col>
                    <FormGroup label="Start date" name="start_date" required>
                      <DatePicker
                        name="start_date"
                        validate={(v) => !v}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup label="End date" name="end_date" required>
                      <DatePicker
                        name="end_date"
                        validate={(v) => !v}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup
                  label={t("Timezone")}
                  name="timezone"
                  translateLabel={false}
                >
                  <Select name="timezone" options={timezones} simpleValue />
                </FormGroup>
                <ProjectFields allowOffline={allowOffline} onDefaultLanguageChanged={async (locale) => {
                  if (locale && !values.id) {
                    await updateProjectIInstructions(locale);
                  }
                }} />
              </TabPane>
              <TabPane tabId="content" optimise activeTab={activeTab}>
                <ContentField />
                <hr />
                <FormGroup
                  label="Email invite subject"
                  name="project_content.email_invite_subject"
                  required
                >
                  <Field
                    name="project_content.email_invite_subject"
                    as={FastInput}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup
                  label="Email invite message"
                  name="project_content.email_invite_message"
                  helpText={
                    <Trans>
                      NOTE: Use {{ link: "{{ link }}" }} to insert the invite
                      link in the message.
                    </Trans>
                  }
                  required
                >
                  <CKEditor name="project_content.email_invite_message" />
                </FormGroup>
                <hr />
                <FormGroup
                  label="Email reminder subject"
                  name="project_content.email_reminder_subject"
                  required
                >
                  <Field
                    name="project_content.email_reminder_subject"
                    as={FastInput}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup
                  label="Email reminder message"
                  name="project_content.email_reminder_message"
                  helpText={
                    <Trans>
                      NOTE: Use {{ link: "{{ link }}" }} to insert the invite
                      link in the message.
                    </Trans>
                  }
                  required
                >
                  <CKEditor name="project_content.email_reminder_message" />
                </FormGroup>
                <hr />
                <FormGroup
                  label="Email thank you subject"
                  name="project_content.email_thankyou_subject"
                  required
                >
                  <Field
                    name="project_content.email_thankyou_subject"
                    as={FastInput}
                    className="form-control"
                  />
                </FormGroup>
                <FormGroup
                  label="Email thank you message"
                  name="project_content.email_thankyou_message"
                  required
                >
                  <CKEditor name="project_content.email_thankyou_message" />
                </FormGroup>
              </TabPane>
              <TabPane tabId="reference_intakes" optimise activeTab={activeTab}>
                <FormGroup
                  label="Heading statement"
                  name="project_reference_intake.heading_statement"
                >
                  <CKEditor name="project_reference_intake.heading_statement" />
                </FormGroup>
                <ReferenceIntake name="project_reference_intake" backOfPack />
                <FormGroup
                  label="Bottom statement"
                  name="project_reference_intake.bottom_statement"
                >
                  <CKEditor name="project_reference_intake.bottom_statement" />
                </FormGroup>
              </TabPane>
              <TabPane tabId="reminders" optimise activeTab={activeTab}>
                <FormGroup label="Send reminders by">
                  <FormCheck
                    label="Fixed dates"
                    translateLabel
                    input={
                      <Field
                        name="send_reminders_by"
                        value="fixed_dates"
                        type="radio"
                        id="send_reminders_by_fixed_dates"
                        className="form-check-input"
                      />
                    }
                  />
                  <FormCheck
                    label="Days since invitation"
                    translateLabel
                    input={
                      <Field
                        name="send_reminders_by"
                        value="days_since_invitation"
                        type="radio"
                        id="send_reminders_by_fixed_days_since_invitation"
                        className="form-check-input"
                      />
                    }
                  />
                </FormGroup>
                {send_reminders_by === 'fixed_dates' ? (
                  <>
                    <FormGroup
                      label="Email reminder date 1"
                      name="email_reminder_dates.0"
                    >
                      <DatePicker
                        name="email_reminder_dates.0"
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Email reminder date 2"
                      name="email_reminder_dates.1"
                    >
                      <DatePicker
                        name="email_reminder_dates.1"
                        className="form-control"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Email reminder date 3"
                      name="email_reminder_dates.2"
                    >
                      <DatePicker
                        name="email_reminder_dates.2"
                        className="form-control"
                      />
                    </FormGroup>
                  </>
                ) : null}
                {send_reminders_by === 'days_since_invitation' ? (
                  <>
                    <FormGroup
                      label="Email reminder days since invitation 1"
                      name="email_reminder_days.0"
                    >
                      <Field name="email_reminder_days.0" validate={validateInteger} className="form-control" />
                    </FormGroup>
                    <FormGroup
                      label="Email reminder days since invitation 2"
                      name="email_reminder_days.1"
                    >
                      <Field name="email_reminder_days.1" className="form-control" />
                    </FormGroup>
                    <FormGroup
                      label="Email reminder days since invitation 3"
                      name="email_reminder_days.2"
                    >
                      <Field name="email_reminder_days.2" className="form-control" />
                    </FormGroup>
                  </>
                ) : null}
              </TabPane>
              <TabPane tabId="diary_options">
                <EligibilityQuestions />
                <hr/>
                <ConsentQuestion />
                <hr/>
                <ParticipantUniqueIdentifier />
                <hr/>
                <DiaryOptionsField />
                <FormGroup label="Diary completion questions">
                  <QuestionsField name="questions" questions={questions} />
                </FormGroup>
              </TabPane>
              <DatabasesTab
                defaultLocale={defaultLocale}
                foodDatabases={foodDatabases}
                activeTab={activeTab}
              />

              {!values.id ? (
                <>
                  <UnavailableTab tabId="participants" />
                  <UnavailableTab tabId="advanced" />
                  <UnavailableTab tabId="opted_out" />
                </>
              ) : null}
              {values.id && activeTab === "participants" && (
                <TabPane tabId="participants" optimise activeTab={activeTab}>
                  <Participants project={values.id} />
                </TabPane>
              )}
              <TabPane tabId="opted_out" optimise activeTab={activeTab} />
              {values.id && activeTab === "advanced" ? (
                <TabPane tabId="advanced" optimise activeTab={activeTab}>
                  {values.project_token ? (
                    <>
                      <p className="mb-2">
                        <Trans>
                          MyFood24 now allows users to access the projects via a
                          unique project url. The access url for this project is
                        </Trans>
                      </p>
                      <p className="mb-2">
                        <strong>
                          {origin}/{defaultLocale}/users/invite/
                          {values.project_token}
                        </strong>
                      </p>
                      <p className="mb-2">
                        <Trans>
                          To track users, enter the unique ids for each user at
                          the
                          end of the url
                        </Trans>
                      </p>
                      <p>
                        <strong>
                          {origin}/{defaultLocale}/users/invite/
                          {values.project_token}/{"{userid}"}
                        </strong>
                      </p>
                      {user.is_superuser ? <hr/> : null}
                    </>
                  ) : null}
                  {user.is_superuser ? (
                    <>
                      <PIIProjectButton projectId={values.id} onSubmitted={onPIISubmitted}/>
                    </>
                  ) : null}
                </TabPane>
              ) : null}
              {allowOffline && initialValues.offline ? (
                <TabPane tabId="offline" optimise activeTab={activeTab}>
                  <OfflineTab projectId={values.id}/>
                </TabPane>
              ) : null}
            </TabContent>
          </>
        )}
        {submitCount && !isValid ? (
          <Alert color="danger">
            <Trans>Please complete all required fields.</Trans>
          </Alert>
        ) : null}
        <div className="flex justify-end">
          <SubmitButton
            onClick={async () => {
              await submitForm();
              await Promise.delay(0);
              validateTabs();
            }}
          >
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};

export default Edit;
