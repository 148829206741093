import React from "react";
import {
  Pagination as ReactstrapPagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { withPropsOnChange, compose, onlyUpdateForKeys } from "recompose";
import { range, pick, reduce } from "lodash";

export const Pagination = ({
  pages,
  onPreviousClick,
  onFirstClick,
  onLastClick,
  onNextClick,
  atEnd,
  atStart,
  ...props
}) => {
  const paginationProps = pick(props, "className", "style", "size", "tag");

  return (
    <ReactstrapPagination size="sm" {...paginationProps}>
      <PaginationItem disabled={atStart}>
        <PaginationLink first href="#" onClick={onFirstClick} />
      </PaginationItem>
      <PaginationItem disabled={atStart}>
        <PaginationLink previous href="#" onClick={onPreviousClick} />
      </PaginationItem>
      {pages.map((i) => (
        <PaginationItem active={i.active} key={i.page}>
          <PaginationLink href="#" onClick={i.onClick}>
            {i.page}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={atEnd}>
        <PaginationLink next href="#" onClick={onNextClick} />
      </PaginationItem>
      <PaginationItem disabled={atEnd}>
        <PaginationLink last href="#" onClick={onLastClick} />
      </PaginationItem>
    </ReactstrapPagination>
  );
};

const enhance = compose(
  onlyUpdateForKeys(["count", "offset", "onOffsetChanged", "limit"]),
  withPropsOnChange(
    ["count", "offset", "onOffsetChanged", "limit", "noPages"],
    ({ count, limit, offset, onOffsetChanged, noPages }) => {
      const numPages = Math.ceil(count / limit);
      const currentPage = Math.floor(offset / limit) + 1;

      let start = currentPage - 5;
      let end = currentPage + 5;

      if (start < 1) {
        end += start * -1;
      } else if (end > numPages) {
        start += numPages - end;
      }

      return {
        currentPage,
        atEnd: currentPage === numPages || numPages === 0,
        atStart: currentPage === 1,
        onFirstClick: (e) => {
          e.preventDefault();
          onOffsetChanged(0);
        },
        onLastClick: (e) => {
          e.preventDefault();
          onOffsetChanged(count - limit);
        },
        onNextClick: (e) => {
          e.preventDefault();
          if (currentPage === numPages || numPages === 0) return;
          onOffsetChanged(offset + limit);
        },
        onPreviousClick: (e) => {
          e.preventDefault();
          const newOffset = offset - limit;
          if (newOffset < 0) return;
          onOffsetChanged(newOffset);
        },
        pages: reduce(
          noPages ? [] : range(1, numPages + 1),
          (acc, i, idx) => {
            if (i < start || i > end) return acc;

            acc.push({
              page: i,
              onClick: (e) => {
                e.preventDefault();
                return onOffsetChanged((i - 1) * limit);
              },
              active: i === currentPage,
            });

            return acc;
          },
          []
        ),
      };
    }
  )
);

export default enhance(Pagination);
