import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { useParticipantContext } from "../../contexts/useParticipantContext";
import { useUserContext } from "hooks";
import { Navigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { Title } from "./components/Title";
import { SubTitle } from "./components/SubTitle";
import { CardHeader } from "./components/CardHeader";

const Section = ({ title, content }) => {
  if (!content) return null;

  return (
    <>
      <CardHeader>{title}</CardHeader>
      <CardBody>
        <div
          className="prose prose-sm"
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </CardBody>
    </>
  );
};

export const Welcome = (props) => {
  const { project } = useParticipantContext();
  const { user } = useUserContext();
  const { project_content } = project;

  if (!project.show_welcome) {
    return <Navigate to="diary" />;
  }

  const enterButton = (
    <Link className="btn btn-primary" to="diary">
      <Trans>Enter Food Diary</Trans>
    </Link>
  );

  return (
    <div className="mb-4">
      <div>
        <Title>
          <Trans>Welcome to myfood24</Trans>
          {user.is_unknown ? "" : `, ${user.full_name}`}
        </Title>
        <SubTitle>
          <Trans>Please review your project instructions.</Trans>
        </SubTitle>
      </div>
      <div className="text-center mb-4">
        {enterButton}
      </div>
      <Card className="border-brand-light">
        <Section
          title={<Trans>Welcome</Trans>}
          content={project_content.welcome_message}
        />
        <Section
          title={<Trans>Project Instructions</Trans>}
          content={project_content.getting_started}
        />
        <Section
          title={<Trans>About Us</Trans>}
          content={project_content.about}
        />
        <Section
          title={<Trans>Contact us</Trans>}
          content={project_content.contact_us}
        />
      </Card>
      <div className="text-center my-4">
        {enterButton}
      </div>
    </div>
  );
};

export default Welcome;
