import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trans } from "react-i18next";

export const AddNewButton = ({
  resource,
  className,
  to = "new",
  children,
  ...props
}) => {
  return (
    <Link
      className={classNames("btn btn-secondary", className)}
      to={to}
      {...props}
    >
      {children ? (
        children
      ) : (
        <>
          <FontAwesomeIcon icon={faPlus} className="mr-1" />{" "}
          <Trans>Add new</Trans> {resource}
        </>
      )}
    </Link>
  );
};

export default AddNewButton;
