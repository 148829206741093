import React, { useState } from "react";
import LoginBase from "../components/LoginBase";
import { useClient, useAsyncEffect } from "hooks";
import { Loading } from "components/common";
import TermsLinks from "components/TermsLinks";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Alert, Card } from "reactstrap";
import { Trans } from "react-i18next";

export const Activate = () => {
  const { user, signature, locale } = useParams();
  const client = useClient();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const baseUrl = `/${locale}/participant/teaching`;

  useAsyncEffect(async () => {
    try {
      const { project, token, username } = await client
        .post("teaching_login/activate", {
          user: parseInt(user, 10),
          signature,
        })
        .get("data");

      navigate(`${baseUrl}/login`, {
        state: {
          project,
          token,
          username,
        },
      });
    } catch (ex) {
      const code = ex?.response?.data?.code;

      if (code === "UserAlreadyActivated") {
        setError(
          <div className="prose prose-sm text-center max-w-full">
            <p>
              <Trans>
                The account that you are trying to activate is already active.
              </Trans>
            </p>
            <p>
              <Trans>
                Please <Link to={`${baseUrl}/start`}>login</Link> to continue.
              </Trans>
            </p>
          </div>
        );
      } else {
        setError(
          <Alert color="danger" className="mb-0">
            <Trans>
              An error occurred with the provided link, please contact myfood24.
            </Trans>
          </Alert>
        );
      }
    }
  }, [user, signature]);

  const base = (children) => (
    <LoginBase footer={<TermsLinks className="text-center mt-4" />}>
      <Card body>
        <div>{children}</div>
      </Card>
    </LoginBase>
  );

  if (error) {
    return base(error);
  }

  return base(<Loading />);
};

export default Activate;
