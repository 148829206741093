import { Page, SubmitButton } from "../components";
import { useClient } from "../hooks";
import { useForm, FormProvider } from "react-hook-form";
import { useQuery } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import { QRCodeSVG } from "qrcode.react";
import { FormGroup, FormText, Label } from "reactstrap";
import { NotificationManager } from "react-notifications";

export const TwoFactorAuthentication = () => {
  const methods = useForm();
  const { handleSubmit, register } = methods;
  const client = useClient();
  const url = "totp";
  const { t } = useTranslation();

  const { data: totp, refetch } = useQuery(
    url,
    ({ queryKey }) => client.get(...queryKey).get("data"),
    {
      suspense: true,
    }
  );
  const { enabled, activated, provisioning_url, totp_secret } = totp;

  async function onEnableTOTP() {
    await client.post(`${url}/enable`).get("data");
    await refetch();
  }

  function handleException(ex) {
    const code = ex.response?.data?.code;

    if (code === "incorrect_password") {
      NotificationManager.error(t("Incorrect password provided"));
    } else if (code === "totp_incorrect_pin") {
      NotificationManager.error(t("Incorrect pin provided"));
    } else {
      throw ex;
    }
  }

  async function onActivateTOTP({ password, pin }) {
    try {
      await client
        .post(`${url}/activate`, {
          password,
          pin,
        })
        .get("data");
      NotificationManager.success(
        t("Two-Factor Authentication successfully activated")
      );
      await refetch();
    } catch (ex) {
      handleException(ex);
    }
  }

  async function onDisableTOTP({ password }) {
    try {
      await client
        .post(`${url}/disable`, {
          password,
        })
        .get("data");
      NotificationManager.success(
        t("Two-Factor Authentication successfully disabled")
      );
      await refetch();
    } catch (ex) {
      handleException(ex);
    }
  }

  return (
    <Page title={t("Two-Factor Authentication")}>
      <p>
        <Trans>
          Increase your account's security by enabling Two-Factor Authentication
          (2FA)
        </Trans>
      </p>
      <hr />
      <FormProvider {...methods}>
        {enabled ? (
          activated ? (
            <>
              <p className="mb-2">
                <Trans>Status: Enabled</Trans>
              </p>
              <>
                <FormGroup>
                  <Label for="password">
                    <Trans>Current password</Trans>
                  </Label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    id="password"
                    ref={register({
                      required: true,
                    })}
                  />
                </FormGroup>
                <SubmitButton
                  color="danger"
                  onClick={handleSubmit(onDisableTOTP)}
                >
                  <Trans>Disable two-factor authentication</Trans>
                </SubmitButton>
              </>
            </>
          ) : (
            <>
              <div>
                <QRCodeSVG
                  value={provisioning_url}
                  className="img-thumbnail mb-2"
                />
                <p>
                  <Trans>
                    Scan the QR code above into Google Authenticator, Authy, or
                    another compatible app.
                  </Trans>
                  <br />
                  <Trans>
                    Or enter key manually: <code>{{ totp_secret }}</code>
                  </Trans>
                </p>
                <hr />
              </div>

              <FormGroup>
                <Label for="pin">
                  <Trans>Pin code</Trans>
                </Label>
                <input
                  className="form-control"
                  name="pin"
                  id="pin"
                  ref={register({
                    required: true,
                  })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">
                  <Trans>Current password</Trans>
                </Label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  id="password"
                  ref={register({
                    required: true,
                  })}
                />
                <FormText color="muted">
                  <Trans>
                    Your current password is required to register a two-factor
                    authentication app.
                  </Trans>
                </FormText>
              </FormGroup>
              <SubmitButton onClick={handleSubmit(onActivateTOTP)}>
                <Trans>Register with two-factor app</Trans>
              </SubmitButton>
            </>
          )
        ) : (
          <>
            <p className="mb-2">
              <Trans>Status: Disabled</Trans>
            </p>
            <SubmitButton onClick={handleSubmit(onEnableTOTP)}>
              <Trans>Enable two-factor authentication</Trans>
            </SubmitButton>
          </>
        )}
      </FormProvider>
    </Page>
  );
};
