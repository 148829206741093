import React, { useEffect, useState } from "react";
import classNames from "classnames";

export const Legend = ({ chartRef, className, ...props }) => {
  const legendItems = chartRef?.chartInstance?.legend?.legendItems;
  const [, refresh] = useState();

  // hack to force a refresh of the legend, works but should probably get fixed properly...
  useEffect(() => {
    setTimeout(() => {
      refresh((v) => !v);
    }, 0);
  }, []);

  if (!legendItems) return null;

  return (
    <ul
      className={classNames(
        "flex items-center flex-wrap justify-center my-3",
        className
      )}
    >
      {legendItems.map((i, idx) => (
        <li key={i.text} className={idx ? "ml-3" : ""}>
          <div className="flex text-gray-800 mb-1 text-xs items-center">
            <div
              className="mr-1"
              style={{
                width: 87 * 0.45,
                height: 29 * 0.45,
                backgroundColor: i.fillStyle,
              }}
            />
            <span>{i.text}</span>
          </div>
        </li>
      ))}
    </ul>
  );
};
