import React from "react";
import classNames from "classnames";

export const Title = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames(
        "text-center text-3xl mb-4 px-4 sm:px-0 font-semibold text-brand-dark",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
