import { selector } from "recoil";
import {userGroupAtom, tokenAtom, activeProjectAtom} from "./atoms";
import { createClient } from "./hooks/useClient";
import { camelCase, mapKeys } from "lodash";
import { AuthenticationFailedError } from "./errors";

export const userSelector = selector({
  key: "user",
  get: async ({ get }) => {
    const token = get(tokenAtom);

    if (!token) return null;

    let user = null;

    try {
      user = await createClient({ token }).get("profile").get("data");

      if (!user.has_roles) {
        throw new AuthenticationFailedError();
      }

      return user;
    } catch (ex) {
      const code = ex.response?.data?.code;

      if (code === "authentication_failed") {
        throw new AuthenticationFailedError(ex);
      }
      throw ex;
    }
  },
});

export const reportingStaticSelector = selector({
  key: "reportingStatic",
  get: async ({ get }) => {
    const token = get(tokenAtom);
    const project = get(activeProjectAtom);
    const result = await createClient({ token })
      .get("reporting/static")
      .get("data");

    const { merged_reference_intakes } = project ?? {};

    if (merged_reference_intakes) {
      result.male_reference_intake = merged_reference_intakes.male;
      result.female_reference_intake = merged_reference_intakes.female;
    }
    return mapKeys(result, (v, k) => camelCase(k));
  },
});

export const userGroupsSelector = selector({
  key: "userGroups",
  get: async ({ get }) => {
    const userGroup = get(userGroupAtom);

    const result = {
      isParticipant: userGroup === "participant",
      isResearcher: userGroup === "researcher",
      isTeacher: userGroup === "teacher",
      isAdmin: userGroup === "admin",
      isHealthcareUser: userGroup === "healthcare_user",
    };

    result.isBackend = !!(
      result.isResearcher ||
      result.isTeacher ||
      result.isAdmin
    );
    return result;
  },
});
