import { useFormContext } from "react-hook-form";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export function SubmitButton({ children, onClick, className }) {
  const context = useFormContext();
  const { formState } = context;
  const { isSubmitting } = formState;

  return (
    <Button onClick={onClick} className={className} disabled={isSubmitting}>
      {isSubmitting ? (
        <FontAwesomeIcon icon={faSpinner} spin className="mr-1" />
      ) : null}
      {children}
    </Button>
  );
}
