import React from "react";
import { Trans } from "react-i18next";
import { Table } from "reactstrap";
import { Field } from "formik";
import { Link } from "react-router-dom";
import { useTable } from "hooks";
import {
  Page,
  FiltersForm,
  DebouncedInput,
  BooleanIcon,
} from "components/common";

export const HCPUserList = () => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    setFilters,
    BulkDeleteButton,
  } = useTable({
    url: "hcp_users",
    resource: "HCP User",
  });

  return (
    <Page title={<Trans>HCP Users</Trans>} fluid>
      <TableSearch />
      <div className="flex justify-between mb-3">
        <AddNewButton />
        <BulkDeleteButton />
      </div>
      <TableContainer>
        <Table size="sm" striped hover>
          <thead>
            <tr>
              <th select />
              <th sortBy="healthcare_user__email">
                <Trans>Email address</Trans>
              </th>
              <th sortBy="healthcare_user__name">
                <Trans>Name</Trans>
              </th>
              <th sortBy="job_title">
                <Trans>Job title</Trans>
              </th>
              <th sortBy="user_group__name">
                <Trans>Organisation</Trans>
              </th>
              <th sortBy="healthcare_user__user__email">
                <Trans>User</Trans>
              </th>
              <th className="text-center">
                <Trans>Enabled</Trans>
              </th>
              <th sortBy="healthcare_user__activated" className="text-center">
                <Trans>Activated</Trans>
              </th>
            </tr>
            <FiltersForm
              persistKey="hcp_users"
              onChange={(x) => {
                setFilters({
                  ...x,
                });
              }}
              initialValues={{
                healthcare_user__email: "",
                healthcare_user__name: "",
                job_title: "",
                user_group__name: "",
              }}
            >
              <tr>
                <td />
                <td>
                  <Field
                    name="healthcare_user__email"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="healthcare_user__name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="job_title"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="user_group__name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td />
                <td />
                <td />
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${i.id}`}>{i.healthcare_user__email}</Link>
                </td>
                <td>{i.healthcare_user__name}</td>
                <td>{i.job_title}</td>
                <td>
                  <Link to={`../organisations/${i.user_group}`}>
                    {i.user_group__name}
                  </Link>
                </td>
                <td>
                  <Link to={`../../users/${i.healthcare_user__user}`}>
                    {i.healthcare_user__user__email}
                  </Link>
                </td>
                <td className="text-center">
                  <BooleanIcon test={i.healthcare_user__user__is_active} />
                </td>
                <td className="text-center">
                  <BooleanIcon test={i.healthcare_user__activated} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};
