import Promise from "bluebird";
import { throttle, get, startsWith } from "lodash";
import { NotificationManager } from "react-notifications";

window.Promise = Promise;
global.Promise = Promise;

const serverErrorNotification = throttle(
  () => NotificationManager.error("An unexpected error occurred"),
  2000
);
const clientErrorNotification = throttle(
  () =>
    NotificationManager.error(
      "An error occurred, please check the provided input and try again"
    ),
  2000
);
const connectionNotification = throttle(
  () =>
    NotificationManager.error(
      "You may be offline, please check your internet connection and try again"
    ),
  2000
);

window.addEventListener("unhandledrejection", (e, f) => {
  const reason = get(e, "detail.reason") || get(e, "reason");

  if (startsWith(get(reason, "config.baseURL"), "/api")) {
    if (reason.handled) {
      e.preventDefault();
      return;
    }

    const status = get(reason, "response.status", null);

    if ((status >= 500 && status < 600) || status === 404) {
      serverErrorNotification();
    } else if (status >= 400 && status < 500) {
      clientErrorNotification();
    } else if (status === null) {
      connectionNotification();
    } else {
      return;
    }
    e.preventDefault();
  }
});
