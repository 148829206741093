import React, { useMemo, useEffect, useState } from "react";
import { keys, reduce } from "lodash";
import { ReportContainer, ReportTabs } from "./components";
import { Bar } from "react-chartjs-2";
import { useRecoilValue } from "recoil";
import { reportingStaticSelector } from "selectors";
import { Trans } from "react-i18next";

export const DiaryStageAnalysis = ({
  data: dataProp,
  title = <Trans>Diary name analysis</Trans>,
}) => {
  const {
    maleReferenceIntake,
    femaleReferenceIntake,
    fieldUnits,
  } = useRecoilValue(reportingStaticSelector);
  const [active, setActive] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setActive(null);
    setOptions([]);
    const options = keys(dataProp);
    const active = options[0];
    setOptions(options);
    setActive(active);
  }, [dataProp]);

  const { data, labels } = useMemo(() => {
    if (!active || !dataProp) return { data: null, labels: null };

    return reduce(
      dataProp[active],
      (acc, i, label) => {
        acc.labels.push(label);
        acc.data.push(i);
        return acc;
      },
      { labels: [], data: [] }
    );
  }, [active, dataProp]);

  if (!data) return <ReportContainer title={title} loading />;

  const renderData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 600);
    gradient.addColorStop(0, "#28B1BF");
    gradient.addColorStop(1, "#5991C5");

    return {
      datasets: [
        {
          label: labels,
          data,
          backgroundColor: gradient,
        },
      ],
      labels,
    };
  };

  return (
    <ReportContainer title={title}>
      <ReportTabs options={options} active={active} setActive={setActive} />
      <Bar
        data={renderData}
        options={{
          annotation: {
            events: ["click"],
            annotations: [
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: maleReferenceIntake[active] ?? 0,
                borderColor: "#E1B073",
                borderWidth: 2,
              },
              {
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: femaleReferenceIntake[active] ?? 0,
                borderColor: "#8FBE76",
                borderWidth: 2,
              },
            ],
          },
          plugins: {
            labels: [
              {
                render: function (args) {
                  return (
                    Math.round(args.value) + " " + (fieldUnits[active] || "g")
                  );
                },
                fontSize: 14,
                fontColor: function (args) {
                  if (
                    args.dataset.label === "Ma" ||
                    args.dataset.label === "Fe"
                  ) {
                    return "transparent";
                  } else {
                    return "#333";
                  }
                },
              },
            ],
          },
          layout: {
            padding: {
              top: 30,
            },
          },
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: (value) => {
                    return `${value} ${fieldUnits[active] ?? "g"}`;
                  },
                  suggestedMin: 0,
                },
              },
            ],
          },
        }}
      />
      <ul className="text-left flex flex-wrap text-gray-600 text-sm mt-2">
        <li className="mr-2">
          <span style={{ color: "#8FBE76" }}>&bull;</span>{" "}
          <Trans>Reference intake of an average female</Trans>
        </li>
        <li>
          <span style={{ color: "#E1B073" }}>&bull;</span>{" "}
          <Trans>Reference intake of an average male</Trans>
        </li>
      </ul>
    </ReportContainer>
  );
};

export default DiaryStageAnalysis;
