import {Trans, useTranslation} from "react-i18next";
import {Table} from "reactstrap";
import {Field} from "formik";
import {FastInput, FormGroup} from "../../../components/common";
import { isFloat } from 'validator';
import {map} from "lodash";
import {useClient} from "../../../hooks";
import {useReportingStatic} from "../queries/useReportingStatic";

const validate = v => {
  if (v === null || v === undefined || v === "") return false;
  return !isFloat(`${v}`, {
    min: 0
  });
}

export const ReferenceIntake = ({ name, backOfPack }) => {
  const { t } = useTranslation();
  const client = useClient();
  const { data } = useReportingStatic(client);
  const { nutrient_names: _nutrient_names, field_units, nutrient_groups, male_reference_intake, female_reference_intake } = data ?? {};

  const nutrient_names = backOfPack ? nutrient_groups?.back_of_pack : _nutrient_names;

  if (!data) return null;



  return (
    <Table size="sm" borderless className="w-auto max-w-full">
      <thead>
        <tr>
          <th />
          <th>
            <Trans>Male</Trans>
          </th>
          <th>
            <Trans>Female</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
      {map(nutrient_names, (label, key) => (
        <tr key={key}>
          <td className="text-sm">
            {t(label)} ({field_units[key] ?? 'g'})
          </td>
          <td>
            <FormGroup name={`${name}.male.${key}`} className="mb-0">
              <Field placeholder={male_reference_intake[key]} as={FastInput} type="number" validate={validate} name={`${name}.male.${key}`} className="form-control form-control-sm " />
            </FormGroup>
          </td>
          <td>
          <FormGroup name={`${name}.female.${key}`} className="mb-0">
            <Field placeholder={female_reference_intake[key]} as={FastInput} validate={validate} type="number" name={`${name}.female.${key}`} className="form-control-sm form-control" />
          </FormGroup>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
  )
}