import React from "react";
import classNames from "classnames";

export const HorizontalRule = ({ className, ...props }) => {
  return (
    <div
      className={classNames("border-t border-gray-300", className)}
      {...props}
    />
  );
};
