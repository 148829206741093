import React from "react";
import { ModalHeader as BaseModalHeader } from "reactstrap";
import classNames from "classnames";

export const ModalHeader = ({ className, children, ...props }) => {
  return (
    <BaseModalHeader
      cssModule={{
        "modal-title": "modal-title w-full",
      }}
      tag="div"
      className={classNames(
        "text-center text-2xl font-semibold text-brand-dark",
        className
      )}
      {...props}
    >
      {children}
    </BaseModalHeader>
  );
};
