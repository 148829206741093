import React, { useEffect, useRef } from "react";
import { Field, useFormik, FormikProvider, FieldArray } from "formik";
import { FormGroup, SubmitButton, Button, Form } from "components/common";
import { useDiaryContext } from "./DiaryContext";
import classNames from "classnames";
import { CardBody, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { Trans } from "react-i18next";

export const MakeAList = () => {
  const {
    listSearch,
    setListSearch,
    listItems,
    setListItems,
  } = useDiaryContext();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      search: listSearch,
      items: listItems,
    },
    onSubmit,
  });
  const formikRef = useRef();
  formikRef.current = formik;

  const { setFieldValue, values, setFieldTouched } = formik;
  const { items } = values;
  const searchRef = useRef();

  function onSearch() {
    navigate("../all", {
      state: {
        fromMakeAList: true,
      },
    });
  }

  useEffect(() => {
    setListItems(items);
  }, [items, setListItems]);

  async function onSubmit({ search }) {
    if (!search) return;

    setFieldValue("items", [...values.items, search], false);
    setFieldValue("search", "");
  }

  useEffect(() => {
    searchRef.current.focus();
  }, [searchRef]);

  return (
    <div>
      <FormikProvider value={formik}>
        <Form>
          <p className="text-center mb-2 opacity-75">
            <Trans>Create a list of items to search for</Trans>
          </p>
          <FormGroup className="relative">
            <Field
              name="search"
              innerRef={searchRef}
              onBlur={(e) => {
                const { name, value } = e.target;
                setListSearch(value);
                setFieldTouched(name, true);
              }}
              className="form-control form-control-lg pr-12"
            />
            <SubmitButton
              type="submit"
              size="lg"
              color="link"
              className="absolute right-0 inset-y-0 h-full"
            >
              <FontAwesomeIcon
                size="lg"
                icon={faPlusCircle}
                className="text-dark"
              />
            </SubmitButton>
          </FormGroup>
        </Form>
        {values.items.length ? (
          <>
            <FieldArray name="items">
              {({ remove }) => (
                <Card className="border-brand-light">
                  {values.items.map((i, idx) => (
                    <CardBody
                      role="button"
                      className={classNames(
                        "items-center flex py-3",
                        idx ? "border-t border-brand-light" : ""
                      )}
                      onClick={() => remove(idx)}
                      key={idx}
                    >
                      <span className="flex-1 font-medium text-brand-dark">
                        {i}
                      </span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        size="lg"
                        className="text-red-500"
                      />
                    </CardBody>
                  ))}
                </Card>
              )}
            </FieldArray>
            <div className="flex justify-end mt-4">
              <Button
                color="link"
                className="mr-2"
                onClick={() => {
                  setFieldValue("items", []);
                }}
              >
                <Trans>Clear</Trans>
              </Button>
              <Button onClick={onSearch}>
                <Trans>Search for these items</Trans>
              </Button>
            </div>
          </>
        ) : null}
      </FormikProvider>
    </div>
  );
};

export default MakeAList;
