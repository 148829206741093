import React from "react";
import { MedicalConditions } from "./MedicalConditions";
import { Nutrients } from "./Nutrients";

export const HealthcareSettings = () => {
  return (
    <>
      <MedicalConditions />
      <Nutrients />
    </>
  );
};
