import { useForm, FormProvider } from "react-hook-form";
import {
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Trans } from "react-i18next";
import { SubmitButton } from ".";
import {useEffect} from "react";

export const TwoFactorAuthenticationModal = ({ onSubmit, toggle }) => {
  const methods = useForm({
    defaultValues: {
      totp: ""
    },
    mode: "all"
  });
  const { handleSubmit, register, setError } = methods;

  const totp = register({
    required: true,
  });

  useEffect(() => {
    setError('totp', {
      shouldFocus: true,
    })
  }, [setError]);

  return (
    <FormProvider {...methods}>
      <ModalHeader toggle={toggle}>
        <Trans>Two-Factor Authentication</Trans>
      </ModalHeader>
      <ModalBody>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="totp">
              <Trans>Two-factor authentication code</Trans>
            </Label>
            <input
              type="text"
              ref={totp}
              className="form-control"
              name="totp"
              id="totp"
            />
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter>
        <SubmitButton onClick={handleSubmit(onSubmit)}>
          <Trans>Verify code</Trans>
        </SubmitButton>
      </ModalFooter>
    </FormProvider>
  );
};
