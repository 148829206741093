import {ClientProvider, useClient} from "./useClient";
import {useMemo} from "react";
import {tour_url_dummy_data_mapping} from "../features/tour/constants";
import {isFunction} from "lodash";
import {useTourContext} from "../features/tour";

export const TourClientProvider = ({ children }) => {
  const { isTourActive } = useTourContext();

  const tourClient = useTourClient();
  const client = useClient();

  return (
    <ClientProvider client={isTourActive ? tourClient : client}>
      {children}
    </ClientProvider>
  )
}

const getDummyData = (url, config) => {
  let data = tour_url_dummy_data_mapping[url];
  if (isFunction(data)) data = data(config);

  return data ? {
    data
  } : null;
}

export const useTourClient = () => {
    const _client = useClient();

    return useMemo(() => {
      const get = _client.get;
      const post = _client.post;

      _client.get = async (...args) => {
        return getDummyData(...args) ?? get(...args);
      }

      _client.post = async (...args) => {
        return getDummyData(...args) ?? post(...args);
      }

      return _client;
    }, [_client]);
}