import Joyride from "react-joyride";

export const TourJoyride = ({ steps, callback, run }) => {
  return (
    <Joyride
      steps={steps}
      debug
      run={run}
      callback={callback}
      continuous
      showProgress
      disableCloseOnEsc
      disableOverlayClose
      hideCloseButton
      styles={{
        options: {
          primaryColor: "#204b7b",
        },
        tooltipContainer: {
          textAlign: "left",
        },
        tooltip: {
          width: 600,
        },
        tooltipTitle: {
          color: "#204b7b",
          fontWeight: "bold",
        },
        tooltipContent: {
          padding: "20px 0px",
        },
      }}
    ></Joyride>
  );
};
