import React from "react";
import { useField } from "formik";
import CKEditor from "ckeditor4-react";

export const MyCKEditor = ({ name, validate }) => {
  const [{ value }, , { setValue }] = useField({
    name,
    validate,
  });

  return (
    <div className="prose prose-sm">
      <CKEditor
        config={{
          versionCheck: false
        }}
        data={value}
        onChange={(e) => {
          setValue(e.editor.getData());
        }}
      />
    </div>
  );
};

export default MyCKEditor;
