import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useClient } from "hooks";
import { Trans, useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { FormGroup, SubmitButton, Page, Select } from "components/common";
import { Field, FieldArray, FormikProvider, useField, useFormik } from "formik";
import { NotificationManager } from "react-notifications";
import { FastInput } from "../../components/common";
import { validateInt } from "../../services/validators";
import FormCheck from "../../components/common/FormCheck";

const url = "nutrients";

const Rows = ({ title, name, type, units }) => {
  const [{ value }] = useField({
    name,
  });

  return (
    <Page title={title} flat>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <Table size="sm">
            <thead>
              <tr>
                <th>
                  <Trans>Key</Trans>
                </th>
                <th>
                  <Trans>Name</Trans>
                </th>
                <th>
                  <Trans>Unit</Trans>
                </th>
                <th>
                  <Trans>Decimal places</Trans>
                </th>
                <th>
                  <Trans>Patient mandatory target?</Trans>
                </th>
                <th>
                  <Trans>Patient default target?</Trans>
                </th>
                <th>
                  <Trans>Scale targets?</Trans>
                </th>
                <th>
                  <Trans>DOE Lower % Buffer</Trans>
                </th>
                <th>
                  <Trans>DOE Upper % Buffer</Trans>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {value.map((i, idx) => {
                const n = (v) => `${name}.${idx}.${v}`;
                return (
                  <tr key={i.id || i._id}>
                    <td>
                      <FormGroup className="mb-0" name={n("key")}>
                        <Field
                          as={FastInput}
                          name={n("key")}
                          readOnly
                          className="form-control"
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup className="mb-0" name={n("name")}>
                        <Field
                          as={FastInput}
                          name={n("name")}
                          validate={(v) => !v}
                          className="form-control"
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup
                        className="mb-0"
                        name={n("units")}
                        style={{ minWidth: "10rem" }}
                      >
                        <Select
                          options={units}
                          simpleValue
                          isDisabled
                          readOnly
                          name={n("units")}
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup
                        className="mb-0 w-24"
                        name={n("decimal_places")}
                      >
                        <Field
                          as={FastInput}
                          name={n("decimal_places")}
                          validate={validateInt({ max: 2 })}
                          className="form-control"
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormCheck
                        input={
                          <Field
                            type="checkbox"
                            name={n("patient_mandatory_target")}
                            className="form-check-input"
                          />
                        }
                      />
                    </td>
                    <td>
                      <FormCheck
                        input={
                          <Field
                            type="checkbox"
                            name={n("patient_default_target")}
                            className="form-check-input"
                          />
                        }
                      />
                    </td>
                    <td>
                      <FormCheck
                        input={
                          <Field
                            type="checkbox"
                            name={n("scale_targets")}
                            className="form-check-input"
                          />
                        }
                      />
                    </td>
                    <td>
                      <FormGroup
                        className="mb-0 w-24"
                        name={n("lower_doe_percentage_buffer")}
                      >
                        <Field
                          as={FastInput}
                          name={n("lower_doe_percentage_buffer")}
                          validate={validateInt({ max: 100, min: 0 })}
                          className="form-control"
                        />
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup
                        className="mb-0 w-24"
                        name={n("upper_doe_percentage_buffer")}
                      >
                        <Field
                          as={FastInput}
                          name={n("upper_doe_percentage_buffer")}
                          validate={validateInt({ max: 100, min: 0 })}
                          className="form-control"
                        />
                      </FormGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </FieldArray>
    </Page>
  );
};

export const Nutrients = () => {
  const client = useClient();
  const { t } = useTranslation();

  const { data: units } = useQuery("units", () =>
    client.get(`${url}/units`).get("data")
  );
  const { data, refetch } = useQuery(
    url,
    async () => {
      const data = await client.get(url).get("data");
      const macronutrients = data.filter((i) => i.is_macronutrient);
      const micronutrients = data.filter((i) => i.is_micronutrient);

      return {
        macronutrients,
        micronutrients,
      };
    },
    {
      suspense: true,
    }
  );
  const { micronutrients, macronutrients } = data;

  const initialValues = useMemo(() => ({ micronutrients, macronutrients }), [
    micronutrients,
    macronutrients,
  ]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });

  async function onSubmit({ micronutrients, macronutrients }) {
    await client.post(`${url}/bulk`, [...micronutrients, ...macronutrients]);
    await refetch({ throwOnError: true });
    NotificationManager.success(t("Saved successfully"));
  }

  return (
    <Page title={t("Nutrients")} fluid>
      <FormikProvider value={formik}>
        <Rows
          title={t("Macronutrients")}
          units={units}
          name="macronutrients"
          type="macronutrient"
        />
        <Rows
          title={t("Micronutrients")}
          units={units}
          name="micronutrients"
          type="micronutrient"
        />
        <div className="flex justify-end">
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};
