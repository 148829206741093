import React, { createContext, useContext } from "react";
import { NavItem, NavLink } from "reactstrap";

const TabContext = createContext();

export const TabContainer = ({ activeTab, setActiveTab, ...props }) => {
  return <TabContext.Provider value={{ activeTab, setActiveTab }} {...props} />;
};

export const Tab = ({ tabId, children }) => {
  const { activeTab, setActiveTab } = useContext(TabContext);

  return (
    <NavItem>
      <NavLink
        className={tabId === activeTab ? "active" : ""}
        href="#"
        onClick={() => setActiveTab(tabId)}
      >
        {children}
      </NavLink>
    </NavItem>
  );
};
