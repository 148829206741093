import React from "react";
import classNames from "classnames";

export const BackgroundWave = ({ className, ...props }) => {
  return (
    <div
      className={classNames("background-wave-container md:block", className)}
      {...props}
    />
  );
};
