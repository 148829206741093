import { useRef, useEffect } from "react";
import { useLocation } from "react-router";

const REACT_APP_GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const GoogleAnalytics = () => {
  const location = useLocation();
  const previousLocation = useRef();

  useEffect(() => {
    if (!REACT_APP_GA_MEASUREMENT_ID) return;

    const previous = previousLocation.current;
    previousLocation.current = location;

    if (!previous) return;

    window.gtag("config", REACT_APP_GA_MEASUREMENT_ID, {
      page_path: location.pathname,
    });
  }, [location, previousLocation]);

  return null;
};
