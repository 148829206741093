import React, { useState, useCallback } from "react";
import { debounce } from "lodash";

import { FormGroup } from "components/common";

import { useTranslation } from "react-i18next";

export const TableSearch = ({ onChange, debounce: debounceTimeout = 1000 }) => {
  const [value, setValue] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(onChange, debounceTimeout), [
    debounceTimeout,
    onChange,
  ]);

  const { t } = useTranslation();

  return (
    <FormGroup name="search" component="div">
      <input
        name="search"
        type="text"
        className="form-control"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          debouncedOnChange(e.target.value);
        }}
        placeholder={t("Search") + "..."}
      />
    </FormGroup>
  );
};

export default TableSearch;
