import React from "react";
import { keys } from "lodash";
import { Button } from "reactstrap";
import DeleteModal from "./DeleteModal";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export const BulkDeleteButton = ({
  toggleModal,
  selected,
  url,
  onDeleted,
  isModalOpen,
  buttonProps,
  modalProps,
  readOnly,
  client,
  ...props
}) => {
  const numSelected = keys(selected).length;
  const { t } = useTranslation();

  if (readOnly) return null;

  return (
    <div {...props}>
      <Button
        onClick={toggleModal}
        disabled={!numSelected}
        color="danger"
        {...buttonProps}
      >
        <FontAwesomeIcon icon={faTrash} className="mr-1" />{" "}
        {t("Remove selected")} {!!numSelected && <span>({numSelected})</span>}
      </Button>
      <DeleteModal
        url={url}
        client={client}
        onDeleted={onDeleted}
        isOpen={isModalOpen}
        toggle={toggleModal}
        selected={selected}
      />
    </div>
  );
};

export default BulkDeleteButton;
