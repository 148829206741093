import React, { useState } from "react";
import classNames from "classnames/dedupe";
import { useUserContext } from "./hooks";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  NavLink as BaseNavLink,
  useMatch,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom";
import logo from "./Pages/assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { LanguageSelector } from "components/common";
import { useRecoilValue } from "recoil";
import { activeProjectAtom, localeAtom } from "./atoms";
import { Trans } from "react-i18next";

const NavLink = ({
  to: toProp = "",
  menu,
  mobile,
  test,
  className: classNameProp,
  children,
  end = false,
  ...props
}) => {
  const { locale } = useParams();
  const to = toProp[0] === "/" ? toProp : `/${locale}/${toProp}`;
  let match = useMatch({
    path: to,
    end,
  });
  if (!to) match = null;

  const [isOpen, setIsOpen] = useState(false);

  if (test !== undefined && !test) return null;

  const className = classNames(
    "px-3 py-2 rounded-md font-medium text-white",
    match ? "bg-brand-dark" : "",
    mobile ? "text-base block" : "text-sm"
  );

  if (menu) {
    return (
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen((v) => !v)}>
        <DropdownToggle
          color="link"
          className={classNames(
            className,
            "no-underline",
            mobile ? "text-left w-full" : ""
          )}
        >
          {children}
        </DropdownToggle>
        {menu}
      </Dropdown>
    );
  }

  return (
    <BaseNavLink className={className} to={to} {...props}>
      {children}
    </BaseNavLink>
  );
};

const NavItems = ({ mobile }) => {
  const {
    isBackend,
    isAdmin,
    isTeacher,
    isParticipant,
    isResearcher,
  } = useUserContext();
  const { locale } = useParams();
  const activeProject = useRecoilValue(activeProjectAtom);

  return (
    <>
      <NavLink to="users" mobile={mobile} test={isAdmin}>
        <Trans>Users</Trans>
      </NavLink>
      <NavLink to="organisations" mobile={mobile} test={isAdmin}>
        <Trans>Organisations</Trans>
      </NavLink>
      <NavLink to="projects" mobile={mobile} test={isBackend}>
        <Trans>Projects</Trans>
      </NavLink>
      <NavLink
        to="researchers"
        mobile={mobile}
        test={isAdmin}
        menu={
          <DropdownMenu>
            <DropdownItem tag={Link} to={`/${locale}/researchers`}>
              <Trans>Researchers</Trans>
            </DropdownItem>
            <DropdownItem tag={Link} to={`/${locale}/researchers/invited`}>
              <Trans>Invited researchers</Trans>
            </DropdownItem>
          </DropdownMenu>
        }
      >
        <Trans>Researchers</Trans>
      </NavLink>
      <NavLink
        to="teachers"
        mobile={mobile}
        test={isAdmin}
        menu={
          <DropdownMenu>
            <DropdownItem tag={Link} to={`/${locale}/teachers`}>
              <Trans>Teachers</Trans>
            </DropdownItem>
            <DropdownItem tag={Link} to={`/${locale}/teachers/invited`}>
              <Trans>Invited Teachers</Trans>
            </DropdownItem>
          </DropdownMenu>
        }
      >
        <Trans>Teachers</Trans>
      </NavLink>
      <NavLink
        to="participants"
        mobile={mobile}
        test={isBackend}
        menu={
          <DropdownMenu>
            <DropdownItem tag={Link} to={`/${locale}/participants/accepted`}>
              <Trans>Accepted participants</Trans>
            </DropdownItem>
            <DropdownItem tag={Link} to={`/${locale}/participants/invited`}>
              <Trans>Invited participants</Trans>
            </DropdownItem>
          </DropdownMenu>
        }
      >
        <Trans>Participants</Trans>
      </NavLink>
      <NavLink
        to="health"
        mobile={mobile}
        test={isAdmin}
        menu={
          <DropdownMenu>
            <DropdownItem tag={Link} to={`/${locale}/hc/hcp_users`}>
              <Trans>HCP Users</Trans>
            </DropdownItem>
            <DropdownItem
              tag={Link}
              to={`/${locale}/hc/healthcare_organisations`}
            >
              <Trans>Organisations</Trans>
            </DropdownItem>
            <DropdownItem tag={Link} to={`/${locale}/hc/settings`}>
              <Trans>Settings</Trans>
            </DropdownItem>
            <DropdownItem tag={Link} to={`/${locale}/hc/dashboard`}>
              <Trans>Dashboard</Trans>
            </DropdownItem>
          </DropdownMenu>
        }
      >
        <Trans>Healthcare</Trans>
      </NavLink>
      <NavLink to="teacher_project_defaults" mobile={mobile} test={isTeacher}>
        <Trans>Project defaults</Trans>
      </NavLink>

      <NavLink to="participant/diary" mobile={mobile} test={isParticipant}>
        <Trans>Food Diary</Trans>
      </NavLink>
      {activeProject?.show_welcome ? (
        <NavLink to="participant" end mobile={mobile} test={isParticipant}>
          <Trans>Project Instructions</Trans>
        </NavLink>
      ) : null}
      <NavLink to="participant/help" mobile={mobile} test={isParticipant}>
        <Trans>Help?</Trans>
      </NavLink>
      <NavLink to="database_import" mobile={mobile} test={isAdmin}>
        <Trans>Database import</Trans>
      </NavLink>
      <NavLink to="search_metrics" mobile={mobile} test={isAdmin}>
        <Trans>Search metrics</Trans>
      </NavLink>
      <NavLink to="apps" mobile={mobile} test={isAdmin}>
        <Trans>Apps</Trans>
      </NavLink>
      <NavLink to="support" mobile={mobile} test={isResearcher || isTeacher}>
        <Trans>Support</Trans>
      </NavLink>
    </>
  );
};

const Nav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout, isAdmin } = useUserContext();
  const activeProject = useRecoilValue(activeProjectAtom);
  const locale = useRecoilValue(localeAtom);
  const navigate = useNavigate();

  const onLogout = () => {
    logout();
    if (activeProject?.is_student_project) {
      navigate(`/${locale}/participant/teaching/start`);
    } else {
      navigate(`/${locale}/login`);
    }
  };

  return (
    <nav className="bg-brand-primary">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <div className="flex items-center">
                  <img className="h-10 w-auto" src={logo} alt="Logo" />
                  {activeProject?.logo__name ? (
                    <img
                      src={`/api/projects/${activeProject.id}/logo?height=40&type=height`}
                      className="h-10 w-auto ml-2"
                      alt="Project logo"
                    />
                  ) : null}
                </div>
              </Link>
            </div>
            <div className="hidden xl:block">
              <div className="ml-10 flex space-x-4">
                <NavItems />
              </div>
            </div>
          </div>
          <div className="hidden xl:block">
            <div className="ml-4 flex items-center xl:ml-6">
              <LanguageSelector />
              {user ? (
                <UncontrolledDropdown className="ml-3" inNavbar>
                  <DropdownToggle
                    color="link"
                    className="text-white btn-sm p-0"
                  >
                    <FontAwesomeIcon icon={faUserCircle} size="2x" />
                  </DropdownToggle>
                  <DropdownMenu flip={false} right>
                    {isAdmin ? (
                      <>
                        <Link to="/two_factor_authentication" className="dropdown-item">
                          <Trans>
                            Two Factor Authentication
                          </Trans>
                        </Link>
                        <DropdownItem divider />
                      </>
                    ) : null}
                    <DropdownItem as="button" onClick={onLogout}>
                      <Trans>Logout</Trans>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
            </div>
          </div>

          {user ? (
            <div className="-mr-2 flex xl:hidden">
              <button
                className="btn btn-link inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-brand-dark"
                onClick={() => setIsOpen((v) => !v)}
              >
                <svg
                  className={classNames("h-6 w-6", isOpen ? "hidden" : "block")}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={classNames("h-6 w-6", isOpen ? "block" : "hidden")}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classNames(isOpen ? "block xl:hidden" : "hidden")}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <NavItems mobile />
        </div>
        {user ? (
          <div className="pt-4 pb-3 border-t border-brand-dark">
            <div className="flex items-center px-5 space-x-3">
              <div className="flex-shrink-0">
                <FontAwesomeIcon
                  className="h-10 w-10 text-white"
                  icon={faUserCircle}
                />
              </div>
              <div className="space-y-1">
                <div className="text-base font-medium leading-none text-white">
                  {user.full_name}
                </div>
                <div className="text-sm font-medium leading-none text-brand-dark">
                  {user.email}
                </div>
              </div>
              <LanguageSelector className="ml-auto" />
            </div>
            <div className="mt-3 px-2 space-y-1">
              <NavLink
                mobile
                to="logout"
                onClick={(e) => {
                  e.preventDefault();
                  onLogout();
                }}
              >
                <Trans>Logout</Trans>
              </NavLink>
            </div>
          </div>
        ) : null}
      </div>
    </nav>
  );
};

export default Nav;
