import { useClient } from "../../../hooks";
import { useMutation } from "react-query";

export const useDeleteTour = ({ config } = {}) => {
  const client = useClient();

  return useMutation({
    mutationFn: (payload) => client.delete(`tour/0`),
    ...config,
  });
};
