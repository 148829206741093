import React from "react";
import { useTable, useModal, useClient, useUserContext } from "hooks";
import { Field, useFormik, FormikProvider } from "formik";
import {
  Page,
  DebouncedInput,
  FiltersForm,
  BooleanIcon,
  SubmitButton,
  Button,
} from "components/common";
import { Table } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "services/formatters";
import { NotificationManager } from "react-notifications";
import { Trans } from "react-i18next";

const TakeControlButton = ({ onSubmit, ...props }) => {
  const form = useFormik({
    onSubmit,
    initialValues: {},
  });

  return (
    <FormikProvider value={form}>
      <SubmitButton {...props}>
        <Trans>Take control</Trans>
      </SubmitButton>
    </FormikProvider>
  );
};

export const List = () => {
  const url = "participants/accepted";
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableDataFetch,
    data,
    setFilters,
    refreshData,
  } = useTable({
    url,
    filters: {
      exclude_expired: true,
    },
  });
  const { Modal, ModalBody, isOpen, setIsOpen, ModalFooter } = useModal();
  const { impersonate } = useUserContext();
  const navigate = useNavigate();
  const client = useClient();
  const reactivateLinkForm = useFormik({
    onSubmit: onReactivateLinkSubmit,
    initialValues: {},
  });

  async function onReactivateLinkSubmit({ id }) {
    await client.post(`${url}/${id}/reactivate_link`);
    NotificationManager.success("Project link successfully reactivated");
    setIsOpen(false);
    refreshData();
  }

  return (
    <Page title="Accepted participants" fluid translateTitle>
      {isOpen ? (
        <Modal>
          <FormikProvider value={reactivateLinkForm}>
            <ModalBody>
              Are you sure you want to reactivate the project link for this
              participant?
            </ModalBody>
            <ModalFooter>
              <SubmitButton>OK</SubmitButton>
            </ModalFooter>
          </FormikProvider>
        </Modal>
      ) : null}
      <TableContainer>
        <Table size="sm" hover striped>
          <thead>
            <tr>
              <th sortBy="user__first_name">
                <Trans>Name</Trans>
              </th>
              <th sortBy="user__email">
                <Trans>Email address</Trans>
              </th>
              <th sortBy="project__title">
                <Trans>Project</Trans>
              </th>
              <th sortBy="participant_date">
                <Trans>Date</Trans>
              </th>
              <th sortBy="is_diary_submitted">
                <Trans>Is diary submitted?</Trans>
              </th>
              <th sortBy="respondent_id">
                <Trans>Respondent ID</Trans>
              </th>
              <th />
            </tr>
            <FiltersForm
              persistKey="acceptedParticipants"
              onChange={setFilters}
              initialValues={{
                user__full_name: "",
                user__email: "",
                project__title: "",
                respondent_id: "",
              }}
            >
              <tr>
                <td>
                  <Field
                    name="user__full_name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="user__email"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="project__title"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td />
                <td />
                <td>
                  <Field
                    name="respondent_id"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td />
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td>
                  <Link to={`../../users/${i.user}`}>{i.user__full_name}</Link>
                </td>
                <td className="text-sm break-all">{i.user__email}</td>
                <td>
                  <Link to={`../../projects/${i.project}`}>
                    {i.project__title}
                  </Link>
                </td>
                <td>{formatDate(i.participant_date)}</td>
                <td className="text-center">
                  <BooleanIcon test={i.is_diary_submitted} />
                </td>
                <td className="text-sm break-all">{i.respondent_id}</td>
                <td>
                  <TakeControlButton
                    size="sm"
                    className="mr-2 whitespace-no-wrap mb-px"
                    onSubmit={async () => {
                      const { token } = await client
                        .post(`${url}/${i.id}/take_control`)
                        .get("data");
                      impersonate(token, i.id);
                      navigate("../../participant");
                    }}
                  />
                  {i.is_diary_submitted ? (
                    <Button
                      className="whitespace-no-wrap mb-px"
                      onClick={() => {
                        reactivateLinkForm.setFieldValue("id", i.id);
                        setIsOpen(true);
                      }}
                      size="sm"
                    >
                      <Trans>Reactivate link</Trans>
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};

export default List;
