import React, { useCallback, useEffect, useRef } from "react";
import useModal from "./useModal";
import useMatch from "./useMatch";
import { useNavigate } from "react-router-dom";

export const useRouteModal = (base) => {
  const modal = useModal();
  const match = useMatch(base, { startsWith: true });
  const mounted = useRef();
  const navigate = useNavigate();

  const { setIsOpen, Modal: BaseModal } = modal;

  useEffect(() => {
    if (match) {
      setIsOpen(true);
    }
  }, [match, setIsOpen]);

  function onMount() {
    mounted.current = true;
  }

  const Modal = useCallback(
    (props) => {
      return (
        <BaseModal
          onClosed={() => {
            navigate(".");
          }}
          {...props}
        />
      );
    },
    [navigate]
  );

  useEffect(onMount, []);

  return { ...modal, Modal, isOpen: match };
};

export default useRouteModal;
