import React from "react";
import { FormGroup, CKEditor } from "components/common";

export const ContentField = () => {
  return (
    <>
      <FormGroup
        label="Welcome message"
        name="project_content.welcome_message"
        required
      >
        <CKEditor name="project_content.welcome_message" />
      </FormGroup>
      <hr />
      <FormGroup
        label="Project instructions"
        name="project_content.getting_started"
        required
      >
        <CKEditor name="project_content.getting_started" />
      </FormGroup>
      <hr />
      <FormGroup label="About" name="project_content.about" required>
        <CKEditor name="project_content.about" />
      </FormGroup>
      <hr />
      <FormGroup label="Contact us" name="project_content.contact_us" required>
        <CKEditor name="project_content.contact_us" />
      </FormGroup>
    </>
  );
};

export default ContentField;
