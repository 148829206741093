import React, { useState } from "react";
import { Page, FormGroup, SubmitButton, Select } from "components/common";
import { Field, useFormik, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { validateEmail } from "services/validators";
import { Trans } from "react-i18next";

export const Edit = () => {
  const formik = useFormik({
    onSubmit,
  });
  const [projects, setProjects] = useState();
  const { resetForm, values } = formik;
  const { id } = useParams();
  const isNew = id === "new";
  const navigate = useNavigate();
  const client = useClient();
  const url = "participants/invited";
  const title = `${isNew ? "New" : "Edit"} Invited Participant`;

  async function onSubmit(x) {
    const { project } = await (isNew
      ? client.post(url, x).get("data")
      : client.put(`${url}/${id}`, x).get("data"));
    navigate(`../../../projects/${project}?tab=participants`);
    NotificationManager.success("Invited participant successfully saved");
  }

  useAsyncEffect(async () => {
    const [values, projects] = await Promise.all([
      isNew
        ? {
            first_name: "",
            last_name: "",
            email: "",
            respondent_id: "",
            project: null,
          }
        : client.get(`${url}/${id}`).get("data"),
      client.get("projects/basic?ordering=title").get("data"),
    ]);
    setProjects(projects);
    resetForm({ values });
  }, []);

  if (!values) return null;

  return (
    <Page title={title}>
      <FormikProvider value={formik}>
        <FormGroup label="First name" required name="first_name">
          <Field
            name="first_name"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Last name" required name="last_name">
          <Field
            name="last_name"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Email" required name="email">
          <Field
            name="email"
            validate={validateEmail()}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Respondent ID" name="respondent_id">
          <Field
            name="respondent_id"
            className="form-control"
            readOnly={!!values.id}
          />
        </FormGroup>
        {!values.id ? (
          <FormGroup label="Project" name="project">
            <Select
              name="project"
              validate={(v) => !v}
              options={projects}
              simpleValue
              labelKey="title"
              valueKey="id"
            />
          </FormGroup>
        ) : null}

        <div className="flex justify-end">
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};

export default Edit;
