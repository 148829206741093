import React, {useLayoutEffect, useState} from "react";
import { ButtonGroup, ModalBody } from "reactstrap";
import Search from "./Search";
import Recent from "./Recent";
import FoodEntry from "./FoodEntry";
import Recipes from "./Recipes";
import EditRecipe from "./EditRecipe";
import DeleteRecipe from "./DeleteRecipe";
import RecipeEntry from "./RecipeEntry";
import { Trans } from "react-i18next";
import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import { useModalContext } from "hooks";
import MakeAList from "./MakeAList";
import { ModalHeader } from "./components/ModalHeader";
import { NavLink } from "./components/NavLink";
import {useDiaryContext} from "./DiaryContext";

const SearchOutlet = () => {
  const { toggle } = useModalContext();
  return (
    <>
      <ModalHeader toggle={toggle}>
        <Trans>Search</Trans>
      </ModalHeader>
      <ModalBody>
        <div className="flex justify-center mb-4">
          <ButtonGroup className="max-w-full w-full">
            <NavLink to="all">
              <Trans>Search</Trans>
            </NavLink>
            <NavLink to="recent">
              <Trans>Recent</Trans>
            </NavLink>
            <NavLink to="recipes">
              <Trans>Recipes</Trans>
            </NavLink>
            <NavLink to="make_a_list">
              <Trans>Make a list</Trans>
            </NavLink>
          </ButtonGroup>
        </div>
        <Outlet />
      </ModalBody>
    </>
  );
};

export const SearchModal = () => {
  const { setSearch } = useDiaryContext();
  const [init, setInit] = useState();

  useLayoutEffect(() => {
    setSearch("");
    setInit(true);
  }, [setSearch, setInit])

  if (!init) return null;

  return (
    <div className="brand-form-controls">
      <Routes basename="m">
        <Route path="food_entry/:id" element={<FoodEntry />} />
        <Route path="recipe_entry/:id" element={<RecipeEntry />} />
        <Route path="search/recipes/:id/delete" element={<DeleteRecipe />} />
        <Route path="recipes/:id" element={<EditRecipe />} />
        <Route path="search" element={<SearchOutlet />}>
          <Route path="all" element={<Search />} />
          <Route path="recent" element={<Recent />} />
          <Route path="recipes/*" element={<Recipes />} />
          <Route path="make_a_list" element={<MakeAList />} />
        </Route>
        <Navigate to="search/all" replace />
      </Routes>
    </div>
  );
};

export default SearchModal;
