import React, { useState } from "react";
import { useTable, useClient, useOnMount } from "hooks";
import { Field } from "formik";
import { Page, DebouncedInput, FiltersForm, Select } from "components/common";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export const List = () => {
  const url = "teachers/invited";
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
    setFilters,
  } = useTable({
    url,
    resource: "",
  });
  const client = useClient();
  const [organisations, setOrganisations] = useState();

  useOnMount(async () => {
    const organisations = await client.get("organisations").get("data");
    setOrganisations(organisations);
  });

  return (
    <Page title="Invited Teachers" fluid>
      <TableSearch />
      <div className="flex justify-end mb-3">
        <BulkDeleteButton className="mr-2" />
        <AddNewButton />
      </div>
      <TableContainer>
        <Table size="sm" hover striped>
          <thead>
            <tr>
              <th select />
              <th sortBy="invitation__first_name">
                <Trans>Forename</Trans>
              </th>
              <th sortBy="invitation__last_name">
                <Trans>Surname</Trans>
              </th>
              <th sortBy="invitation__email">
                <Trans>Email address</Trans>
              </th>
              <th sortBy="organisation__name">
                <Trans>Organisation</Trans>
              </th>
              <th />
            </tr>
            <FiltersForm
              persistKey="invitedTeachers"
              onChange={({ organisations, ...x }) => {
                setFilters({
                  ...x,
                  organisations: organisations
                    ? organisations.map((i) => i.id).join(",")
                    : undefined,
                });
              }}
              initialValues={{
                invitation__first_name: "",
                invitation__last_name: "",
                invitation__email: "",
                organisations: "",
              }}
            >
              <tr>
                <td />
                <td>
                  <Field
                    name="invitation__first_name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="invitation__last_name"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Field
                    name="invitation__email"
                    className="form-control form-control-sm"
                    as={DebouncedInput}
                  />
                </td>
                <td>
                  <Select
                    name="organisations"
                    className="text-xs"
                    isMulti
                    options={organisations}
                    labelKey="name"
                    valueKey="id"
                  />
                </td>
                <td />
              </tr>
            </FiltersForm>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${i.id}`}>{i.invitation__first_name}</Link>
                </td>
                <td>{i.invitation__last_name}</td>
                <td>{i.invitation__email}</td>
                <td>
                  <Link to={`../../organisations/${i.organisation}`}>
                    {i.organisation__name}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="flex justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch fetchOnMount={false} />
    </Page>
  );
};

export default List;
