import React, { useState } from "react";
import { Row, Col, ModalBody } from "reactstrap";
import {
  useAsyncEffect,
  useParticipantClient,
  useSearchParams,
  useModalContext,
} from "hooks";
import { useFormik, FormikProvider, Field } from "formik";
import { FormGroup, Select, SubmitButton } from "components/common";
import { useTranslation, Trans } from "react-i18next";
import { map, range } from "lodash";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useDiaryContext } from "./DiaryContext";
import { validateDecimal } from "services/validators";
import classNames from "classnames";
import { useParticipantContext } from "../../contexts/useParticipantContext";
import { BackLinkContainer } from "./BackLink";
import numeral from "numeral";
import { ModalHeader } from "./components/ModalHeader";

const HOURS = range(0, 24).map((i) => ({
  label: i < 10 ? "0" + i : i,
  value: i < 10 ? "0" + i : i,
}));

const MINUTES = ["00", "15", "30", "45"].map((i) => ({
  label: i,
  value: i,
}));

export const RecipeEntry = () => {
  const [meals, setMeals] = useState();
  const [recipe, setRecipe] = useState();
  const [consumptionMethods, setConsumptionMethods] = useState();
  const client = useParticipantClient();
  const { id } = useParams();
  const isNew = id === "new";
  const { recipe: recipeId } = useSearchParams();
  const { toggle, setIsOpen } = useModalContext();

  const formik = useFormik({
    onSubmit,
  });
  const { values, resetForm } = formik;
  const { t } = useTranslation();
  const { reload: reloadDiary, meal, lastTime } = useDiaryContext();
  const { project, date } = useParticipantContext();

  useAsyncEffect(async () => {
    let [values, meals, consumptionMethods] = await Promise.all([
      isNew ? null : client.get(`diary/recipe_entries/${id}`).get("data"),
      client.get(`diary/meal_choices`).get("data"),
      client.get("diary/consumption_method_choices").get("data"),
    ]);

    const recipe = await client
      .get(`recipes/${values?.recipe || recipeId}`)
      .get("data");
    setRecipe(recipe);
    setMeals(
      map(meals, (label, value) => ({
        value,
        label: t(label),
      }))
    );
    setConsumptionMethods(
      map(consumptionMethods, (label, value) => ({
        value,
        label: t(label),
      }))
    );

    values = values
      ? {
          ...values,
          hour: values.time ? values.time.substr(0, 2) : "",
          minute: values.time ? values.time.substr(3, 2) : "",
        }
      : {
          recipe: recipe.id,
          method_of_consumption: "",
          quantity: "1",
          hour: lastTime?.hour,
          minute: lastTime?.minute,
          meal,
        };

    resetForm({
      values,
    });
  }, []);

  async function onSubmit({ hour, minute, ...x }) {
    const values = {
      ...x,
      date,
      time: project.log_food_time ? `${hour}:${minute}` : null,
    };

    isNew
      ? await client.post("diary/recipe_entries", values).get("data")
      : await client
          .put(`diary/recipe_entries/${values.id}`, values)
          .get("data");

    NotificationManager.success(
      t("The diary entry has been successfully added")
    );
    setIsOpen(false);
    await reloadDiary();
  }

  if (!values) return null;

  return (
    <>
      <ModalHeader toggle={toggle}>
        <BackLinkContainer to={"../../search/recipes/my_recipes"}>
          Recipe
        </BackLinkContainer>
      </ModalHeader>
      <ModalBody>
        {recipe.ingredients.map((i, idx) => (
          <div
            key={i.id}
            className={classNames(
              "flex border-b border-grey-200 pb-2 -mx-4 px-4",
              idx ? "pt-2" : ""
            )}
          >
            <div style={{ width: "20rem" }}>
              <div className="text-brand-dark font-medium">
                {i.food_item__description}
              </div>
              <div className="text-sm">{i.food_item__food_brand__name}</div>
            </div>
            <div className="ml-4 self-center">
              {numeral(i.actual_size).format("0[.]00")}
              {t(i.actual_size_convertible_unit__unit)}
            </div>
          </div>
        ))}
        <div className="text-center mt-4">
          <Trans>Number of portions in this recipe is</Trans>{" "}
          {recipe.total_servings}.<br />
          <Trans>1 portion is</Trans> {numeral(recipe.portion_size).format("0")}
          <Trans>g</Trans>
        </div>
        <FormikProvider value={formik}>
          <div>
            <FormGroup label="Portions?" name="quantity">
              <Field
                name="quantity"
                validate={validateDecimal()}
                className="form-control"
              />
            </FormGroup>
            <FormGroup label="Meal:" name="meal">
              <Select
                isSearchable={false}
                name="meal"
                validate={(v) => !v}
                options={meals}
                simpleValue
              />
            </FormGroup>
            {project.log_food_time ? (
              <FormGroup label={t("What time?")}>
                <Row form>
                  <Col>
                    <FormGroup name="hour" className="mb-0">
                      <Select
                        placeholder={t("Hour")}
                        name="hour"
                        validate={(v) => !v}
                        options={HOURS}
                        simpleValue
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup name="minute" className="mb-0">
                      <Select
                        placeholder={t("Minute")}
                        name="minute"
                        validate={(v) => !v}
                        options={MINUTES}
                        simpleValue
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            ) : null}
            {project.log_method_of_consumption ? (
              <FormGroup
                label="Consumption method:"
                name="method_of_consumption"
              >
                <Select
                  isSearchable={false}
                  options={consumptionMethods}
                  name="method_of_consumption"
                  simpleValue
                  validate={(v) => !v}
                />
              </FormGroup>
            ) : null}
            <div className="flex justify-end">
              <SubmitButton color="primary">
                {values.id ? t("Save Changes") : t("Add to Diary")}
              </SubmitButton>
            </div>
          </div>
        </FormikProvider>
      </ModalBody>
    </>
  );
};

export default RecipeEntry;
