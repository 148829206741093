import YesNoField from "./YesNoField";
import {FormCheck, FormGroup} from "components/common";
import React from "react";
import {Field, useFormikContext} from "formik";

export const DiaryOptionsField = () => {
  const { values } = useFormikContext();
  return (
    <>
      <FormGroup label="On completion display calorie summary?" required>
        <YesNoField name="show_calorie_consumption" />
      </FormGroup>
      <FormGroup label="Reset Food Diary after 24 hours?" required>
        <YesNoField name="can_reset_recall_day" />
      </FormGroup>
      <FormGroup label="Log specific time of food entry?" required>
        <YesNoField name="log_food_time" />
      </FormGroup>
      {values.log_food_time ? (
        <FormGroup label="Log and display time in 24 hour format (hh:mm)?">
          <FormCheck
            name="time_as_24hr"
            label={<>&nbsp;</>}
            className="form-group mb-1"
            input={
              <Field
                name="time_as_24hr"
                type="checkbox"
                id="time_as_24hr"
                className="form-check-input"
              />
            }
          />
        </FormGroup>
      ) : null}
      <FormGroup label="Log consumption method?" required>
        <YesNoField name="log_method_of_consumption" />
      </FormGroup>
      <FormGroup label="Show the welcome screen?" required>
        <YesNoField name="show_welcome" />
      </FormGroup>
      <FormGroup label="Hide diary converter?" required>
        <YesNoField name="hide_diary_converter" />
      </FormGroup>
    </>
  );
};

export default DiaryOptionsField;
