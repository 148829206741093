import {createContext, useContext} from "react";
import { useClient as useClientBase } from "hooks";
import { healthcareTokenAtom } from "../../atoms";
import { isEmpty } from 'lodash';

const ClientContext = createContext(null);

export const useClient = (config = {}) => {
  const {
    baseURL = "/api/v1/healthcare",
    sendAuth = true,
    redirectOnUnauthorized = false,
  } = config;
  const providedClient = useProvidedClient();

  const client = useClientBase({
    baseURL,
    sendAuth,
    redirectOnUnauthorized,
    tokenAtom: healthcareTokenAtom,
  })

  if (providedClient !== null && isEmpty(config)) {
    return providedClient;
  }

  return client;
};

export const useProvidedClient = () => {
  return useContext(ClientContext);
}

export const ClientProvider = ({ client, children }) => {

  return (
    <ClientContext.Provider value={client}>
      {children}
    </ClientContext.Provider>
  )
}