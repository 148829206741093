import React, { useState } from "react";
import { Page, FormGroup, SubmitButton, Select } from "components/common";
import { Field, useFormik, FormikProvider } from "formik";
import { useNavigate, useParams } from "react-router";
import { NotificationManager } from "react-notifications";
import { useAsyncEffect, useClient } from "hooks";
import { validateEmail } from "services/validators";
import { Trans } from "react-i18next";

export const Edit = () => {
  const formik = useFormik({
    onSubmit,
  });
  const [organisations, setOrganisations] = useState();
  const { id } = useParams();
  const isNew = id === "new";
  const { resetForm, values } = formik;
  const navigate = useNavigate();
  const client = useClient();
  const url = "teachers/invited";
  const title = `${isNew ? "New" : "Edit"} Invited Teacher`;

  async function onSubmit(x) {
    await (isNew
      ? client.post(url, x).get("data")
      : client.put(`${url}/${id}`, x));
    NotificationManager.success("Invited teacher saved successfully");
    navigate("..");
  }

  useAsyncEffect(async () => {
    const [values, organisations] = await Promise.all([
      isNew
        ? {
            invitation: {
              first_name: "",
              last_name: "",
              email: "",
            },
            organisation: null,
          }
        : client.get(`${url}/${id}`).get("data"),
      client.get("organisations").get("data"),
    ]);
    setOrganisations(organisations);

    resetForm({ values });
  }, [id]);

  if (!values) return null;

  return (
    <Page title={title}>
      <FormikProvider value={formik}>
        <FormGroup label="First name" required name="invitation.first_name">
          <Field
            name="invitation.first_name"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Last name" required name="invitation.last_name">
          <Field
            name="invitation.last_name"
            validate={(v) => !v}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Email" required name="invitation.email">
          <Field
            name="invitation.email"
            validate={validateEmail()}
            className="form-control"
          />
        </FormGroup>
        <FormGroup label="Organisation" name="organisation" required>
          <Select
            name="organisation"
            validate={(v) => !v}
            options={organisations}
            simpleValue
            labelKey="name"
            valueKey="id"
          />
        </FormGroup>
        <div className="flex justify-end">
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </div>
      </FormikProvider>
    </Page>
  );
};

export default Edit;
