import React, { useState, useEffect } from "react";
import { useOnMount } from "hooks";
import { Outlet } from "react-router-dom";
import { useParticipantClient } from "hooks";
import { ParticipantContext } from "../../contexts/useParticipantContext";
import {useRecoilState} from "recoil";
import { activeProjectAtom } from "atoms";
import { Alert } from "reactstrap";
import { Page } from "components/common";
import { useTranslation, Trans } from "react-i18next";
import ImpersonateNotification from "components/ImpersonateNotification";
import {DateSelection, multiDayDateAtom} from "../../features/multi_day_projects";

export const Container = () => {
  const client = useParticipantClient();

  const [participant, setParticiant] = useState();
  const [message, setMessage] = useState();
  const { t } = useTranslation();
  const [project, setActiveProject] = useRecoilState(activeProjectAtom);
  const [date, setDate] = useRecoilState(multiDayDateAtom);

  useOnMount(async () => {
    try {
      const [project, participant] = await Promise.all([
        client.get("diary/project").get("data"),
        client.get("").get("data"),
      ]);
      setParticiant(participant);
      setActiveProject(project);
    } catch (ex) {
      const code = ex?.response?.data?.code;

      if (code === "InactiveProject") {
        setMessage(t("This project is no longer active"));
      } else if (code === "OptedOut") {
        setMessage(t("You have already opted-out of this project"));
      } else {
        setMessage(
          <Alert color="danger">
            <Trans>An error has occurred.</Trans>
          </Alert>
        );
        console.error(ex);
      }
    }
  });

  useEffect(() => {
    return () => {
      setActiveProject(null);
    };
  }, [setActiveProject]);

  const base = (children) => <div className="container mt-12">{children}</div>;

  useEffect(() => {
    return () => setDate(null);
  }, [setDate])

  if (message) {
    return base(<Page>{message}</Page>);
  }

  if (!project) return null;

  const { is_multi_day } = project;

  return base(
    <ParticipantContext.Provider value={{ project, participant, date }}>
      <ImpersonateNotification />
      {is_multi_day && !date ? <DateSelection /> : <Outlet />}
    </ParticipantContext.Provider>
  );
};

export default Container;
