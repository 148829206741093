import React from "react";
import { useField } from "formik";

export const CheckboxField = ({ name, validate, int, ...props }) => {
  const [field, , { setValue }] = useField({
    name,
    validate,
    type: "checkbox",
  });

  return (
    <input
      type="checkbox"
      {...field}
      onChange={(e) => {
        const { checked } = e.target;

        if (int) {
          setValue(checked ? 1 : 0);
        } else {
          setValue(checked);
        }
      }}
      {...props}
    />
  );
};

export default CheckboxField;
